import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _NotificationService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.NOTIFICATION_API_URL;
  

    async GetAllNotifications(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Notify/get/admin/notifications");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

 

    async GetNotificationTypes(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Notification/get/notification/types`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }
   


}

export const NotificationService = new _NotificationService();