import React, { useState ,useEffect,useContext} from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
// import Select from "./sub-components/Select";
import Select, { OptionProps } from 'react-select';
import { testConcessions } from "../../../../utils";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import TreeFellItems from "./sub-components/TreeFellItems";
import { AccountService } from "../../../../services/AccountService";
import { PaymentService } from "../../../../services/PaymentService";
import UserContext from "../../../../context/UserContext";
import { useNavigate } from "react-router";
const AddRevenue = () => {
  const user = useContext(UserContext);
  let history = useNavigate();
  const [state, setState] = useState({
    accountName: "",
    accountNumber: "",
    description: "",
    currentBalance: 0,
    lastUpdatedByUserId: 0,
    transactionNote: "",
    accountTypeId: 0,
  });

  const [paymentComponents,setPaymentComponents] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [accountTypes,setAccountTypes] = useState([]);
  useEffect(() => {
    handlePaymentComponents();
  }, []);

   

    const handlePaymentComponents = () => {
      PaymentService.GetPaymentComponents().then((res) => {
        let data = res.accountTypes.map((country)=>{
          country.value = country.accountTypeId;
          country.label = country.accountTypeName;
          return country;
       })
        setAccountTypes(data);
        // setAccountTypes()
      
        console.log(data)
        
      });
    };
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSelectChange = (e) => {
    console.log(e)
    setSelectedOption(e);
    // state.driverId = e.value
    setState({...state, accountTypeId:e.value});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Adding New Account ");
     const addCompactmentPromise = AccountService.AddNewAccount(
       parseInt(state.accountTypeId),
       state.description,
       state.accountName,
       state.accountNumber,
        state.currentBalance,
        user.userData.userId,
        state.transactionNote
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         
        //  test(res.data)
         history("/dashboard/finance-management/account");
         
       } else {
         toast.error(res.message);
       }
     });
   
  };
  return (
    <>
      <GoBack />
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        
        <h2 className="my-2 text-xl font-medium text-gray-700 ">New Account</h2>
        <FormGrid>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Account Name</span>
            <Input
              placeholder="Account Name"
              id="accountName"
              type="text"
              value={state.accountName}
              onChange={handleChange}
              required
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Account Number</span>
            <Input
              placeholder="Account Number"
              id="accountNumber"
              type="text"
              value={state.accountNumber}
              onChange={handleChange}
              required
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Current Balance</span>
            <Input
              placeholder="Current Balance"
              id="currentBalance"
              type="text"
              value={state.currentBalance}
              onChange={handleChange}
              required
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Account Type</span>
            <Select
              placeholder="Account Type"
              label="Select Account Type"
              options={accountTypes}
              type="text"
              required
              value={selectedOption }
             onChange={handleSelectChange}
             id="accountTypeId"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Note</span>
            <TextArea
              placeholder="Transaction Note"
              type="text"
              value={state.transactionNote}
              onChange={handleChange}
              required
              id="transactionNote"
            />
          </label>
          
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Description</span>
            <TextArea
              id="description"
              placeholder="Description"
              type="text"
              value={state.description}
              onChange={handleChange}
              required
            />
          </label>
          
          
        </FormGrid>
        {/* <h2>Items</h2>
        <TreeFellItems /> */}
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Create Account
        </button>
      </form>
    </>
  );
};

export default AddRevenue;
