import React, { useState,useEffect } from "react";
import toast from "react-hot-toast";
import { useExportableCSV } from "use-exportable-csv";
import { UserService } from "../../../services/UserService";
const userTypes = ["all", "active", "deactivated"];
const fileTypes = ["pdf", "excel"];
const ExportUsers = () => {
  const [state, setState] = useState({
    userType: "all",
    fileType: "pdf",
  });
  const [users, setUsers] = useState([]);
  const [fileName, setfileName] = useState('allUsers.xls');
  const [userExport, setUserExport] = useState([]);
  const loadUsers = () => {
    UserService.GetAllUsers().then((res) => {
      setUsers(...users, res);
    });
  };

   useEffect(() => {
       loadUsers();
   }, []);

   useEffect(() => {
        console.log(state.userType)
        if(state.userType === 'active'){
          let data = users.filter((x)=>x.isActive !== false);
          setUserExport(data)
          setfileName('activeUsers.xls');
        }else if(state.userType === 'deactivated'){
          let data = users.filter((x)=>x.isActive === false);
          setUserExport(data);
          setfileName('deactivatedUsers.xls');
        }else{
          setUserExport(users);
          setfileName('allUsers.xls');
        }
      

    }, [state]);

   const options = { bom: true };
  const link = useExportableCSV(users, options);
  const handleSubmit = (e) => {
    link();
    e.preventDefault();
    const createUserPromise = new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve({
            message: "User Exported Successfully",
            status: 200,
            data: [],
          }),
        1000
      )
    );
    // handles the toast loader for the login process
    toast.promise(createUserPromise, {
      loading: "Exporting Users",
      success: "Users Exported Successfully",
      error: "Users Export Failed",
    });
  };
  return (
    <div className="w-full p-3">
      <h2 className="mb-4 text-2xl font-bold text-gray-700">Export Users</h2>
      <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
        <h2 className="text-lg font-medium text-gray-700">Select User Group</h2>
        <OptionGroup
          list={userTypes}
          onChange={(value) => setState({ ...state, userType: value })}
          currentValue={state.userType}
        />
        {/* <h2 className="text-lg font-medium text-gray-700">Select File Type</h2>
        <OptionGroup
          list={fileTypes}
          onChange={(value) => setState({ ...state, fileType: value })}
          currentValue={state.fileType}
        /> */}
           <a className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded self-end" href={link} download={fileName}>
            Exporting Users
          </a>
        {/* <button
          type="submit"
          className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Exporting Users old
        </button> */}
      </form>
    </div>
  );
};

export default ExportUsers;

const OptionGroup = ({ list, onChange, currentValue }) => {
  return (
    <div className="flex items-center space-x-2">
      {list.map((item, index) => (
        <OptionButton
          key={index}
          name={item}
          onClick={() => onChange(item)}
          isActive={currentValue === item}
        />
      ))}
    </div>
  );
};

const OptionButton = ({ name, onClick, isActive }) => {
  return (
    
    <button
      type="button"
      className={`px-4 font-medium border border-green-700 uppercase py-1.5 rounded rounded-lg   hover:text-white hover:bg-green-700 ${
        isActive ? "bg-green-700 text-white" : "text-green-700 bg-green-100"
      }`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
