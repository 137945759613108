import React, { useState, useContext, useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { ActivePill, InactivePill } from "../sub-components/StatusPill";
import { useNavigate } from "react-router-dom";
import Search from "../sub-components/Search";
import TableActions from "../sub-components/TableActions";
import { StockService } from "../../../../services/StockService";
import moment from "moment";
import {StampageService} from "../../../../services/StampageService";
import swal from "sweetalert";
import toast from "react-hot-toast";
const Lif = () => {
  const navigate=useNavigate()


      const treeColumns = [
    {
      name: "Stampage Code",
      selector: (row) => row.stampageCode,
    },
        {
          name: "Reference Number",
          selector: (row) => row.referenceNumber,
        },

        {
          name:"Description",
          selector: (row) => row.description,
        }
        ,
        {
          name:"Forest Code",
          selector: (row) => row.forestCode,
        }
        ,
        {
          name:"Stool Land Owners",
          selector: (row) => row.stoolLandOwners,
        },
        {
          name: "Local Mark ",
          selector: (row) => row.localMark,
        },
        {
          name: "Contractor Name",
          selector: (row) => row.contractorName,
        },
        {
          name:"cok Number",
          selector: (row) => row.cokNumber,
        },


    // {
    //   name: "Price",
    //   selector: (row) => `GHS ${row.price.toFixed(2)}`,
    // },
    // {
    //   name: "Available",
    //   selector: (row) =>
    //     row.isAvailable ? (
    //       <ActivePill>Available</ActivePill>
    //     ) : (
    //       <InactivePill>Not Available</InactivePill>
    //     ),
    // },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name:'Actions',
      selector:(row)=><div>
        <TableActions viewAction={()=>navigate(`${row.stampageId}`)} deleteAction={()=>deleteAction(row.stampageId)} />
      </div>
    }
  ];
  const [trees,setTrees] = useState([])
  useEffect(() => {
    handleTrees();

  }, []);
  const [pending, setPending] = useState(true);
  const handleTrees = () => {
    StampageService.GetAllYields().then((res) => {
      
      setTrees(...trees, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
    console.log(search)
  }, [search]);

  const deleteAction = (userId)=>{
    swal({
      title: "Are you sure?",
      text: "Stampage would be removed permanently",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        StampageService.RemoveStampage(parseInt(userId))
            .then((res) => {
              if (res.Successful) {
                toast.success(res.message);

                setTimeout(()=>{
                  const filteredRoles = trees.filter((role) => role.stampageId !== userId );
                  console.log(filteredRoles)
                  setTrees(filteredRoles);
                },1000)


              } else {
                toast.error(res.message);
              }
            })
            .catch((err) => {});
      }
    });
  }
  return (
    <div className="w-full">
      <AddnExport />
      <Search updateList={handleSearch} />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeColumns}
        // data={data}
        data={trees.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.stampageCode.toLowerCase().includes(search.toLowerCase())||
           item.contractorName.toLowerCase().includes(search.toLowerCase())
          }
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default Lif;


const data = [
  {
    id: 1,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 2,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 3,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: false,
  },
  {
    id: 4,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 5,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: false,
  },
  {
    id: 6,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 7,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: false,
  },
];
