import React, { useState, useContext, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import Select from "../../../Select";

import toast from "react-hot-toast";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import TextArea from "../../../TextArea";
import {
  useParams,
} from "react-router-dom";
import { StockService } from "../../../../services/StockService";
const EditProduct = () => {
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    description: "",
    isActive: true,
    price: 0,
    productType: 0,
    productName: "",
    quantityInStock: 0
    
  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };
  const params = useParams();


  const [productTypes,setProductTypes] = useState([])
  const productTypeList = () => {
    StockService.GetProductTypes().then((res) => {
        //  let data = Array.from(res);
         let data = res.map((country)=>{
          country.value = country.productTypeId;
          country.label = country.productTypeName;
          return country;
       })
         setProductTypes(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    productTypeList();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
   const toastId = toast.loading("Product Update In Progress...");
    StockService.UpdateProduct(
      parseInt(params.id),
      parseInt(state.productType),
      state.productName,
      state.description,
      state.price,
      state.quantityInStock
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };


 
  useEffect(() => {
    handleProducts();
  }, []);

  const handleProducts = () => {
    StockService.GetProduct(params.id).then((res) => {
      // console.log(res)
      setState({
      ...state,
      productName: res.productName ||"",
      isActive: res.isActive ||false,
      description: res.description ||"",
      quantityInStock: res.quantityInStock ||"",
      price: res.price ||"",
      productType:res.productTypeId ||0
     
      
    });
      // console.log(concession)
    });
  };

  const deactivateProduct = ()=>{
    StockService.DeActivateProduct(parseInt(params.id)).then((res) => {
     //  console.log(res)
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isActive: false,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 }
 const activateProduct = () => {
  StockService.ActivateProduct(parseInt(params.id)).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isActive: true,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <GoBack />
      <div className="flex space-x-2 self-end">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
            >
              Cancel
            </button>
          </div>
        )}
       {state.isActive ? (
          <button  onClick={deactivateProduct} type='button' className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50">
            - Deactivate
          </button>
        ) : (
          <button  onClick={activateProduct} type='button' className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50">
            + Activate
          </button>
        )}
      </div>
      <h2 className=" text-xl font-medium text-gray-700 my-2">
        {disabled ? "Product Details" : "Edit Product"}
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Product Name
          </span>
          <Input
          disabled={disabled}
            placeholder="Product Name"
            type="text"
            value={state.productName}
            id="productName"
            onChange={handleChange}
            required
          />
        </label>

        
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Quantity In Stock
          </span>
          <Input
            placeholder="Quantity In Stock"
          disabled={disabled}
          type="text"
            value={state.quantityInStock}
            id="quantityInStock"
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Price
          </span>
          <Input
            placeholder="Market Value"
          disabled={disabled}
          type="text"
            value={state.price}
            id="price"
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Product Type
          </span>
          <Select
            label='Select A Product'
          disabled={disabled}
          options={productTypes}
            value={state.productType}
            id="productType"
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          <TextArea
            placeholder="Description"
          disabled={disabled}
          type="text"
            value={state.description}
            id="description"
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Product
        </button>
      )}
    </form>
  );
};

export default EditProduct;
