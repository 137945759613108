import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _PaymentService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.PAYMENT_API;
  

    async GetAllPayments(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Pay/get/all/payments");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetPaymentById(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Pay/get/payment/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async GetPaymentComponents(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Pay/get/payment/components`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async GetPaymentByOrderNumber(orderNumber: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Pay/get/payments/for/orderNumber/${orderNumber}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async AddNewPayment(entryByUserId:number,orderNumber: string,accountId:number,paymentTypeId:number,wayBillId:number,userId:number,paymentModeId:number,paymentMethodId:number,totalAmount:number,amountPaid:number,paymentNote:string,dueDateInDays:number,dueDate:string,comment?:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Pay/add/payment", { entryByUserId,orderNumber,accountId,paymentTypeId,wayBillId,userId,paymentModeId,paymentMethodId,totalAmount,amountPaid,paymentNote,dueDateInDays,dueDate,comment });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Add Record. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async AddOutstandingPayment(paymentId:number,paymentNote: string,amountPaid:number,paymentModeId:number,paymentMethodId:number,dueDateInDays:number,dueDate:string,accountId:number,paymentTypeId:number,wayBillId:number,entryByUserId:number,comment?:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Pay/outstanding/payment", {paymentId,paymentNote,amountPaid,paymentModeId,paymentMethodId,dueDateInDays,dueDate,accountId,paymentTypeId,wayBillId,entryByUserId,comment });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to add outstanding payment. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

 
    async GetPaymentByAdmin(userId:number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Pay/get/payments/entered/by/user/${userId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }
    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }


}

export const PaymentService = new _PaymentService();
