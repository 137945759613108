import React, { useContext, useEffect, useState } from "react";
// import logo from "./logo.svg";
import "./App.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "./components/Login";

import Auth from "./components/Auth";
import { Toaster } from "react-hot-toast";

import PasswordReset from "./components/ResetPassword/PasswordReset";
import PasswordResetSent from "./components/ResetPassword/PasswordResetSent";
import PasswordResetForm from "./components/ResetPassword/PasswordResetForm";
import Dashboard from "./components/Dashboard/Dashboard";
// Auth Wrapper
import PrivateRoute from "./components/PrivateRoute";
import Users from "./components/Dashboard/Users/Users";
import DashboardMain from "./components/Dashboard/DashboardMain";

// All Dashboard Profile Routes
import Profile from "./components/Dashboard/Profile/Profile";
import PersonalDetails from "./components/Dashboard/Profile/PersonalDetails";
import PersonalAddress from "./components/Dashboard/Profile/AddressDetails";
import ChangePassword from "./components/Dashboard/Profile/ChangePassword";
import AllUsers from "./components/Dashboard/Users/AllUsers";
import ActiveUsers from "./components/Dashboard/Users/ActiveUsers";
import DeactivatedUsers from "./components/Dashboard/Users/DeactivatedUsers";
import AddANewUser from "./components/Dashboard/Users/AddANewUser";
import UserDetails from "./components/Dashboard/Users/UserDetails";
import EditUser from "./components/Dashboard/Users/EditUser";
import ExportUsers from "./components/Dashboard/Users/ExportUsers";

// All Dashboard Stock Management Routes
import StockManagement from "./components/Dashboard/StockManagement/StockManagement";
import AddConcession from "./components/Dashboard/StockManagement/Concession/AddConcession";
import AddCompartment from "./components/Dashboard/StockManagement/Compartment/AddCompartment";
import Trees from "./components/Dashboard/StockManagement/Tree/Trees";
import AddTree from "./components/Dashboard/StockManagement/Tree/AddTree";
import EditTree from "./components/Dashboard/StockManagement/Tree/EditTree";
import Species from "./components/Dashboard/StockManagement/Specie/Species";
import AddSpecies from "./components/Dashboard/StockManagement/Specie/AddSpecies";
import EditSpecies from "./components/Dashboard/StockManagement/Specie/EditSpecies";
import Concessions from "./components/Dashboard/StockManagement/Concession/Concessions";
import EditConcession from "./components/Dashboard/StockManagement/Concession/EditConcession";
import EditCompartment from "./components/Dashboard/StockManagement/Compartment/EditCompartment";
import Compartments from "./components/Dashboard/StockManagement/Compartment/Compartments";
import EditUserProfile from "./components/Dashboard/Users/EditUserProfile";
import EditUserAddress from "./components/Dashboard/Users/EditUserAddress";
import EditUserRole from "./components/Dashboard/Users/EditUserRoles";
import Products from "./components/Dashboard/StockManagement/Product/Products";
import AddProduct from "./components/Dashboard/StockManagement/Product/AddProduct";
import EditProduct from "./components/Dashboard/StockManagement/Product/EditProduct";
import AddTeam from "./components/Dashboard/Users/Team/AddTeam";
import EditTeam from "./components/Dashboard/Users/Team/EditTeam";
import TeamMembers from "./components/Dashboard/Users/Team/Teams";
import ProductTypes from "./components/Dashboard/StockManagement/ProductType/ProductTypes";
import AddProductType from "./components/Dashboard/StockManagement/ProductType/AddProductType";
import EditProductType from "./components/Dashboard/StockManagement/ProductType/EditProductType";
import Orders from "./components/Dashboard/Orders/Orders";
import AllOrders from "./components/Dashboard/Orders/AllOrders";
import OrderDetails from "./components/Dashboard/Orders/OrderDetails";
import Treefells from "./components/Dashboard/Treefell/Treefells";
import AllTreeFells from "./components/Dashboard/Treefell/AllTreeFells";
import AddTreeFell from "./components/Dashboard/Treefell/AddTreeFell";

import ClientUsers from "./components/Dashboard/Users/ClientUsers";
import InternalUsers from "./components/Dashboard/Users/InternalUsers";
import TreeFellDetails from "./components/Dashboard/Treefell/TreeFellDetails";
import Warehouse from "./components/Dashboard/Warehouse/Warehouse";
import AllWarehouses from "./components/Dashboard/Warehouse/AllWarehouses";
import AddWarehouse from "./components/Dashboard/Warehouse/AddWarehouse";
import EditWarehouse from "./components/Dashboard/Warehouse/EditWarehouse";
import Waybills from "./components/Dashboard/Waybills/Waybills";
import WaybillHistory from "./components/Dashboard/Waybills/History/WaybillHistory";
import AddWaybill from "./components/Dashboard/Waybills/History/AddWaybill";
import EditWaybill from "./components/Dashboard/Waybills/History/EditWaybill";
import Returns from "./components/Dashboard/Waybills/Returns/Returns";
import AddReturn from "./components/Dashboard/Waybills/Returns/AddReturn";
import EditReturn from "./components/Dashboard/Waybills/Returns/EditReturn";
import Settings from "./components/Dashboard/Settings/Settings";
import Notifications from "./components/Dashboard/Notifications/Notifications";
import CompanyInfo from "./components/Dashboard/Settings/CompanyInfo";
import Currency from "./components/Dashboard/Settings/Currency";
import Analytics from "./components/Dashboard/Reports/Analytics";
import Payments from "./components/Dashboard/FinanceManagement/Payments/Payments";
import AllPayments from "./components/Dashboard/FinanceManagement/Payments/AllPayments";
import AddPayment from "./components/Dashboard/FinanceManagement/Payments/AddPayment";
import PaymentDetails from "./components/Dashboard/FinanceManagement/Payments/PaymentDetails";
import SystemAccounts from "./components/Dashboard/Settings/SystemAccounts";
import AddSystemAccount from "./components/Dashboard/Settings/AddSystemAccount";
import AllSystemAccounts from "./components/Dashboard/Settings/AllSystemAccounts";
import SystemDetails from "./components/Dashboard/Settings/SystemDetails";
import Expenses from "./components/Dashboard/FinanceManagement/Expense/Expenses";
import AddExpense from "./components/Dashboard/FinanceManagement/Expense/AddExpense";
import AllExpenses from "./components/Dashboard/FinanceManagement/Expense/AllExpenses";
import ExpenseDetails from "./components/Dashboard/FinanceManagement/Expense/ExpenseDetails";
import Revenue from "./components/Dashboard/FinanceManagement/Revenue/Revenue";
import AllRevenue from "./components/Dashboard/FinanceManagement/Revenue/AllRevenue";
import AddRevenue from "./components/Dashboard/FinanceManagement/Revenue/AddRevenue";
import RevenueDetails from "./components/Dashboard/FinanceManagement/Revenue/RevenueDetails";
import FinanceManagement from "./components/Dashboard/FinanceManagement/FinanceManagement";
import Yields from "./components/Dashboard/StockManagement/Yield/Yields";
import AddYield from "./components/Dashboard/StockManagement/Yield/AddYield";
import EditYield from "./components/Dashboard/StockManagement/Yield/EditYield";
import Stampage from "./components/Dashboard/StockManagement/Stampage/Stampage";
import AddStampage from "./components/Dashboard/StockManagement/Stampage/AddStampage";
import EditStampage from "./components/Dashboard/StockManagement/Stampage/EditStampage";
import Conveyance from "./components/Dashboard/StockManagement/Conveyance/Conveyance";
import AddConveyance from "./components/Dashboard/StockManagement/Conveyance/AddConveyance";
import EditConveyance from "./components/Dashboard/StockManagement/Conveyance/EditConveyance";
import Lif from "./components/Dashboard/StockManagement/lif/Lif";
import AddLif from "./components/Dashboard/StockManagement/lif/AddLif";
import EditLif from "./components/Dashboard/StockManagement/lif/EditLif";

// All Dashboard Users Routes
function App() {
  // ÷let user = useContext(UserContext);
  // const [userState, setAuthenticated] = useState(user);
  // useEffect(() => {
  //   if (userState.authenticated) {
  //     //get user details
  //     console.log(userState.userData);
  //   }
  // });
  
  return (
    <>
    
      <Routes>
        {/* Auth Routes */}
        <Route path="/" element={<Auth />}>
          <Route path="" element={<Navigate replace to="login" />} />
          <Route path="login" element={<Login />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route
            path="password-reset-request"
            element={<PasswordResetForm />}
          />
          <Route path="password-reset-sent" element={<PasswordResetSent />} />
        </Route>
        {/* Dashboard Routes */}
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<Navigate to="main" replace />} />
          {/* Main Dashboard Page */}
          <Route path="main" element={<DashboardMain />} />

          {/* Analytics Page*/}
          <Route path="analytics" element={<Analytics />} />

          {/* Admin Settings */}
          <Route path="settings" element={<Settings />}>
            <Route index element={<Navigate to="company-info" replace />} />
            <Route path="company-info" element={<CompanyInfo />} />
            <Route path="exchange-rate" element={<Currency />} />
            <Route path="system-accounts" element={<OutLetShell />}>
              <Route index element={<AllSystemAccounts />} />
              <Route path="addsys" element={<AddSystemAccount />} />
              <Route path=":id" element={<SystemDetails />} />
            </Route>
          </Route>
          {/* Admin Settings */}
          <Route path="notifications" element={<Notifications />} />

          {/* Admin Dashboard Users */}
          <Route path="users" element={<Users />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllUsers />} />
            <Route path="active" element={<ActiveUsers />} />
            <Route path="deactivated" element={<DeactivatedUsers />} />
            <Route path="client" element={<ClientUsers />} />
            <Route path="internal" element={<InternalUsers />} />
            <Route path=":id" element={<UserDetails />} />
            <Route path=":id/edit" element={<EditUser />}>
              <Route index element={<Navigate to="profile" replace />} />
              <Route path="profile" element={<EditUserProfile />} />
              <Route path="address" element={<EditUserAddress />} />
              <Route path="roles" element={<EditUserRole />} />
            </Route>
            <Route path="add" element={<AddANewUser />} />
            <Route path="export" element={<ExportUsers />} />
            {/* Teams */}
            <Route path="teams" element={<OutLetShell />}>
              <Route index element={<TeamMembers />} />
              <Route path="add" element={<AddTeam />} />
              <Route path=":id" element={<EditTeam />} />
            </Route>
          </Route>
          {/* Dashboard Profile Page */}
          <Route path="profile" element={<Profile />}>
            <Route index element={<Navigate to="personal-details" replace />} />
            <Route path="personal-details" element={<PersonalDetails />} />
            <Route path="personal-address" element={<PersonalAddress />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>

          {/* Dashboard Waybill Section */}
          <Route path="waybills" element={<Waybills />}>
            <Route index element={<Navigate to="history" replace />} />
            <Route path="history" element={<OutLetShell />}>
              <Route index element={<WaybillHistory />} />
              <Route path="add" element={<AddWaybill />} />
              <Route path=":id" element={<EditWaybill />} />
            </Route>
            <Route path="returns" element={<OutLetShell />}>
              <Route index element={<Returns />} />
              <Route path="add" element={<AddReturn />} />
              <Route path=":id" element={<EditReturn />} />
            </Route>
          </Route>
          {/* Dashboard Order Section */}
          <Route path="orders" element={<Orders />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllOrders />} />
            <Route path=":id" element={<OrderDetails />} />
          </Route>
          {/* Dashboard Payment Section */}
          {/* <Route path="payments" element={<Payments />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllPayments />} />
            <Route path="add" element={<AddPayment/>} />
            <Route path=":id" element={<PaymentDetails />} />
          </Route> */}
          {/* Dashboard Tree Fell Section */}
          <Route path="tree-fell" element={<Treefells />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllTreeFells />} />
            <Route path="add" element={<AddTreeFell />} />
            <Route path=":id" element={<TreeFellDetails />} />
          </Route>
          {/* Dashboard Stock Management Page */}
          <Route path="stock-management" element={<StockManagement />}>
            <Route index element={<Navigate to="concession" replace />} />
            {/* Concession */}
            <Route path="concession" element={<OutLetShell />}>
              <Route index element={<Concessions />} />
              <Route path="add" element={<AddConcession />} />
              <Route path=":id" element={<EditConcession />} />
            </Route>
            {/* Compartment */}
            <Route path="compartment" element={<OutLetShell />}>
              <Route index element={<Compartments />} />
              <Route path="add" element={<AddCompartment />} />
              <Route path=":id" element={<EditCompartment />} />
            </Route>
            {/* Trees */}
            <Route path="trees" element={<OutLetShell />}>
              <Route index element={<Trees />} />
              <Route path="add" element={<AddTree />} />
              <Route path=":id" element={<EditTree />} />
            </Route>
            <Route path="yield" element={<OutLetShell />}>
              <Route index element={<Yields />} />
              <Route path="add" element={<AddYield />} />
              <Route path=":id" element={<EditYield />} />
            </Route>
            <Route path="stampage" element={<OutLetShell />}>
              <Route index element={<Stampage />} />
              <Route path="add" element={<AddStampage />} />
              <Route path=":id" element={<EditStampage />} />
            </Route>
            <Route path="conveyance" element={<OutLetShell />}>
              <Route index element={<Conveyance />} />
              <Route path="add" element={<AddConveyance />} />
              <Route path=":id" element={<EditConveyance />} />
            </Route>
            <Route path="lif" element={<OutLetShell />}>
              <Route index element={<Lif />} />
              <Route path="add" element={<AddLif />} />
              <Route path=":id" element={<EditLif />} />
            </Route>
            {/* Species */}
            <Route path="species" element={<OutLetShell />}>
              <Route index element={<Species />} />
              <Route path="add" element={<AddSpecies />} />
              <Route path=":id" element={<EditSpecies />} />
            </Route>
            {/* Products */}
            <Route path="products" element={<OutLetShell />}>
              <Route index element={<Products />} />
              <Route path="add" element={<AddProduct />} />
              <Route path=":id" element={<EditProduct />} />
            </Route>
            {/* Product Types */}
            <Route path="product-types" element={<OutLetShell />}>
              <Route index element={<ProductTypes />} />
              <Route path="add" element={<AddProductType />} />
              <Route path=":id" element={<EditProductType />} />
            </Route>
            
          </Route>
          {/* Dashboard Warehouse Section */}
          <Route path="warehouse" element={<Warehouse />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllWarehouses />} />
            <Route path="add" element={<AddWarehouse />} />
            <Route path=":id" element={<EditWarehouse />} />
          </Route>
          {/* <Route path="expense" element={<Expenses />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllExpenses />} />
            <Route path="add" element={<AddExpense/>} />
            <Route path=":id" element={<ExpenseDetails />} />
          </Route> */}
          {/* <Route path="revenue" element={<Revenue />}>
            <Route index element={<Navigate to="all" replace />} />
            <Route path="all" element={<AllRevenue />} />
            <Route path="add" element={<AddRevenue/>} />
            <Route path=":id" element={<RevenueDetails />} />
          </Route> */}
          {/* Dashboard Finance Management Page */}
          <Route path="finance-management" element={<FinanceManagement />}>
            <Route index element={<Navigate to="payments" replace />} />
            {/* payments */}
            <Route path="payments" element={<OutLetShell />}>
              <Route index element={<AllPayments />} />
              <Route path="add" element={<AddPayment />} />
              <Route path=":id" element={<PaymentDetails />} />
            </Route>
            {/* Compartment */}
            <Route path="expenses" element={<OutLetShell />}>
              <Route index element={<AllExpenses />} />
              <Route path="add" element={<AddExpense />} />
              <Route path=":id" element={<ExpenseDetails />} />
            </Route>
            {/* Trees */}
            <Route path="account" element={<OutLetShell />}>
              <Route index element={<AllRevenue />} />
              <Route path="add" element={<AddRevenue />} />
              <Route path=":id" element={<RevenueDetails />} />
            </Route>
            
          </Route>
        </Route>
      </Routes>
    
  
      <Toaster />
    </>
  );
}

export default App;

const OutLetShell = () => (
  <>
    <Outlet />
  </>
);

// OLD SIGN UP ROUTES
// import Signup from "./components/Signup";
// import PersonalDetails from "./components/Signup/PersonalDetails";
// import AddressInfo from "./components/Signup/AddressInfo";
// import ImageFiles from "./components/Signup/ImageFiles";
// import PasswordSent from "./components/Signup/PasswordSent";

// <Route path="signup" element={<Signup />}>
// <Route
//   path=""
//   element={<Navigate replace to="personal-details" />}
// />
// <Route path="personal-details" element={<PersonalDetails />} />
// <Route path="address" element={<AddressInfo />} />
// <Route path="image" element={<ImageFiles />} />
// </Route>
/* <Route path="password-sent" element={<PasswordSent />} /> */
