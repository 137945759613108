import React, { useState, useContext, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import Input from "../../../Input";
import Select from "../../../Select";
import {RiEditBoxLine, RiCloseLine, RiFile2Fill, RiFileExcel2Fill} from "react-icons/ri";
import { HiMinusCircle, HiTrash } from "react-icons/hi";
import toast from "react-hot-toast";
import TextArea from "../../../TextArea";
import TreeDropzone from "./sub-components/TreeDropzone";
import manCuttingTimber from "../../../../assets/man_cutting_timber.jpg";
import {
  useParams,
} from "react-router-dom";
import { StockService } from "../../../../services/StockService";
import Modal from "react-modal";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Slider from 'react-touch-drag-slider'
import {StampageService} from "../../../../services/StampageService";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import {FiTrash} from "react-icons/fi";
import {YieldUpload} from "../../sub-components/DocumentUploads";
const dummyImages = [{src : manCuttingTimber}, {src : manCuttingTimber}, {src : manCuttingTimber}];

const EditStampage = () => {
  const [disabled, setDisabled] = useState(true);
  const [concessions, setConcessions] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [species, setSpecies] = useState([]);
  const [files, setFiles] = useState([]);
  const [stampageItems, setStampageItems] = useState([]);
  const [state, setState] = useState({
    rangeId: 0,
    concessionId: 0,
    speciesId:0,
    compartmentId: 0,
    description: "",
    contractorName:"",
    forestCode:"",
    cokNumber:"",
    referenceNumber:"",
    district:"",
    localMark:"",
    stoolLandOwners:"",
    districtAssembly:"",
    stampageUrl:"",
    yield: "",
    stampageItems:[]
  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);


 
  const params = useParams();
  useEffect(() => {
    handleTree();

  }, []);



  const handleTree = () => {
    StampageService.GetYieldDetail(params.id).then((res) => {
       console.log(res)
      // setTreeImages(res.treeImages);

      setState({
      ...state,
      concessionId: res.concessionId||0,
        compartmentId: res.volume ||0,
      description:  res.description ||"",
        contractorName: res.contractorName||"",
        forestCode: res.forestCode||"",
        cokNumber: res.cokNumber||"",
        referenceNumber: res.referenceNumber||"",
        district: res.district||true,
        localMark:res.localMark,
        stoolLandOwners:res.stoolLandOwners,
        districtAssembly:res.districtAssembly,
        stampageItems:res.stampageItems,
        stampageUrl: res.stampageUrl

  
      
    });
    

    
      // console.log(concession)
    });
  };
  



  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading(`Saving Tree ...`);
    StockService.UpdateTree(
      params.id,
      state.speciesId,
      state.treeName,
      state.volume,
      state.marketValue,
      state.concessionId,
      state.compartmentId,
      state.price,
      state.description
    )
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.Successful) {
          toast.success(`Basic Tree Information Saved. Please wait while images get uploaded`);
          console.log(files.length)
          if(files && files.length >0){
            uploadTreeImages(files,params.id)
          }else{
            // navigate('/dashboard/stock-management/trees')
          }
          
        } else {
          toast.error(
            res.message
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  const uploadTreeImages = (e,treeId) => {
   
    const toastId = toast.loading(`Please image(s) upload in progress`);
    StockService.SaveTreeImage(e,treeId)
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.isSuccessful) {
          toast.success(`Image(s) upload successful`);
          // navigate('/dashboard/stock-management/trees')
        } else {
          toast.error(
            "Upload Failed, Delete file and Try again"
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  const deactivateTree = ()=>{
    StockService.DeActivateTree(parseInt(params.id)).then((res) => {
     //  console.log(res)
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isAvailable: false,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 }
 const activateTree = () => {
  StockService.ActivateTree(parseInt(params.id)).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isAvailable: true,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 };

 useEffect(() => {
  // Make sure to revoke the data uris to avoid memory leaks
  console.log(state);
}, [state]);

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const treeFellItemsColumns = [
    {
      name: "Stock Number",
      selector: (row) => row.stockNumber,
    },
    {
      name: "Volume",
      selector: (row) => row.volume,
    },

    {
      name: "Barcode",
      selector: (row) => row.barcode,
    },
    {
      name: "Bole Volume",
      selector: (row) => row.boleVolume,
    },
    {
      name: "Branch Volume (cm)",
      selector: (row) => row.branchVolume,
    },
    {
      name: "contTreeNumber (cm)",
      selector: (row) => row.contTreeNumber,
    },
    {
      name: "Diameter DB",
      selector: (row) => row.diameterDB,
    },
    {
      name: "Diameter DT",
      selector: (row) => row.diameterDT,
    },
    {
      name: "Species Code",
      selector: (row) => row.speciesCode,
    },
    {
      name: "Species Name",
      selector: (row) => row.speciesName,
    },
    {
      name: "Diameter DB",
      selector: (row) => row.diameterDB,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <button
          className="px-2 py-1 text-gray-500 hover:bg-red-700 hover:text-white"
          onClick={()=>deleteAction(row.stampageItemId)}
        >
          <FiTrash/>
          </button>
        </div>
      ),
    },
  ];
  const deleteAction = (userId)=>{
    swal({
      title: "Are you sure?",
      text: "Item will be removed from Stampage",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        StampageService.RemoveItem(parseInt(userId))
            .then((res) => {
              if (res.Successful) {
                toast.success(res.message);

                setTimeout(()=>{
                  const filteredRoles = state.stampageItems.filter((role) => role.stampageItemId !== userId );
                  console.log(filteredRoles)
                  setState({...state,stampageItems:filteredRoles});
                },1000)


              } else {
                toast.error(res.message);
              }
            })
            .catch((err) => {});
      }
    });
  }
  return (
    <>
     <GoBack />

        <h2 className="my-2 text-xl font-medium text-gray-700 ">
         Stampage Details
        </h2>
      <div className="w-full ">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-700" >
          <thead className="text-xs text-gray-500 uppercase bg-gray-100 dark:bg-gray-500 dark:text-gray-200">
          <tr>
            <th  className="py-3 px-6">Contractor Name</th>
            <th className="py-3 px-6">Forest Code</th>
            <th className="py-3 px-6">Cok Number</th>
            <th className="py-3 px-6">Reference Number</th>
            <th className="py-3 px-6">District</th>

          </tr>
          </thead>
          <tbody>
          <tr>
            <td  className="py-3 px-6">{state.contractorName}</td>
            <td  className="py-3 px-6">{state.forestCode}</td>
            <td  className="py-3 px-6">{state.cokNumber}</td>
            <td  className="py-3 px-6">{state.referenceNumber}</td>
            <td  className="py-3 px-6">{state.district}</td>
          </tr>
          </tbody>
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-500 dark:text-gray-200">
          <tr>

            <th className="py-3 px-6">Local Mark</th>
            <th className="py-3 px-6">Stool Land Owners</th>
            <th className="py-3 px-6">District Assembly</th>
            <th className="py-3 px-6">Stampage Document</th>
            <th className="py-3 px-6">Description</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td  className="py-3 px-6">{state.localMark}</td>
            <td  className="py-3 px-6">{state.stoolLandOwners}</td>
            <td  className="py-3 px-6">{state.districtAssembly}</td>
            <td  className="py-3 px-6"><span className="text-3xl"><a href={state.stampageUrl}>  <RiFileExcel2Fill/></a></span></td>
            <td  className="py-3 px-6">{state.description}</td>
          </tr>
          </tbody>
        </table>
      </div>
      {/*<form className="flex flex-col w-full" >*/}


      {/*  <FormGrid>*/}

      {/*    /!*<label className="block">*!/*/}
      {/*    /!*  <span className="block mb-3 text-gray-700 text-md">Concession</span>*!/*/}


      {/*    /!*</label>*!/*/}
      {/*    /!*<label className="block">*!/*/}
      {/*    /!*  <span className="block mb-3 text-gray-700 text-md">Compartment</span>*!/*/}

      {/*    /!*</label>*!/*/}

      {/*    <label className="block">*/}
      {/*      <span className="block mb-3 text-gray-700 text-md">Description</span>*/}
      {/*      <TextArea*/}
      {/*          placeholder="Description"*/}
      {/*          type="text"*/}
      {/*          value={state.description}*/}
      {/*          readOnly*/}
      {/*          id="description"*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*     Contractor Name*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="contractorName"*/}
      {/*          placeholder="Contractor Name"*/}
      {/*          type="text"*/}
      {/*          value={state.contractorName}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      Forest Code*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="forestCode"*/}
      {/*          placeholder="Forest Code"*/}
      {/*          type="text"*/}
      {/*          value={state.forestCode}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      Cok Number*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="cokNumber"*/}
      {/*          placeholder="cok Number"*/}
      {/*          type="text"*/}
      {/*          value={state.cokNumber}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      Reference Number*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="referenceNumber"*/}
      {/*          placeholder="Reference Number"*/}
      {/*          type="text"*/}
      {/*          value={state.referenceNumber}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      District*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="district"*/}
      {/*          placeholder="District"*/}
      {/*          type="text"*/}
      {/*          value={state.district}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      Local Mark*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="localMark"*/}
      {/*          placeholder="Local Mark"*/}
      {/*          type="text"*/}
      {/*          value={state.localMark}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      Stool Land Owners*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="stoolLandOwners"*/}
      {/*          placeholder="Stool Land Owners"*/}
      {/*          type="text"*/}
      {/*          value={state.stoolLandOwners}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label className="block">*/}
      {/*    <span className="block mb-3 text-gray-700 text-md">*/}
      {/*      District Assembly*/}
      {/*    </span>*/}
      {/*      <Input*/}
      {/*          id="districtAssembly"*/}
      {/*          placeholder="District Assembly"*/}
      {/*          type="text"*/}
      {/*          value={state.districtAssembly}*/}

      {/*          readOnly*/}
      {/*      />*/}
      {/*    </label>*/}
      {/*    <label htmlFor="">*/}
      {/*      <span>Stampage Document</span>*/}
      {/*      <span className="text-3xl"><a href={state.stampageUrl}>  <RiFileExcel2Fill/></a></span>*/}
      {/*    </label>*/}

      {/*  </FormGrid>*/}
      {/*  <h4 className="my-2 text-lg font-medium text-gray-700 ">Stampage Items</h4>*/}

      {/*  /!*<button*!/*/}
      {/*  /!*    type="button"*!/*/}
      {/*  /!*    className="flex items-center px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg hover:bg-gray-800 text-medium"*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!* *!/*/}
      {/*  /!*</button>*!/*/}

      {/*</form>*/}

      <div className="w-full">
        <DataTable
            className="w-full rounded rounded-lg"
            columns={treeFellItemsColumns}
            data={state.stampageItems}
            pagination
        />
      </div>

      

  </>
  );
};

export default EditStampage;




export const FormGrid = ({ children }) => {
  return (
      <div style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit,minmax(200px,1fr))",
      }} className=" w-full grid-cols-4 gap-4 mb-2 sm:w-4/6 sm:grid-cols-2" onClick={e=>e.stopPropagation()}>
        {children}
      </div>
  );
};

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   setImages(state.treeImages);
  // }, [state.treeImages]);



