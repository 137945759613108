import React, { useState, useContext, useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { ActivePill, InactivePill } from "../sub-components/StatusPill";
import { useNavigate } from "react-router-dom";
import Search from "../sub-components/Search";
import TableActions from "../sub-components/TableActions";
import { StockService } from "../../../../services/StockService";
import moment from "moment";
import {YieldService} from "../../../../services/YieldService";
import swal from "sweetalert";
import {StampageService} from "../../../../services/StampageService";
import toast from "react-hot-toast";
const Yields = () => {
  const navigate=useNavigate()


      const treeColumns = [
    {
      name: "Yield Code",
      selector: (row) => row.yieldCode,
    },

        {
          name:"Description",
          selector: (row) => row.description,
        }
        ,
        {
          name:"Compactment",
          selector: (row) => row.compartment.compartmentName,
        },
        {
          name: "Concession",
          selector: (row) => row.concession.concessionName,
        }
        ,
        {
          name:"Range Name",
          selector: (row) => row.rangeName,
        },


    // {
    //   name: "Price",
    //   selector: (row) => `GHS ${row.price.toFixed(2)}`,
    // },
    // {
    //   name: "Available",
    //   selector: (row) =>
    //     row.isAvailable ? (
    //       <ActivePill>Available</ActivePill>
    //     ) : (
    //       <InactivePill>Not Available</InactivePill>
    //     ),
    // },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name:'Actions',
      selector:(row)=><div>
        <TableActions viewAction={()=>navigate(`${row.yieldCode}`)} deleteAction={()=>deleteAction(row.yieldId)} />
      </div>
    }
  ];
  const [trees,setTrees] = useState([])
  useEffect(() => {
    handleTrees();
  }, []);
  const [pending, setPending] = useState(true);
  const handleTrees = () => {
    YieldService.GetAllYields().then((res) => {
      
      setTrees(res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
    console.log(search)
  }, [search]);

  const deleteAction = (userId)=>{
    swal({
      title: "Are you sure?",
      text: "Yield would removed permanently",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        YieldService.RemoveStampage(parseInt(userId))
            .then((res) => {
              if (res.Successful) {
                toast.success(res.message);

                setTimeout(()=>{
                  const filteredRoles = trees.filter((role) => role.yieldId !== userId );
                  console.log(filteredRoles)
                  setTrees(filteredRoles);
                },1000)


              } else {
                toast.error(res.message);
              }
            })
            .catch((err) => {});
      }
    });
  }
  return (
    <div className="w-full">
      <AddnExport />
      <Search updateList={handleSearch} />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeColumns}
        // data={data}
        data={trees.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.treeName.toLowerCase().includes(search.toLowerCase())||
           item.treeCode.toLowerCase().includes(search.toLowerCase())
          }
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default Yields;


const data = [
  {
    id: 1,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 2,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 3,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: false,
  },
  {
    id: 4,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 5,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: false,
  },
  {
    id: 6,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: true,
  },
  {
    id: 7,
    treeName: "Timber Tree",
    treeCode:'code',
    description: "A beautiful Tree",
    price: 123.0,
    isAvailable: false,
  },
];
