import React, { useState, useContext, useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { ActivePill, InactivePill } from "../sub-components/StatusPill";
import Search from "../sub-components/Search";
import { useNavigate } from "react-router-dom";
import TableActions from "../sub-components/TableActions";
import { StockService } from "../../../../services/StockService";
import moment from "moment";
const Compartments = () => {
  const navigate=useNavigate()
  const compartmentColumns = [
    {
      name: "Name",
      selector: (row) => row.compartmentName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "No. of Trees",
      selector: (row) => row.numberOfTrees,
    },
    {
      name: "Active",
      selector: (row) => row.isActive?<ActivePill >Active</ActivePill>:<InactivePill>Inactive</InactivePill>,
  
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name:'Actions',
      selector:(row)=><div>
        <TableActions viewAction={()=>navigate(`${row.compartmentId}`)} deleteAction={()=>window.confirm(`Are you sure you want to delete this compartment`)} />
      </div>
    }
  ];
  const [pending, setPending] = useState(true);
  const [compactments,setCompactments] = useState([])
 
  useEffect(() => {
  handleCompactments();
}, []);

   

    const handleCompactments = () => {
      StockService.GetAllCompactments().then((res) => {
        
        setCompactments(...compactments, res);
        setPending(false);
        // console.log(concessions)
        
      });
    };

const [search,setSearch] = useState("");
  return (
    <div className="w-full">
      <AddnExport />
      <Search/>
      <DataTable
        className="w-full rounded rounded-lg"
        columns={compartmentColumns}
        data={compactments.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.compartmentName.toLowerCase().includes(search.toLowerCase())
           
          }
          })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default Compartments;



const data = [
  {
    id: 1,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: true,
  },
  {
    id: 2,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: true,
  },
  {
    id: 3,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: false,
  },
  {
    id: 4,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: true,
  },
  {
    id: 5,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: false,
  },
  {
    id: 6,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: true,
  },
  {
    id: 7,
    compartmentName: "A Compartment",
    description: "Compartment Description",
    size: 123.0,
    isActive: false,
  },
];
