import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
const OrderItems = (props) => {

  const orderItemColumns = [
    {
      name: "Product Name",
      selector: (row) => row.product?row.product.productName:'',
    },
    {
      name: "Species",
      selector: (row) => row.species?row.species.speciesName:'',
    },

    {
      name: "Quantity",
      selector: (row) => row.quantity||'',
    },
    {
      name: "Price",
      selector: (row) => ` GHS ${row.price}`,
    },
    {
      name: "Width",
      selector: (row) => row.width||'',
    },
    {
      name: "Length",
      selector: (row) => row.length||'',
    },
    {
      name: "Thickness",
      selector: (row) => row.tickness||'',
    },
    {
      name: "Amount",
      selector: (row) => ` GHS ${row.amount}`,
    },
    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
  ];
  let { state } = props;
  const [items,setItems] = useState([...state.orderItems]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    console.log(state.orderItems)
    setItems(state.orderItems);
  }, [state.orderItems]);
  return (
    <div className="w-full">
      <DataTable
        title={<span className="font-semibold">Order Items</span>}
        data={items}
        columns={orderItemColumns}
      />
    </div>
  );
};

export default OrderItems;

const data = [
  {
    id: 1,
    productName: "A Product",
    species: "Product Species",
    quantity: 12,
    price: 12,
    amount: 144,
    tickness: "Tickness",
    width: 23,
    length: 12,
  },
  {
    id: 2,
    productName: "A Product",
    species: "Product Species",
    quantity: 12,
    price: 12,
    amount: 144,
    tickness: "Tickness",
    width: 23,
    length: 12,
  },
  {
    id: 3,
    productName: "A Product",
    species: "Product Species",
    quantity: 12,
    price: 12,
    amount: 144,
    tickness: "Tickness",
    width: 23,
    length: 12,
  },
];
