import { nanoid } from "nanoid";
import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";

const PaymentHistoryTable = (props) => {
  const treeFellItemsColumns = [
    {
      name: "Order Number",
      selector: (row) => row.orderNumber,
    },
    {
      name: "Payment Note",
      selector: (row) => row.orderNumber,
    },

    {
      name: "Status",
      selector: (row) => row.isFullyPaid ? (
          <b>Fully Paid</b>
      ) : (
          <b>Partially Paid</b>
      ),
    },

    {
      name: "Total Amount",
      selector: (row) => ` GHS ${row.totalAmount}`,
    },
    {
      name: "Amount Paid",
      selector: (row) => ` GHS ${row.amountPaid}`,
    },
    {
      name: "Outstanding Amount",
      selector: (row) => ` GHS ${row.amountOutstanding}`,
    },

    {
      name: "Last Payment Date",
      selector: (row) => moment(row.lastPaymentDate).format('YYYY-MM-DD'),
    },
    {
      name: "Due Date",
      selector: (row) => moment(row.dueDate).format('YYYY-MM-DD')
    },
  ];
  return (
    <div className="w-full">
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeFellItemsColumns}
        data={props.history}
        pagination
      />
    </div>
  );
};

export default PaymentHistoryTable;

const data = [
  {
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
  {
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
  {
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
];
