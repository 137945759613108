import React, { useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import Input from "../../Input";
// import Select from "../../Select";
// import { FormGrid } from "../Users/AddANewUser";
import { StockService } from "../../../services/StockService";
import moment from "moment";
import Select, { OptionProps } from 'react-select';
import UserContext from "../../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
const Currency = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    managerId: 0,
    supervisorId: 0,
    description: "",
    totalQuantity: "",
    teamId: 0,
    clerkId: 0,
    treeFellItems: [],
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };
  const [pending, setPending] = useState(true);
  const [currencies,setCurrencies] = useState([])
  useEffect(() => {
    handleCurrency();
  }, []);

  const handleCurrency = () => {
    StockService.GetCurrencies().then((res) => {
      console.log(res[1].currencyHistories)
      const sortedDates = res[1].currencyHistories.sort((dateA, dateB) => moment(dateB.dateCreated).toDate() - moment(dateA.dateCreated).toDate())
      // let data = res[0].find((user)=>)
  
      setCurrencies(...currencies, sortedDates);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  let currencyColumns = [
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name: "Currency",
      selector: (row) => row.currencyName,
    },
    {
      name: "Updated By",
      selector: (row) => `${row.updatedBy||''}`,
    },
    {
      name: "GHC Value",
      selector: (row) => `GHC ${row.exchangeRate}`,
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <ExchangeRateForm currencies={currencies} />
      <br />
      <hr />
      <br />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={currencyColumns}
        data={currencies}
        pagination
      />
    </div>
  );
};

export default Currency;

const ExchangeRateForm = (props) => {
  let history = useNavigate();
  const user = useContext(UserContext);
  const [state, setState] = useState({
    currencyId: 1,
    newRate: 0,
    updatedBy: user.userData.firstName+' '+user.userData.lastName,
    
   
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
     const toastId = toast.loading("Updating Exchange Rate");
      StockService.UpdateCurrencyExnachangeRate(
       state.currencyId,
       parseFloat(state.newRate),
       state.updatedBy
     ).then((res) => {
         toast.dismiss(toastId);
         console.log(res);
         if (res.Successful) {
           toast.success(res.message);
           window.location.reload();
         } else {
           toast.error(res.message);
         }
       })
       .catch((error) => {
         toast.error(error.error);
       });
    
    
  };
  return (
    <form onSubmit={handleSubmit} className="flex flex-col w-full ">
      <h2 className="my-2 text-lg font-bold text-gray-700">
        Set New Exchange Rate
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Currency</span>
          <Select
            id="currencyId"
            type="text"
            options={[{label:'GHS',value:'GHS'}]}
            value={{label:'GHS',value:'GHS'}}
           
            isDisabled={true}
           
            
          />
          
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">GHS Value</span>
          <Input type="number" step="0.01" min="0.0" 
            value={state.newRate}
            onChange={handleChange}
            required
            id="newRate"/>
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">USD Value</span>
          <Input type="number" step="0.01" min="0.0" value={1} disabled={true}/>
        </label>
      </FormGrid>
      <button
        type="submit"
        className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Add New Rate
      </button>
    </form>
  );
};


export const FormGrid = ({ children }) => {
  return (
    <div className="grid w-full grid-cols-3 gap-16 mb-2 auto-cols-max">
      {children}
    </div>
  );
};