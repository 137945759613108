import React, { useState, useContext, useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import Select, { OptionProps } from 'react-select';
import { testConcessions } from "../../../utils";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { GrDeliver } from "react-icons/gr";
import GoBack from "../sub-components/GoBack";
import TextArea from "../../TextArea";
import TreeFellItems from "./sub-components/TreeFellItems";
import TreeFellItemsTable from "./sub-components/TreeFellItemsTable";
import TreeFellStatusSteps from "./sub-components/TreeFellStatusSteps";
import { LogService } from "../../../services/LogService";
import { UserService } from "../../../services/UserService";
import { StockService } from "../../../services/StockService";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import {OrderService} from "../../../services/OrderService";
const TreeFellDetails = () => {
  const params = useParams();
  const [statusLabel, setStatusLabel] = useState('In-Progress');
  let labels = [
    {orderStatusId: 1, orderStatusName: "New Order", description: "New Order"},
    {orderStatusId: 2, orderStatusName: "InProgress", description: "In Progress"},
    {orderStatusId: 3, orderStatusName: "Transported", description: "Transport and in transit"},
    {orderStatusId: 4, orderStatusName: "Delivered", description: "Delivered to client"}
  ];
  const [disabled, setDisabled] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [treeFellStatuses,setTreeFellStatuses] = useState([]);
  const [destinationTypes,setDestinationTypes] = useState([]);
  const [customers,setCustomers] = useState([]);
  const [warehouses,setWarehouses] = useState([]);
  const [state, setState] = useState({
    managerId: 0,
    manager:{},
    supervisor:{},
    supervisorId: 0,
    description: "",
    totalQuantity: "",
    teamId: 0,
    team:{},
    date: new Date().toISOString(),
    clerkId: 0,
    clerk:{},
    treeFellItems: [],
    treeFellId:0,
    treeFellCode:"",
    treeFellStatusId:0,
    treeFellStatus:{},
    destinationTypes:{},
    destinationTypeId:0,
    destination:{},
    destinationId:0
  });

  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const [treeFellItems, setTreeFellItems] = useState([
    {
      description: "",
      dimension: "",
      quantity: 0,
      volume: 0,
      width: 0,
      length: 0,
      tree:'',
      treeId:0,
      id: nanoid(),
    },
  ]);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const updateStatus = () =>
    setActiveStep((prevState) => (prevState < 3 ? prevState + 1 : prevState));

    const [teamMembers,setTeamMembers] = useState("")
    const [selectedClerkOption, setSelectedClerkOption] = useState(null);
    const [selectedTeamOption, setSelectedTeamOption] = useState(null);
    const handleClerkChange =(e)=> {
      // console.log(e)
      setSelectedClerkOption(e);
      state.driverId = e.value
      setState({...state, clerkId:e.value});
    };
  
  
    const handleTeamChange =(e)=> {
      //  console.log(e)
       setState({...state, managerId:e.managerId, supervisorId:e.supervisorId,teamId:e.teamId});
       
      setSelectedTeamOption(e);
      
    };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    let oldItems = treeFellItems.filter(x=>x.treeFellItemId);
    let newItems = treeFellItems.filter(x=>!x.treeFellItemId);
    const toastId = toast.loading("Updating Tree fell ... ");
    LogService.UpdateTreeFell(
      state.treeFellId,
      state.teamId,
      state.description,
      state.clerkId,
      state.supervisorId,
      state.managerId,
      state.totalQuantity,
      state.treeFellCode,
      oldItems,
      newItems
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        //history("/dashboard/stock-management/teams");
        
      } else {
        toast.error(res.message);
      }
    });
    // const editTreeFellPromise = new Promise((resolve, reject) =>
    //   setTimeout(
    //     () =>
    //       resolve({
    //         message: "TreeFell Edited Successfully",
    //         status: 200,
    //         data: [],
    //       }),
    //     1000
    //   )
    // );
    // handles the toast loader for the login process
    // toast.promise(editTreeFellPromise, {
    //   loading: "Editing TreeFell",
    //   success: "TreeFell Edited Successfully",
    //   error: "TreeFell Edit Failed",
    // });
  };

  const loadDetails = () => {
    LogService.GetTreeLogDetail(parseInt(params.id)).then((res) => {
      // console.log(res.orderItems)
      if(res){
        let data = res.fellItems.map((user)=>{
          user.id = nanoid();
          return user;
       })
      //  setState({...state,treeFellItems: data})
        setState({...state,
          orderId: res.orderId,
          managerId: res.managerId,
          supervisorId: res.supervisorId,
          description: res.description,
          totalQuantity: res.totalQuantity,
          teamId: res.teamId,
          date: res.dateCreated,
          clerkId: res.clerkId,
          treeFellItems: data||[],
          manager:res.manager,
          supervisor:res.supervisor,
          clerk:res.clerk,
          team:res.team,
          treeFellId:res.treeFellId,
          treeFellCode:res.treeFellCode,
          treeFellStatusId:res.treeFellStatusId,
          treeFellStatus:res.treeFellStatus
          
        })

        setTreeFellItems(data);
      }
      
    });
  };
  useEffect(() => {
    loadDetails();
  }, []);

  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
     })
      setUsers(...users, data);
    });
  };

  const [trees, setTrees] = useState([]);
  const loadTrees = () => {
    StockService.GetTrees().then((res) => {
      // console.log()
      let data = res.map((user)=>{
        user.value = user.treeId;
        user.label = user.treeName;
        return user;
     })
      setTrees(...trees, data);
    });
  };

   useEffect(() => {
       loadTrees();
   }, []);

  const [teams, setTeams] = useState([]);
  const loadTeams = () => {
    StockService.GetTeams().then((res) => {
      let data = res.map((user)=>{
        user.value = user.teamId;
        user.label = user.teamName;
        return user;
     })
      setTeams(...users, data);
    });
  };

   useEffect(() => {
       loadUsers();
   }, []);

   useEffect(() => {
    loadTeams();
    }, []);

  
    useEffect(() => {
      // console.log(state)
      let x = users.find(x=>x.userId===state.clerkId)
      setSelectedClerkOption(x)
      let y = teams.find(x=>x.teamId===state.teamId)
      setSelectedTeamOption(y)

      
      
    }, [state,teams,users]);

   
    
    useEffect(() => {
      console.log(treeFellItems);
      if(treeFellItems.length > 0 ){
        let totalQty = treeFellItems.map(item => parseInt(item.quantity)).reduce((prev, next) => prev + next);
         setState({...state, totalQuantity:totalQty});
      }
      
    }, [treeFellItems]);

    const deleteAction = (userId)=>{
      swal({
        title: "Are you sure?",
        text: "Item will be removed from List",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          LogService.RemoveTreeMember(parseInt(params.id),parseInt(userId))
            .then((res) => {
              if (res.Successful) {
                toast.success(res.message);
             
                setTimeout(()=>{
                  const filteredRoles = treeFellItems.filter((role) => role.treeFellItemId !== userId );
                  console.log(filteredRoles)
                  setTreeFellItems(filteredRoles);
                },1000)
               
               
              } else {
                toast.error(res.message);
              }
            })
            .catch((err) => {});
        }
      });
   }

  useEffect(() => {
    // console.log(state)
    setActiveStep(parseInt(state.treeFellStatusId)-1)
    let x = labels.findIndex(x=>x.orderStatusId===state.treeFellStatusId)
    if(x < 3){
      setStatusLabel(labels[x+1].orderStatusName)
    }else{
      setStatusLabel('Delivered')
    }

  }, [state,labels]);
   const moveToSliding = (orderStatusId,orderStatusLabel) => {
    LogService.MoveToSlidingArea(parseInt(params.id)).then((res) => {
        if (res.Successful) {
          toast.success(res.message);
          setActiveStep(parseInt(orderStatusId)-1)
          setStatusLabel(orderStatusLabel);
          window.location.reload();
        } else {
          toast.error(res.message);
        }
    
        //  console.log(state)
      });
    };

  const markTransported = (orderStatusId,orderStatusLabel) => {
    LogService.MarkAsTransported(parseInt(params.id),state.destinationId,state.destinationTypeId).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setActiveStep(parseInt(orderStatusId)-1)
        setStatusLabel(orderStatusLabel);
        window.location.reload();
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
  };

  const markDelivered = (orderStatusId,orderStatusLabel) => {
    LogService.MarkAsDelivered(parseInt(params.id)).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setActiveStep(parseInt(orderStatusId)-1)
        setStatusLabel(orderStatusLabel);
        window.location.reload();
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
  };
  
    const changeStatus = (e) => {
      e.preventDefault();
      swal({
        // title: "Are you sure?",
        text: "The Tree fell  status will be changed !!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          console.log(state)
          if(state.treeFellStatusId && state.treeFellStatusId === 1){
            let nextStep = labels.find(x=>x.orderStatusName === "InProgress");
            // console.log(nextStep)
            moveToSliding(nextStep.orderStatusId, nextStep.orderStatusName);
           
          }
          if(state.treeFellStatusId && state.treeFellStatusId === 2){
            if(state.destinationId && state.destinationTypeId){
              let nextStep = labels.find(x=>x.orderStatusName === "Transported");
              // console.log(nextStep)
              markTransported(nextStep.orderStatusId, nextStep.orderStatusName);
            }else{
              toast.error("Destination Type and Destination are required");
            }

           
          }
  
          if(state.treeFellStatusId && state.treeFellStatusId === 3){
            let nextStep = labels.find(x=>x.orderStatusName === "Delivered");
            // console.log(nextStep)
             markDelivered(nextStep.orderStatusId, nextStep.orderStatusName);
           
          }
          
        }
      });
    };
  const loadOrderStatus = () => {
    LogService.GetTreeFellStatus().then((res) => {
      // console.log(res.orderItems)
      setTreeFellStatuses(...treeFellStatuses,res)
    });
  };
  useEffect(() => {
    loadOrderStatus();
  }, []);

  const loadDestinations = () => {
    LogService.GetLogDestinationTypes().then((res) => {
      let data = res.destinationTypes.map((user)=>{
        user.value = user.destinationTypeId;
        user.label = user.destinationTypeName;
        return user;
      })
      setDestinationTypes(...destinationTypes,data);
      let data1 = res.customers.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName +' '+user.lastName;
        return user;
      })
      setCustomers(...customers,data1);
      let data2 = res.warehouses.map((user)=>{
        user.value = user.warehouseId;
        user.label = user.warehouseName;
        return user;
      })
      setWarehouses(...warehouses,data2);
    });
  };
  useEffect(() => {
    loadDestinations();
  }, []);
  const [selectedDispatchOption, setSelectedDispatchOption] = useState(null);
  const [selectedCustomerOption, setSelectedCustomerOption] = useState(null);
  const [selectedWarehouseOption, setSelectedWarehouseOption] = useState(null);
  const handleDispatchChange =(e)=> {
    // console.log(e)
    setSelectedDispatchOption(e);
    state.destinationType = e;
    state.destinationTypeId = e.value;

  };
  const handleCustomerChange =(e)=> {
    // console.log(e)
    setSelectedCustomerOption(e);
    state.destinationId = e.value;
    state.destination = e;

  };
  const handleWarehouseChange =(e)=> {
    // console.log(e)
    setSelectedWarehouseOption(e);
    state.destinationId = e.value;
    state.destination = e;

  };
  return (
    <>
    <GoBack />
        <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      
      <div className="flex self-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
        {state.treeFellStatusId !== 2 &&(
        <button
          className="px-2 py-1 bg-green-300 rounded rounded-lg text-tclPrimary"
          onClick={changeStatus}
          type="button"
        >
          Update Status
        </button>)}
      </div>
      <h2 className="my-3 text-xl font-medium text-gray-700 ">
        {disabled ? "TreeFell Details" : "Edit TreeFell"}
      </h2>
      <TreeFellStatusSteps activeStep={activeStep} />
          {state.treeFellStatusId === 2 && (
              <div
                  className="gap-4 px-5 my-10 2xl:px-0"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit,minmax(200px,1fr))",
                  }}
              >

                <label className="block">

                  <span className="block mb-3 text-gray-700 text-md">Destination Type</span>
                  <Select
                      id="supervisorId"
                      type="text"
                      options={destinationTypes}
                      value={selectedDispatchOption}

                      onChange={handleDispatchChange}
                      required

                  />
                </label>
                {state.destinationTypeId === 1 && (
                <label className="block">

                  <span className="block mb-3 text-gray-700 text-md">Destination</span>
                  <Select
                      id="supervisorId"
                      type="text"
                      options={customers}
                      value={selectedCustomerOption}

                      onChange={handleCustomerChange}
                      required

                  />
                </label>
                    )}
                {state.destinationTypeId === 2 && (
                <label className="block">

                  <span className="block mb-3 text-gray-700 text-md">Destination</span>
                  <Select
                      id="warehouseId"
                      type="text"
                      options={warehouses}
                      value={selectedWarehouseOption}
                      onChange={handleWarehouseChange}
                      required

                  />
                </label>
                    )}

                <label className="block">
                  <button
                      onClick={changeStatus}
                      className="mt-8 sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-1 lg:py-2 my-3 rounded rounded-md self-end"
                  >
                   Update Status
                  </button>
                </label>


              </div>
          )}
      <FormGrid>
        {/* <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Manager</span>
          <Select
            placeholder="Manager"
            label="Select a Manager"
            options={[]}
            disabled={disabled}
            type="text"
            value={state.managerId}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Supervisor</span>
          <Select
            label="Select a Supervisor"
            type="text"
            disabled={disabled}
            options={[]}
            value={state.supervisorId}
          />
        </label> */}

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Team</span>
          <Select
            label="Select a Team"
            options={teams}
            value={selectedTeamOption}
            onChange={handleTeamChange}
            required
            isDisabled={disabled}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Clerk</span>
          <Select
            id="clerkId"
            type="text"
            options={users}
            value={selectedClerkOption }
            onChange={handleClerkChange}
            required
            isDisabled={disabled}
          />
          {/* <Select label="Select a Clerk" options={[]} value={state.clerkId} /> */}
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            disabled={disabled}
            type="text"
            value={state.description}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Date</span>
          <Input
            type="date"
            disabled={true}
            value={state.date}
          />
        </label>
      </FormGrid>
      <h2 className="my-4 text-lg font-semibold">Items</h2>
      {!disabled ? <TreeFellItems trees={trees} deleteAction={deleteAction} treeFellItems={treeFellItems} setTreeFellItems={setTreeFellItems}  /> : <TreeFellItemsTable treeFellItems={state.treeFellItems} />}
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit TreeFell
        </button>
      )}
    </form>
    </>
  
  );
};

export default TreeFellDetails;
