import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { UserService } from "../../../../services/UserService";


const testClients = [
    { label: "Ernest", value: 1 },
    { label: "Earnest", value: 2 },
    { label: "Ear-nest", value: 3 },
  ];


const FilterByClient = ({
    filter,
    changeHandler,
    toggleFilters,
    shouldFilter,
    updateList,
    state
  }) => {
    const cancelFiltersHandler = async () => {
      await changeHandler(0);
      toggleFilters();
    };

    const [users, setUsers] = useState([]);
    const loadUsers = () => {
      UserService.GetClientUsers().then((res) => {
        let data = res.map((user)=>{
          user.value = user.userId;
          user.label = user.firstName+' '+user.lastName;
          return user;
      })
        setUsers(...users, data);
      });
    };
  
     useEffect(() => {
         loadUsers();
     }, []);
    return (
      <div className="flex flex-col justify-end md:flex-row md:items-stretch md:space-x-3">
        {shouldFilter && (
          <Select
            placeholder="Select A Client"
            onChange={(item) => updateList(item)}
            options={users}
            value={state}
            className="w-full md:w-[20rem] mb-3 md:mb-0"
            name="client"
            isClearable={true}
          />
        )}
        {shouldFilter ? (
          <button
            className="self-end px-2 py-1 text-white bg-red-700 rounded rounded-md hover:bg-red-800 hover:shadow-md md:self-stretch"
            onClick={cancelFiltersHandler}
          >
            Cancel
          </button>
        ) : (
          <button
            className="rounded rounded-md bg-gray-700 hover:bg-gray-800 hover:shadow-md text-white self-end py-1.5 px-2"
            onClick={toggleFilters}
          >
            Filter by Client
          </button>
        )}
      </div>
    );
  };

export default FilterByClient