import React from "react";
import { Outlet } from "react-router-dom";
import { waybillRoutes } from "../../../utils";
import DashboardTabLink from "../sub-components/DashboardTabLink";

const Waybills = () => {
  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 mb-6 text-4xl font-extrabold text-slate-700">
        Waybills
      </h1>
      <nav className="flex flex-wrap w-full py-5 mb-2 text-left border-b border-gray-300">
        {waybillRoutes.map((route,index) => (
          <DashboardTabLink {...route} key={index}/>
        ))}
      </nav>
      <Outlet />
    </div>
  );
};

export default Waybills;
