import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _WaybillService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;
  

    async AddWaybill(orderId:number,driverId: number,managerId: number,supervisorId:number,personnelId:number,totalAmount:number,vatAmount: number,grossAmount:number,waybillItems:any,comment: string,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Waybill/add/waybill", { orderId,driverId,managerId,supervisorId,personnelId,totalAmount,vatAmount,grossAmount,waybillItems,comment});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Waybill. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async UpdateWaybill(orderId:number,driverId: number,managerId: number,supervisorId:number,personnelId:number,totalAmount:number,comment: string,vatAmount: number,grossAmount:number,waybillItems:any,newWaybillItems:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Waybill/update/waybill", { orderId,driverId,managerId,supervisorId,personnelId,totalAmount,comment,vatAmount,grossAmount,waybillItems,newWaybillItems});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Waybill. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async GetAllWaybill(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Waybill/get/waybills");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetWaybillDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Waybill/get/waybill/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }
    async GetWaybillDetailByOrderId(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Waybill/get/waybills/by/order/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetWaybillDetailByUserId(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Waybill/get/waybills/for/user/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetWaybillDetailByWayBillNumber(wayBillNumber: string): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Waybill/get/waybill/by/number/${wayBillNumber}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async RemoveWaybillItem(waybillItemId: number,orderId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Waybill/remove/waybillitem", { waybillItemId,orderId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Remove Item. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async DeActivateWaybill(waybillId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Waybill/deactivate/warehouse", { waybillId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Deactivate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async AddReturns(waybillId:number,driverId: number,managerId: number,supervisorId:number,personnelId:number,totalAmount:number,vatAmount: number,grossAmount:number,returnItemRequests:any,comment?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Return/add/returns", { waybillId,driverId,managerId,supervisorId,personnelId,totalAmount,vatAmount,grossAmount,returnItemRequests,comment});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Return. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async UpdateReturns(returnId:number,driverId: number,managerId: number,supervisorId:number,personnelId:number,totalAmount:number,comment: string,vatAmount: number,grossAmount:number,returnItemRequests:any,newReturnItemRequests:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Return/update/return", { returnId,driverId,managerId,supervisorId,personnelId,totalAmount,comment,vatAmount,grossAmount,returnItemRequests,newReturnItemRequests});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Waybill. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async GetAllReturns(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Return/get/all/returns");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetReturnDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Return/get/returns/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetReturnsDetailByOrderId(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Return/get/returns/by/order/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetReturnsDetailByUserId(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Return/get/returns/for/user/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async RemoveReturnItem(waybillItemId: number,orderId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Return/remove/returnsitem", { waybillItemId,orderId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Remove Item. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }

}

export const WaybillService = new _WaybillService();
