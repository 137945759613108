import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _LogService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;

    async GetRecentTreeLogs(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Log/get/recent/treefells");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetAllTreeLogs(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Log/get/all/treefells");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetTreeLogDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Log/get/treefell/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }


    async CreateTreeFell(
        teamId: number,
        description: string,
        clerkId: string,
        supervisorId: number,
        managerId: number,
        totalQuantity: number,
        treeFellItems: any
    ): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/add/treefell", { teamId, description, clerkId, supervisorId, managerId, totalQuantity, treeFellItems });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Create Tree Fell Log. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async UpdateTreeFell(
        treeFellId:number,
        teamId: number,
        description: string,
        clerkId: string,
        supervisorId: number,
        managerId: number,
        totalQuantity: number,
        treeFellCode:string,
        treeFellItems: any,
        newTreeFellItems:any
    ): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/update/treefell", {treeFellId, teamId, description, clerkId, supervisorId, managerId, totalQuantity,treeFellCode, treeFellItems,newTreeFellItems });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Tree Fell Log. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }



    async RemoveTreeMember(treeFellId: number, treeFellItemId: number): Promise<any> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/remove/treefellitem", { treeFellId, treeFellItemId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Remove Item. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }


    async MoveToSlidingArea(treeFellId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/move/treefell/to/sliding/area", { treeFellId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update  Status. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async MarkAsTransported(treeFellId:number,destinationId:number,destinationTypeId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/mark/treefell/as/transported", { treeFellId,destinationId,destinationTypeId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update  Status. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async MarkAsDelivered(treeFellId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/mark/treefell/as/delivered", { treeFellId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update  Status. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetTreeFellStatus(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Stock/get/treefell/statuses`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetLogDestinationTypes(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Log/get/destination/type/components`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetLogStats(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Log/get/treefell/counts`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }


}

export const LogService = new _LogService();
