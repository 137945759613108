import React, { useState, useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import Select, { OptionProps } from 'react-select';
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import GoBack from "../sub-components/GoBack";
import TextArea from "../../TextArea";
import { WarehouseService } from "../../../services/WarehouseService";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import { Link, useNavigate } from "react-router-dom";
const EditWarehouse = () => {
  let history = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    warehouseName: "",
    location: "",
    isActive: true,
    managerId: 0,
    supervisorId: 0,
    description: "",
    totalQuantity: "",
    email: "",
    phoneNumber: "",
    warehouseId:0
  });

  const toggleDisable = () => setDisabled((oldState) => !oldState);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Updating Warehouse ");
    WarehouseService.UpdateWarehouse(
      state.warehouseId,
      state.warehouseName,
      state.description,
      state.location,
      state.managerId,
      state.supervisorId,
      state.email,
      state.phoneNumber
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        history("/dashboard/warehouse/all");
      } else {
        toast.error(res.message);
      }
    });
  };

  const params = useParams();
  useEffect(() => {
    handleWarehouse();
  }, []);

  const handleWarehouse = () => {
    WarehouseService.GetWarehouseDetail(params.id).then((res) => {
      setState({
        ...state,
        warehouseName: res.warehouseName || "",
        description: res.description || "",
        managerId: res.managerId || "",
        supervisorId: res.supervisorId || "",
        location: res.location || "",
        email: res.email || "",
        phoneNumber: res.phoneNumber || "",
        warehouseId:res.warehouseId,
        isActive:res.isActive
        
      });
      // console.log(concessions)
      
    });
  };
  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);


  
  const handleSupervisorChange =(e)=> {
    // console.log(e)
    setSelectedSupervisorOption(e);
    state.supervisorId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };

  const handleMangerChange =(e)=> {
    // console.log(e)
    setSelectedManagerOption(e);
    state.managerId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };



  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
     })
      setUsers(...users, data);
    });
  };

   useEffect(() => {
       loadUsers();
   }, []);
   useEffect(() => {
    if(users.length > 0){
     
      const filteredSupervisor = users?.filter((user) => user.userId === state.supervisorId );
     
      setSelectedSupervisorOption(filteredSupervisor);
      const filteredManager = users?.filter((user) => user.userId === state.managerId );
      
      setSelectedManagerOption(filteredManager);
      const filteredDriver = users?.filter((user) => user.userId === state.driverId );
      
   
    }
}, [users,state]);
const deactivateTree = ()=>{
  WarehouseService.DeActivateWarehouse(parseInt(params.id)).then((res) => {
   //  console.log(res)
    if (res.Successful) {
      toast.success(res.message);
      setState({
        ...state,
        isActive: false,
      });
    } else {
      toast.error(res.message);
    }

    //  console.log(state)
  });
}
const activateTree = () => {
WarehouseService.ActivateWarehouse(parseInt(params.id)).then((res) => {
    if (res.Successful) {
      toast.success(res.message);
      setState({
        ...state,
        isActive: true,
      });
    } else {
      toast.error(res.message);
    }

    //  console.log(state)
  });
};
  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      <GoBack />
      <div className="flex self-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
        {state.isActive ? (
          <button
          onClick={deactivateTree}
            type="button"
            className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50"
          >
            - Deactivate
          </button>
        ) : (
          <button
          onClick={activateTree}
            type="button"
            className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50"
          >
            + Activate
          </button>
        )}
      </div>
      <h2 className="my-3 text-xl font-medium text-gray-700 ">
        {disabled ? "Warehouse Details" : "Edit Warehouse"}
      </h2>

      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Warehouse Name
          </span>
          <Input
            placeholder="Warehouse Name"
            label="Warehouse Name"
            type="text"
            disabled={disabled}
            value={state.warehouseName}
            onChange={handleChange}
            required
            id="warehouseName"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Manager</span>
          <Select
            id="managerId"
            type="text"
            options={users}
            value={selectedManagerOption}
            isDisabled={disabled}
            onChange={handleMangerChange}
            required
            
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Supervisor</span>
          <Select
            id="supervisorId"
            type="text"
            options={users}
            value={selectedSupervisorOption}
            isDisabled={disabled}
            onChange={handleSupervisorChange}
            required
            
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Location</span>
          <Input
            placeholder="Location"
            label="Location"
            type="text"
            disabled={disabled}
            value={state.location}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Email</span>
          <Input
            placeholder="Email"
            label="Email"
            type="email"
            disabled={disabled}
            value={state.email}
            onChange={handleChange}
            required
            id="email"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Phone Number
          </span>
          <Input
            placeholder="Phone Number"
            label="Phone Number"
            type="tel"
            disabled={disabled}
            value={state.phoneNumber}
            onChange={handleChange}
            required
            id="phoneNumber"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            disabled={disabled}
            value={state.description}
            onChange={handleChange}
            required
            id="description"
          />
        </label>
      </FormGrid>
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Warehouse
        </button>
      )}
    </form>
  );
};

export default EditWarehouse;
