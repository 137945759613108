import React, { useState,useEffect } from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
// import Select from "../../../Select";
import Select, { OptionProps } from 'react-select';
import { testConcessions } from "../../../../utils";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import { StockService } from "../../../../services/StockService";
import { Link, useNavigate } from "react-router-dom";
import DocumentUploadNew from "../../sub-components/DocumentUploads";
import moment from "moment";
const AddCompartment = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    compartmentName: "",
    concession: "",
    description: "",
    numberOfTrees: 0,
    compartmentSize: 0,
    permitDocument: "",
    permitNumber: "",
    permitExpiryDate: "",
    registrationDate: moment().format('l')

  });
const [concessions, setConcessions] = useState([]);
const [selectedOption, setSelectedOption] = useState(null);
  const loadConcessionList = () => {
    StockService.GetAllConcessions().then((res) => {
      let data = res.map((country)=>{
        country.value = country.concessionId;
        country.label = country.concessionName;
        return country;
     })
         setConcessions(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadConcessionList();
  }, []);
  const handleChange = (e) => {
    console.log(e)
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };
  const handleSelectChange = (e) => {
    console.log(e)
    setSelectedOption(e);
    // state.driverId = e.value
    setState({...state, concession:e.value});
  };

  const test = (id) => {
    const toastId = toast.loading("Contract Upload in progress... ");
    StockService.SaveCompartmentDoc(state.permitDocument, id)
         .then((res) => {
           if (res.isSuccessful) {
            toast.dismiss(toastId);
             toast.success(res.message);
             history("/dashboard/stock-management/compartment");
           } else {
             toast.error(
               "Document Upload Failed, Delete file and Try again"
             );
           }
         })
         .catch((err) => {
           console.error(err);
         });
     };
 const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Adding new Compactment ");
     const addCompactmentPromise = StockService.AddCompactment(
       parseInt(state.concession),
       state.compartmentName,
       state.description,
       parseInt(state.numberOfTrees),
        parseInt(state.compartmentSize),
        state.permitNumber,
        state.permitExpiryDate
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         
         test(res.data)
        // history("/dashboard/stock-management/compartment");
         
       } else {
         toast.error(res.message);
       }
     });
   
  };


  return (
    <>
     <GoBack />
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
     
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        New Compartment
      </h2>
        <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Compartment Number
          </span>
          <Input
            id="compartmentName"
            placeholder="Compartment Name"
            type="text"
            value={state.compartmentName}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Compartment Size
          </span>
          <Input
            id="compartmentSize"
            placeholder="Compartment Size"
            type="text"
            value={state.compartmentSize}
            onChange={handleChange}
            required
          />
        </label>
        
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Number of Trees
          </span>
          <Input
            id="numberOfTrees"
            placeholder="Total Number of Trees"
            type="text"
            value={state.numberOfTrees}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Select Concession
          </span>
          {/* <Select
            id="concession"
            type="text"
            options={concessions}
            value={state.concession}
            onChange={handleSelectChange}
            required
          /> */}
           <Select
            id="concession"
            type="text"
            options={concessions}
            value={selectedOption }
            onChange={handleSelectChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            id="description"
            placeholder="Description"
            type="text"
            value={state.description}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      <h2 className="my-4 text-xl font-medium text-gray-700 ">Permit Info</h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Permit Number
          </span>
          <Input
            id="permitNumber"
            placeholder="Permit Number"
            type="text"
            value={state.permitNumber}
            onChange={handleChange}
            required
          />
        </label>
        {/* <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Permit Registration Date
          </span>
          <Input
            id="registrationDate"
            placeholder="Permit Expiry Date"
            type="date"
            value={state.registrationDate}
            onChange={handleChange}
            required
          />
        </label> */}
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Permit Expiry Date
          </span>
          <Input
            id="permitExpiryDate"
            placeholder="Permit Expiry Date"
            type="date"
            value={state.permitExpiryDate}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      <span className="block mt-4 mb-3 text-gray-700 text-md">
        Permit Document
      </span>
      <DocumentUploadNew state={state} />
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Compartment
      </button>
    </form>
    </>
    
  );
};

export default AddCompartment;
