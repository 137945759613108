import { nanoid } from "nanoid";
import React from "react";
import DataTable from "react-data-table-component";
import TableActions from "../../StockManagement/sub-components/TableActions";
import {  FiTrash } from "react-icons/fi";

const TreeFellItemsTable = (props) => {
  const treeFellItemsColumns = [
    {
      name: "Tree Name",
      selector: (row) => row.treeId,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
   
    {
      name: "Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Dimension",
      selector: (row) => row.dimension,
    },
    {
      name: "Length (cm)",
      selector: (row) => row.length,
    },
    {
      name: "Width (cm)",
      selector: (row) => row.width,
    },
    {
      name: "Volume",
      selector: (row) => row.volume,
    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div>
    //       <button
    //       className="px-2 py-1 text-gray-500 hover:bg-red-700 hover:text-white"
    //       onClick={()=>props.deleteAction(row.userInfo.userId)}
    //     >
    //       <FiTrash />
    //     </button>
    //     </div>
    //   ),
    // },
  ];
  return (
    <div className="w-full">
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeFellItemsColumns}
        data={props.treeFellItems}
        pagination
      />
    </div>
  );
};

export default TreeFellItemsTable;

const data = [
  {
    treeId:nanoid(),
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
  {
    treeId:nanoid(),
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
  {
    treeId:nanoid(),
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
];
