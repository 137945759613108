import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { HubConnection,LogLevel, HubConnectionBuilder } from "@microsoft/signalr";
import { config } from '../../../environments/environment'
import { NotificationService } from "../../../services/NotificationService";
import moment from "moment";
import Search from "../Settings/sub-components/Search";
import { ViewMessage } from "./sub/ViewMessage";
const Notifications = () => {
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);

  const getAdminNotifications = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(`${config.url.NOTIFICATION_API_URL}/messenger`)
        .configureLogging(LogLevel.Information)
        .build();

      connection.on("UnReadNotificationForUser", (message) => {
        setMessages(messages => [...messages, {message }]);
      });

      

      connection.onclose(e => {
        setConnection();
        setMessages([]);
     
      });

      await connection.start();
      await connection.invoke("GetUnreadMessagesForUser",{userid:3});
      setConnection(connection);
    } catch (e) {
      console.log(e);
    }
  }

//   useEffect(() => {
//     getAdminNotifications(); 
// }, []);

  const notificationColumns = [
    {
      name: "Title",
      selector: (row) => row.messageTitle,
    },
    {
      name: "Message",
      // selector: (row) => row.messageText,
      selector: (row) => (
        <ViewMessage message={row} messages={messages} setMessages={setMessages}/>
        
      ),
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateSent).format('yyyy-MM-DD'),
    },
    

  ];

  const [notifications,setNotifications] = useState([])
  useEffect(() => {
    handleNotifications();
  }, []);
  const [pending, setPending] = useState(true);
  const handleNotifications = () => {
    NotificationService.GetAllNotifications().then((res) => {
      console.log(res)
      setNotifications(...notifications, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
    console.log(search)
  }, [search]);
  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 mb-6 text-4xl font-extrabold text-slate-700">
        Notifications
      </h1>

        
      <Search updateList={handleSearch}/>
      <DataTable
        className="w-full rounded rounded-lg"
        columns={notificationColumns}
       
        data={notifications.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.messageTitle.toLowerCase().includes(search.toLowerCase())||
           item.messageText.toLowerCase().includes(search.toLowerCase())
           
          }
          })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default Notifications;

const data = [
  {
    id: 1,
    title: "A new order has been placed",
  },
  {
    id: 2,
    title: "A new waybill has been created for order-1234",
  }
];
