import React, { useState,useEffect } from "react";
import { OrderService } from "../../../../../services/OrderService";
import Input from "../../../../Input";
import { FormGrid } from "../../../Users/AddANewUser";
import Select, { OptionProps } from 'react-select';
import toast from "react-hot-toast";
const GetOrder = (props) => {
  const [state, setState] = useState({
    orderNumber: "",
  });
 
  const handleSubmit = (e) => {
    e.preventDefault();
    //props.gotOrder();
    console.log(e);
      const toastId = toast.loading("Searching for order ... ");
    OrderService.GetOrderByOrderNumber(state.orderNumber).then((res) => {
       console.log(res)
        toast.dismiss(toastId);
       if(res){
            toast.success("Order Found");
        props.setOrder({...props.order,
          orderId: res.orderId,
          orderNumber: res.orderNumber,
          description: res.description,
          status: res.status||'',
          client: res.user.firstName+' '+res.user.lastName||'',
          dispatchType: res.dispatchType||'',
          currency: res.currency||'',
          numberOfProducts: res.numberOfProducts,
          deliveryAddress: res.deliveryAddress||'',
          totalAmount: res.totalAmount,
          destinationCountryId: res.destinationCountryId,
          comment: res.comment,
          dispatchTypeId: res.dispatchTypeId||0,
          email: res.email,
          phoneNumber: res.phoneNumber,
          currencyId: res.currencyId||0,
          vatAmount: res.vatAmount,
          contractNumber: res.contractNumber,
          contractDocumentUrl: res.contractFileUrl||'',
          contractDocumentFile: res.contractDocumentFile||'',
          orderItems:res.orderItems||[],
          orderStatus:res.orderStatus,
          orderStatusId:res.orderStatusId
         
        });
        props.gotOrder();
        props.setOrderItems(res.orderItems);
       }else{
        toast.error("Oops ... Order not found");
       }
     
    });
  };

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

 
  return (
    <form className="flex items-end my-5 space-x-2 " onSubmit={handleSubmit}>
      <div
        className="gap-4 2xl:px-0"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(350px,1fr))",
        }}
      >
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Enter Order Number
          </span>
          <Input
            id="orderNumber"
            placeholder="Enter Order Number"
            type="text"
            value={state.orderNumber}
            onChange={handleChange}
          />
        </label>
      </div>
      <button
        type="submit"
        className="w-auto px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-1 rounded rounded-md"
      >
        Get Order
      </button>
    </form>
  );
};

export default GetOrder;
