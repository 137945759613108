import React, { useContext } from "react";
import { RiShutDownLine } from "react-icons/ri";
import { NavLink,useLocation } from "react-router-dom";
import { dashboardNavigation } from "../../../utils";
import UserContext from "../../../context/UserContext";
import { AuthService } from "../../../services/AuthService";
import swal from "sweetalert";
const Sidebar = () => {
  const location = useLocation();
  const user = useContext(UserContext);
  const Logout = () => {
    swal({
      title: "Are you sure?",
      text: "Your current session and any unsaved data will be lost",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        AuthService.Logout(user.userData.userId)
          .then((result) => {
            if (result.Successful) {
              window.location.reload();
              location("/login");
            }
          })
          .catch((err) => {});
      } else {
      }
    });
  };
  return (
    <nav className="h-full bg-white shadow-xs min-w-[4rem] basis-[13%] flex-col items-center justify-between  hidden md:flex">
     
      <div className="flex flex-col justify-center  space-y-6 ">
        {dashboardNavigation.map((navItem, index) => (
          <NavLink key={index} to={navItem.path}  className={({isActive})=>(
            isActive?"flex items-center justify-start w-full p-1 mt-2 font-normal text-gray-800  uppercase transition-colors duration-300 border-r-4 border-lime-600 bg-gradient-to-r from-white to-lime-100 "
            :"flex items-center justify-start w-full p-1 my-2 font-normal uppercase mx-2 text-sm  "
          )
            
          }
          
            >
            {({ isActive }) => (
              <>
                <span className="mr-2 text-left"
                
                >
                    <navItem.Icon
                        className={
                          isActive
                            ? "hidden"
                            : "text-xl text-gray-700  group-hover:hidden"
                        }
                      />

                      <navItem.IconFilled
                        className={
                          isActive
                            ? " text-xl text-lime-700"
                            : "hidden"
                        }
                      />
                    </span>
                    <span className={
                          isActive
                            ? "transition-colors duration-200 justify-start  text-m text-lime-700 "
                            : " text-lime-700  group-hover:block"
                        }
                      >{navItem.name}
                      </span>

                
              
                
              </>
            )}
          </NavLink>
        ))}
      </div>

      <div className="flex items-center justify-center p-2 mb-10 bg-red-700  rounded-full hover:bg-red-900">
        <RiShutDownLine onClick={Logout} className="text-2xl text-white" />
      </div>
    </nav>
    
  );
};

export default Sidebar;
