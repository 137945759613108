import React, { useState, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import Select from "../../../Select";
import TextArea from "../../../TextArea";
import toast from "react-hot-toast";
import Dropzone from "react-dropzone";
import TreeDropzone from "./sub-components/TreeDropzone";
import { StockService } from "../../../../services/StockService";
import Species from "../Specie/Species";
import { useNavigate } from "react-router";

const AddTree = () => {
  const navigate=useNavigate();
  const [state, setState] = useState({
    treeName: "",
    volume: "",
    marketValue: 0.0,
    concessionId: 0,
    speciesId:0,
    compartmentId: 0,
    price: 0.0,
    description: "",
    isAvailable: false,
  });

  const [concessions, setConcessions] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [species, setSpecies] = useState([]);
  const loadConcessionList = () => {
    StockService.GetAllConcessions().then((res) => {
      let data = res.map((country)=>{
        country.value = country.concessionId;
        country.label = country.concessionName;
        return country;
     })
      // data.unshift({value:0,label:"Make Selection"});
      
         setConcessions(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadConcessionList();
  }, []);


  const loadSpeciesList = () => {
    StockService.GetAllSpecies().then((res) => {
      let data = res.map((country)=>{
        country.value = country.speciesId;
        country.label = country.speciesName;
        return country;
     })
         setSpecies(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadSpeciesList();
  }, []);


  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
    if(id === 'concessionId'){
      let compactmentList = concessions.find(c=>c.concessionId === parseInt(e.target.value)); 
   
      if(compactmentList.compartments.length>0){
        let data = compactmentList.compartments.map((country)=>{
              country.value = country.compartmentId;
              country.label = country.compartmentName;
              return country;
          })
          console.log(data)
       setCompartments(data)
      }
     
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading(`Saving Tree ...`);
    StockService.AddTree(
      state.speciesId,
      state.treeName,
      state.volume,
      state.marketValue,
      state.concessionId,
      state.compartmentId,
      state.price,
      state.description
    )
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.Successful) {
          toast.success(`Basic Tree Information Saved. Please wait while images get uploaded`);
          if(files && files.length >0){
            uploadTreeImages(files,res.data.treeId||1)
          }else{
            navigate('/dashboard/stock-management/trees')
          }
          
        } else {
          toast.error(
            res.message
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };


  const [files, setFiles] = useState([]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    console.log(files);
  }, [files]);

  const uploadTreeImages = (e,treeId) => {
   
    const toastId = toast.loading(`Please image(s) upload in progress`);
    StockService.SaveTreeImage(e,treeId)
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.isSuccessful) {
          toast.success(`Image(s) upload successful`);
          navigate('/dashboard/stock-management/trees')
        } else {
          toast.error(
            "Upload Failed, Delete file and Try again"
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      <GoBack />
      <h2 className="my-2 text-xl font-medium text-gray-700 ">New Tree</h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Tree Name</span>
          <Input placeholder="Tree Name" type="text" value={state.treeName} 
            onChange={handleChange}
            required
            id="treeName" />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Tree Volume</span>
          <Input placeholder="Tree Volume" type="text" value={state.volume}
          onChange={handleChange}
          required
          id="volume"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Market Value
          </span>
          <Input
            placeholder="Market Value"
            type="text"
            value={state.marketValue}
            onChange={handleChange}
            required
            id="marketValue"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Concession</span>
          
          <Select
            placeholder="Concession"
            type="text"
            value={state.concessionId}
            options={concessions}
            onChange={handleChange}
            required
            id="concessionId"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Compartment</span>
          <Select
            placeholder="Compartment"
            type="text"
            label="Select A Compartment"
            value={state.compartmentId}
            options={compartments}
            onChange={handleChange}
            required
            id="compartmentId"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Species</span>
          <Select
            placeholder="Species"
            type="text"
            label="Select A Species"
            value={state.speciesId}
            options={species}
            onChange={handleChange}
            required
            id="speciesId"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Price</span>
          <Input placeholder="Price" type="text" value={state.price} 
          onChange={handleChange}
          required
          id="price"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            value={state.description}
            onChange={handleChange}
            required
            id="description"
          />
        </label>
      </FormGrid>
      <h3 className="my-2 text-lg font-medium text-gray-700 ">Tree Images</h3>
      <TreeDropzone files={files} setFiles={setFiles}/>

      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Tree
      </button>
    </form>
  );
};

export default AddTree;
