import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Search from "../StockManagement/sub-components/Search";
import TableActions from "../StockManagement/sub-components/TableActions";
import { StockService } from "../../../services/StockService";
import { LogService } from "../../../services/LogService";
import moment from "moment";
import AddnExport from "../FinanceManagement/sub-components/Add-Export";
const AllTreeFells = () => {
  const navigate = useNavigate();
  const treeFellColumns = [
    {
      name: "Tree Fell Code",
      selector: (row) => row.treeFellCode,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    // },
    {
      name: "Total Quantity",
      selector: (row) => row.totalQuantity,
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`/dashboard/tree-fell/${row.treeFellId}`)}
            noDelete
          />
        </div>
      ),
    },
  ];

  const [treefell,setTreefell] = useState([])
  useEffect(() => {
    handletreefell();
  }, []);
  const [pending, setPending] = useState(true);
  const handletreefell = () => {
    LogService.GetAllTreeLogs().then((res) => {
      
      setTreefell(...treefell, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
    console.log(search)
  }, [search]);
  return (
    <div className="w-full">
      <Search placeholder="Search TreeFells" />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeFellColumns}
        data={treefell.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.treeFellCode.toLowerCase().includes(search.toLowerCase())
           
          }
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default AllTreeFells;

const data = [
  {
    id: 1,
    treeFellCode: "treefell-234tvd",
    description: "treefell Description",
    status: "completed",
    date: new Date().toDateString(),
    totalQuantity: 130,
  },
  {
    id: 2,
    treeFellCode: "treefell-234tvd45",
    description: "treefell Description",
    status: "completed",
    date: new Date().toDateString(),
    totalQuantity: 130,
  },
  {
    id: 3,
    treeFellCode: "treefell-234t5jvd",
    description: "TreeFell Description",
    status: "completed",
    date: new Date().toDateString(),
    totalQuantity: 130,
  },
];
