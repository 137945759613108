import React from "react";
import { useNavigate } from "react-router-dom";
import TableActions from "../../StockManagement/sub-components/TableActions";
import { useState } from "react";
import { useEffect } from "react";
import { AccountService } from "../../../../services/AccountService";
import UserCard from "../../Users/sub-components/UserCard";
import {FiEye, FiEyeOff} from "react-icons/fi";
import * as CurrencyFormat from 'react-currency-format';
import AddnExport from "../sub-components/Add-Export";


const AllRevenue = () => {
  const navigate = useNavigate();
  const [shouldFilter, setShouldFilter] = useState(false);
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    console.log({ shouldFilter }, { filter });
  }, [shouldFilter, filter]);

  const toggleShouldFilter = () => setShouldFilter((prevState) => !prevState);


  const [accounts,setAccounts] = useState([])
  const [pending, setPending] = useState(true);
  useEffect(() => {
    handleaccounts();
  }, []);

   

    const handleaccounts = () => {
      AccountService.GetAllAccounts().then((res) => {
        
        setAccounts(...accounts, res);
        setPending(false);
        // console.log(concessions)
        
      });
    };

  return (
    <div className="w-full">
      <AddnExport />

        {accounts.map((account) => (
            <div className=" bg-green-600 max-w-sm w-full lg:max-w-full lg:flex  rounded-b lg:rounded-b-none lg:rounded-r p-0  mb-2 " key={account.accountId}>
              <div className="flex mb-2">
                <div className=" bg-green-600 text-white">
                  <div className="w-full p-2 divide-y divide-white">
                    <div className="py-3 px-3 font-bold  text-lg">

                      <p >  <small>Account Name: </small>{account.accountName} <button onClick={() => navigate(`/dashboard/finance-management/account/${account.accountId}`)} className="float-right border border-white-400 rounded rounded-lg px-2 py-1 "><FiEye /></button></p>

                    </div>

                  </div>
                  <div className="w-full">
                    <div className=" flex p-2  rounded-t lg:rounded-t-none lg:rounded-l text-center divide-x divide-white">
                      <div className="py-3 px-3 text-xl font-medium ">
                        <h5 > <small>Account Number: </small> {account.accountNumber}</h5>
                      </div>
                      <div className=" py-3 px-3 font-bold  text-lg">
                        <small>Previous Balance: </small>
                        <CurrencyFormat value={account.previousBalance} displayType={'text'} thousandSeparator={true} prefix={'GHS '} />
                      </div>

                      <div className="py-3 px-3  font-bold text-lg">
                        <small >Current Balance: </small>
                        <CurrencyFormat value={account.currentBalance} displayType={'text'} thousandSeparator={true} prefix={'GHS '} />

                      </div>
                      <div className=" py-3 px-3 font-bold  text-lg">
                        <small>Total Credit Balance: </small>
                        <CurrencyFormat value={account.totalCreditBalance} displayType={'text'} thousandSeparator={true} prefix={'GHS '} />
                      </div>
                      <div className=" py-3 px-3 font-bold  text-lg">
                        <small>Total Debit Balance: </small>
                        <CurrencyFormat value={account.totalDebitBalance} displayType={'text'} thousandSeparator={true} prefix={'GHS '} />
                      </div>

                    </div>
                  </div>
                  <div className="w-full p-2">
                    <p className="py-3 px-3 text-white text-base">{account.description}</p>
                  </div>

                </div>

              </div>

            </div>


        ))}




    </div>
  );
};

export default AllRevenue;


