import React, { useState, useContext, useEffect } from "react";
import GoBack from "../sub-components/GoBack";
import OrderItems from "./sub-components/OrderItems";
import TotalSection from "./sub-components/TotalSection";
import { RiFileDownloadLine } from "react-icons/ri";
import Input from "../../Input";
import OrderStatusSteps from "./sub-components/OrderStatusSteps";
import { OrderService } from "../../../services/OrderService";
import { Link, useParams } from "react-router-dom";
import Orders from "./Orders";
import toast from "react-hot-toast";
import swal from "sweetalert";
import Select, { OptionProps } from 'react-select';
const OrderDetails = () => {
  const params = useParams();
  const [disabled, setDisabled] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [dispatchTypes, setDispatchTypes] = useState([]);
  const [statusLabel, setStatusLabel] = useState('In-Progress');
  let labels = [
    {orderStatusId: 1, orderStatusName: "New Order", description: "New Order"},
    {orderStatusId: 2, orderStatusName: "InProgress", description: "In Progress"},
    {orderStatusId: 3, orderStatusName: "Transported", description: "Transport and in transit"},
    {orderStatusId: 4, orderStatusName: "Delivered", description: "Delivered to client"}
  ];

  const [selectedDispatchOption, setSelectedDispatchOption] = useState(null);
  
  const handleDispatchChange =(e)=> {
    // console.log(e)
    setSelectedDispatchOption(e);
    state.dispatchType = e
    
  };
  const updateStatus = () =>{
    console.log(state)
    console.log(orderStatuses)
    console.log(dispatchTypes)
    // setActiveStep((prevState) => (prevState < 3 ? prevState + 1 : prevState));
  }
    

  const [state, setState] = useState({
    orderId: 1,
    orderNumber: "order-234tvd",
    description: "Order Description",
    status: "completed",
    client: "Kwesi",
    dispatchType: "type",
    currency: "GHS",
    numberOfProducts: 13,
    deliveryAddress: "Delivery Address",
    totalAmount: 130,
    destinationCountryId: 2,
    comment: "",
    dispatchTypeId: 3,
    email: "tcl@gmail.com",
    phoneNumber: "0200033400",
    currencyId: 3,
    vatAmount: 4,
    contractNumber: "23456",
    contractDocumentUrl: "https://contractdocurl.com",
    contractDocumentFile: "",
    orderItems:[],
    orderStatus:{},
    orderStatusId:0


  });

  const loadOrderDetails = () => {
    OrderService.GetOrderDetail(parseInt(params.id)).then((res) => {
      // console.log(res.orderItems)
      setState({...state,
        orderId: res.orderId,
        orderNumber: res.orderNumber,
        description: res.description,
        status: res.status||'',
        client: res.user.firstName+''+res.user.lastName||'',
        dispatchType: res.dispatchType||'',
        currency: res.currency||'',
        numberOfProducts: res.numberOfProducts,
        deliveryAddress: res.deliveryAddress||'',
        totalAmount: res.totalAmount,
        destinationCountryId: res.destinationCountryId,
        comment: res.comment,
        dispatchTypeId: res.dispatchTypeId||0,
        email: res.email,
        phoneNumber: res.phoneNumber,
        currencyId: res.currencyId||0,
        vatAmount: res.vatAmount,
        contractNumber: res.contractNumber,
        contractDocumentUrl: res.contractFileUrl||'',
        contractDocumentFile: res.contractDocumentFile||'',
        orderItems:res.orderItems||[],
        orderStatus:res.orderStatus,
        orderStatusId:res.orderStatusId
      })
    });
  };
  useEffect(() => {
    loadOrderDetails();
  }, []);

const loadOrderStatus = () => {
  OrderService.GetOrderStatus().then((res) => {
    // console.log(res.orderItems)
    setOrderStatuses(...orderStatuses,res)
  });
};
useEffect(() => {
  loadOrderStatus();
}, []);
const loadDispatch = () => {
  OrderService.GetDispatchTypes().then((res) => {
    // console.log(res.orderItems)
    let data = res.map((user)=>{
      user.value = user.dispatchTypeId;
      user.label = user.dispatchTypeName;
      return user;
   })
    setDispatchTypes(...dispatchTypes,data)
  });
};
useEffect(() => {
  loadDispatch();
}, []);
useEffect(() => {
  console.log(state)
  setActiveStep(parseInt(state.orderStatusId)-1)
  let x = labels.findIndex(x=>x.orderStatusName===state.orderStatus.orderStatusName)
  if(x < 3){
    setStatusLabel(labels[x+1].orderStatusName)
  }else{
    setStatusLabel('Delivered')
  }
 
}, [state,labels]);


const updateOrderStatus = (orderStatusId,orderStatusLabel) => {
  OrderService.UpdateOrderStatus(parseInt(params.id),parseInt(orderStatusId)).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setActiveStep(parseInt(orderStatusId)-1)
        setStatusLabel(orderStatusLabel);
        window.location.reload();
      } else {
        toast.error(res.message);
      }
  
      //  console.log(state)
    });
  };

  const changeStatus = () => {
    swal({
      // title: "Are you sure?",
      text: "The order status will be changed. Client will be notified !!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(state)
        if(state.orderStatus && state.orderStatus.orderStatusName === "New Order"){
          let nextStep = orderStatuses.find(x=>x.orderStatusName === "InProgress");
          console.log(nextStep)
          updateOrderStatus(nextStep.orderStatusId, nextStep.orderStatusName);
         
        }
        if(state.orderStatus && state.orderStatus.orderStatusName === "InProgress"){
          let nextStep = orderStatuses.find(x=>x.orderStatusName === "Transported");
          console.log(nextStep)
          updateOrderStatus(nextStep.orderStatusId, nextStep.orderStatusName);
         
        }

        if(state.orderStatus && state.orderStatus.orderStatusName === "Transported"){
          let nextStep = orderStatuses.find(x=>x.orderStatusName === "Delivered");
          console.log(nextStep)
           updateOrderStatus(nextStep.orderStatusId, nextStep.orderStatusName);
         
        }
        
      } 
    });
  };

  const updateDispatchType =()=>{
    swal({
      // title: "Are you sure?",
      text: "The order dispatch status will be changed",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        console.log(state)
        console.log(selectedDispatchOption)
        if(selectedDispatchOption){
          
          OrderService.UpdateOrderDispatchStatus(parseInt(params.id),parseInt(selectedDispatchOption.dispatchTypeId)).then((res) => {
            if (res.Successful) {
              toast.success("Dispatch Status Updated");
              // window.location.reload();
            } else {
              toast.error(res.message);
            }
          });
         
        }else{
          swal("Select a dispatch type !");
        }
        
        
        
      } 
    });
  }
  
  return (
    <div className="flex flex-col w-full">
      <GoBack />
      <div className="flex self-end space-x-2">
      {state.contractDocumentUrl && (
        <button
          type="button"
          className="flex items-center px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg hover:bg-gray-800 text-medium"
        >
          <span className=""><a href={state.contractDocumentUrl}>Download Contract</a></span>
        </button>
      )}
        {state.orderStatusId !== 4 && (
        <button
          className="px-2 py-1 bg-green-300 rounded rounded-lg text-tclPrimary"
          onClick={changeStatus}
          type="button"
        >
          Update Order Status to {statusLabel}
        </button>
        )}
      </div>
      <h1 className="my-3 text-3xl font-extrabold text-gray-600 uppercase">
        #{state.orderNumber}
      </h1>
      <OrderStatusSteps activeStep={activeStep} />
      {state.orderStatusId === 3 && (
      <div
        className="gap-4 px-5 my-10 2xl:px-0"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(200px,1fr))",
        }}
      >
        
        <label className="block">
         
        <span className="block mb-3 text-gray-700 text-md">Dispatch Type</span>
          <Select
            id="supervisorId"
            type="text"
            options={dispatchTypes}
            value={selectedDispatchOption}
            
            onChange={handleDispatchChange}
            required
            
          />
        </label>
        <label className="block">
            <button
            onClick={updateDispatchType}
            className="mt-8 sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-1 lg:py-2 my-3 rounded rounded-md self-end"
          >
            Set Dispatch Type
          </button>
        </label>

        
      </div>
      )}
      <div
        className="gap-4 px-5 my-10 2xl:px-0"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(200px,1fr))",
        }}
      >
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <Input
            placeholder="Description"
            disabled={disabled}
            type="text"
            value={state.description}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Delivery Address
          </span>
          <Input
            placeholder="Delivery Address"
            disabled={disabled}
            type="text"
            value={state.deliveryAddress.location||''}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Email</span>
          <Input
            placeholder="Email"
            disabled={disabled}
            type="text"
            value={state.email}
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Phone Number
          </span>
          <Input
            placeholder="Phone Number"
            disabled={disabled}
            type="text"
            value={state.phoneNumber}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Client</span>
          <Input
            placeholder="Client"
            disabled={disabled}
            type="text"
            value={state.client}
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Dispatch Type
          </span>
          <Input
            placeholder="Dispatch Type"
            disabled={disabled}
            type="text"
            value={state.dispatchType.dispatchTypeName||'Not Set'}
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Contract Number
          </span>
          <Input
            placeholder="Contract Number"
            disabled={disabled}
            type="text"
            value={state.contractNumber}
          />
        </label>
      </div>
     
      <OrderItems state={state} />
      <TotalSection  state={state}/>
    </div>
  );
};

export default OrderDetails;
