import React, { useState, useContext, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import Select from "../../../Select";
import {RiEditBoxLine, RiCloseLine, RiFileExcel2Fill} from "react-icons/ri";
import { HiMinusCircle, HiTrash } from "react-icons/hi";
import toast from "react-hot-toast";
import TextArea from "../../../TextArea";
import TreeDropzone from "./sub-components/TreeDropzone";
import manCuttingTimber from "../../../../assets/man_cutting_timber.jpg";
import {
  useParams,
} from "react-router-dom";
import { StockService } from "../../../../services/StockService";
import Modal from "react-modal";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Slider from 'react-touch-drag-slider'
import {YieldService} from "../../../../services/YieldService";
import DataTable from "react-data-table-component";
import {FiTrash} from "react-icons/fi";
import swal from "sweetalert";
import {StampageService} from "../../../../services/StampageService";
const dummyImages = [{src : manCuttingTimber}, {src : manCuttingTimber}, {src : manCuttingTimber}];

const EditYield = () => {
  const [disabled, setDisabled] = useState(true);
  const [concessions, setConcessions] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [species, setSpecies] = useState([]);
  const [files, setFiles] = useState([]);
  const [treeImages, setTreeImages] = useState([]);
  const [state, setState] = useState({
    treeName: "",
    yieldVolumeRangeId: 0,
    concessionId: 0,
    yieldCode: '',
    compartmentId: 0,
    rangeName: '',
    description: "",
    yieldUrl: '',
    yieldItems:[],

  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);


 
  const params = useParams();
  useEffect(() => {
    handleTree();

  }, []);



  const handleTree = () => {
    YieldService.GetYieldDetail(params.id).then((res) => {
       console.log(res)
      // setTreeImages(res.treeImages);
      setState(res);
    //   setState({
    //   ...state,
    //   treeName: res.treeName||"",
    //   volume: res.volume ||false,
    //   description:  res.description ||"",
    //   price: res.price||0,
    //   marketValue: res.marketValue||0,
    //   concessionId: res.concessionId||0,
    //   compartmentId: res.compartmentId||0,
    //   isAvailable: res.isAvailable||true,
    //   treeImages:res.treeImages
    //
    //
    // });
    
    
    
      // console.log(concession)
    });
  };
  
  const loadConcessionList = () => {
    StockService.GetAllConcessions().then((res) => {
      let data = res.map((country)=>{
        country.value = country.concessionId;
        country.label = country.concessionName;
        return country;
     })
         setConcessions(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadConcessionList();
  }, []);


  const loadSpeciesList = () => {
    StockService.GetAllSpecies().then((res) => {
      let data = res.map((country)=>{
        country.value = country.speciesId;
        country.label = country.speciesName;
        return country;
     })
         setSpecies(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadSpeciesList();
  }, []);

  useEffect(() => {
    let compactmentList = concessions.find(c=>c.concessionId === state.concessionId); 
    if(concessions.length>0 && state.concessionId !==0)
      if(compactmentList.compartments.length>0){
        let data = compactmentList.compartments.map((country)=>{
              country.value = country.compartmentId;
              country.label = country.compartmentName;
              return country;
          })
          
       setCompartments(data)
      }
      // setTreeImages(res.treeImages);

  }, [concessions,state]);
  // const loadTreeImage = () => {
  //   StockService.GetTreeImages(params.id).then((res) => {
  //        setTreeImages(res);
  //       //  console.log(concessions);
       
  //   });
  // };
  // useEffect(() => {
  //   loadTreeImage();
  // }, []);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
    if(id === 'concessionId'){
      let compactmentList = concessions.find(c=>c.concessionId === parseInt(e.target.value)); 
   
      if(compactmentList.compartments.length>0){
        let data = compactmentList.compartments.map((country)=>{
              country.value = country.compartmentId;
              country.label = country.compartmentName;
              return country;
          })
          console.log(data)
       setCompartments(data)
      }
     
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading(`Saving Tree ...`);
    StockService.UpdateTree(
      params.id,
      state.speciesId,
      state.treeName,
      state.volume,
      state.marketValue,
      state.concessionId,
      state.compartmentId,
      state.price,
      state.description
    )
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.Successful) {
          toast.success(`Basic Tree Information Saved. Please wait while images get uploaded`);
          console.log(files.length)

          
        } else {
          toast.error(
            res.message
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };



 useEffect(() => {
  // Make sure to revoke the data uris to avoid memory leaks
  console.log(state);
}, [state]);
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const treeFellItemsColumns = [
    {
      name: "YieldCode",
      selector: (row) => row.yieldCode,
    },
    {
      name: "Species Name",
      selector: (row) => row.speciesName,
    },
    {
      name: "Volume",
      selector: (row) => row.volumeByCount,
    },

    {
      name: "Average Volume",
      selector: (row) => row.averageVolume,
    },
    {
      name: "Average M3",
      selector: (row) => row.averageM3,
    },

    {
      name: "Actions",
      selector: (row) => (
          <div>
            <button
                className="px-2 py-1 text-gray-500 hover:bg-red-700 hover:text-white"
                onClick={()=>deleteAction(row.yieldItemId)}
            >
              <FiTrash/>
            </button>
          </div>
      ),
    },
  ];
  const deleteAction = (userId)=>{
    swal({
      title: "Are you sure?",
      text: "Item will be removed from Yield",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        YieldService.RemoveItem(parseInt(userId))
            .then((res) => {
              if (res.Successful) {
                toast.success(res.message);

                setTimeout(()=>{
                  const filteredRoles = state.yieldItems.filter((role) => role.yieldItemId !== userId );
                  console.log(filteredRoles)
                  setState({...state,yieldItems:filteredRoles});
                },1000)


              } else {
                toast.error(res.message);
              }
            })
            .catch((err) => {});
      }
    });
  }
  return (
    <>
     <GoBack />
      <div className="w-full ">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-700" >
          <thead className="text-xs text-gray-500 uppercase bg-gray-100 dark:bg-gray-500 dark:text-gray-200">
          <tr>
            <th  className="py-3 px-6">Yield Name</th>
            <th className="py-3 px-6">Range Name</th>
            <th className="py-3 px-6">Concession</th>
            <th className="py-3 px-6">Compartment</th>
            <th className="py-3 px-6">Stampage Document</th>
            <th className="py-3 px-6">Description</th>

          </tr>
          </thead>
          <tbody>
          <tr>
            <td  className="py-3 px-6">{state.yieldCode}</td>
            <td  className="py-3 px-6">{state.rangeName}</td>
            <td  className="py-3 px-6">{state.concession.concessionName}</td>
            <td  className="py-3 px-6">{state.compartment.compartmentName}</td>
            <td  className="py-3 px-6"> <span className="text-3xl"><a href={state.yieldUrl}>  <RiFileExcel2Fill/></a></span></td>
            <td  className="py-3 px-6">{state.description}</td>
          </tr>
          </tbody>

        </table>
      </div>

      <h4 className="my-2 text-lg font-medium text-gray-700 ">Yield Items</h4>
      <div className="w-full">
        <DataTable
            className="w-full rounded rounded-lg"
            columns={treeFellItemsColumns}
            data={state.yieldItems}
            pagination
        />
      </div>
  </>
  );
};

export default EditYield;


const ImagesDisplaySection = (props) => {
  //  console.log(props)
  let { disabled,state } = props;
  const [images, setImages] = useState([]);
  // const [images, setImages] = useState([...state.treeImages]);
  const handleDelete = (index) => {
    if (disabled) return;
    let remImage = images.filter((image, id) => id === index);
    StockService.RemoveTreeImage(remImage[0].treeImageId).then((res) => {
       if (res.Successful) {
         toast.success('Tree image removed');
         let newState = images.filter((image, id) => id != index);
         setImages(newState);
       } else {
         toast.error(res.message);
       }
 
       //  console.log(state)
     });
    
  };


  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   setImages(state.treeImages);
  // }, [state.treeImages]);

 
  return (
    <div className="flex flex-wrap w-full ">
      {images.map((image, index) => (
        <div
          className="relative cursor-pointer"
          key={index}
          onClick={() => handleDelete(index)}
        >
          <img
            src={image.imageUrl}
            alt=""
            className="object-cover w-24 h-24 m-2 rounded rounded-lg peer shrink-0 first:ml-0"
          />
          {!disabled && (
            <HiTrash className="absolute top-0 hidden text-xl text-red-700 right-1 peer-hover:block" style={{backgroundColor: "gray", borderRadius: "50%", padding: 3}} />
          )}
        </div>
      ))}
      {images.length === 0 && (
        <>
          <h2>No Images</h2>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

function App() {
  const [images, setImages] = useState([...dummyImages]);
  return (
        <Slider
          onSlideComplete={(i) => {
            console.log('finished dragging, current slide is', i)
          }}
          onSlideStart={(i) => {
            console.log('started dragging on slide', i)
          }}
          activeIndex={0}
          threshHold={100}
          transition={0.5}
          scaleOnDrag={true}
        >
          {images.map(({ url, title }, index) => (
            <img src={url} key={index} alt={title} />
          ))}
        </Slider>
  )
}
