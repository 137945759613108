import React, { useState, useContext, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import TextArea from "../../../TextArea";
import {
  useParams,
} from "react-router-dom";
import { StockService } from "../../../../services/StockService";
const EditSpecie = () => {
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    speciesName: "",
    isActive: false,
    description: "",
    totalNumberOfTrees: "",
    marketValue: "",
    numberOfTreesFelled: "",
    numberOfTreesRemaining: "",
  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   const toastId = toast.loading("Species Update In Progress...");
    StockService.UpdateSpecies(
      parseInt(params.id),
      state.speciesName,
      state.description,
      state.totalNumberOfTrees,
      state.marketValue,
      state.numberOfTreesFelled,
      state.numberOfTreesRemaining
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  const params = useParams();
  useEffect(() => {
    handleSpecies();
  }, []);

  const handleSpecies = () => {
    StockService.GetSpecies(params.id).then((res) => {
      // console.log(res)
      setState({
      ...state,
      speciesName: res.speciesName||"",
      isActive: res.isActive ||false,
      description:  res.description ||"",
      totalNumberOfTrees: res.totalNumberOfTrees||"",
      marketValue: res.marketValue||"",
      numberOfTreesFelled: res.numberOfTreesFelled||"",
      numberOfTreesRemaining: res.numberOfTreesRemaining||"",
  
      
    });
      // console.log(concession)
    });
  };

  const deactivateSpecies = ()=>{
    StockService.DeActivateSpecies(parseInt(params.id)).then((res) => {
     //  console.log(res)
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isActive: false,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 }
 const activateSpecies = () => {
  StockService.ActivateSpecies(parseInt(params.id)).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isActive: true,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 };

  return (
    <>
    <GoBack />
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      
      <div className="flex self-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
       {state.isActive ? (
          <button
          onClick={deactivateSpecies}
           type='button' className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50">
            - Deactivate
          </button>
        ) : (
          <button 
          onClick={activateSpecies}
          type='button' className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50">
            + Activate
          </button>
        )}
      </div>
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        {disabled ? "Species Details" : "Edit Species"}
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Species Name
          </span>
          <Input
            placeholder="Species Name"
            type="text"
            disabled={disabled}
            value={state.speciesName}
            onChange={handleChange}
            required
            id="speciesName"
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Number of Trees
          </span>
          <Input
            placeholder="Total Number of Trees"
            type="text"
            disabled={disabled}
            value={state.totalNumberOfTrees}
            onChange={handleChange}
            required
            id="totalNumberOfTrees"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Market Value
          </span>
          <Input
            placeholder="Market Value"
            type="text"
            disabled={disabled}
            value={state.marketValue}
            onChange={handleChange}
            required
            id="marketValue"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Number of Trees Felled
          </span>
          <Input
            placeholder="Number of Trees Felled"
            type="text"
            disabled={disabled}
            value={state.numberOfTreesFelled}
            onChange={handleChange}
            required
            id="numberOfTreesFelled"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Number of Trees Remaining
          </span>
          <Input
            placeholder="Number of Trees Remaining"
            type="text"
            disabled={disabled}
            value={state.numberOfTreesRemaining}
            onChange={handleChange}
            required
            id="numberOfTreesRemaining"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            disabled={disabled}
            value={state.description}
            onChange={handleChange}
            required
            id="description"
          />
        </label>
      </FormGrid>
      {/* <div className={`${disabled ? "mt-0" : "mt-3"}`}>
        <input
          placeholder="Number of Trees Remaining"
          type="checkbox"
          id="isActive"
          disabled={disabled}
          value={state.isActive}
          onChange={handleChange}
          required
          
        />
        <span className="ml-3 text-gray-700 text-md">Active</span>
      </div> */}
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Species
        </button>
      )}
    </form>
    </>
    
  );
};

export default EditSpecie;
