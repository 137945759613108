import "./Chart.scss";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import CustomDateRangePicker from "../CustomDateRangePicker";
import { BsSearch } from "react-icons/bs";
import { BiX } from "react-icons/bi";

const data = [
  {
    name: "January",
    Total: 1200,
  },
  {
    name: "February",
    Total: 2100,
  },
  {
    name: "March",
    Total: 800,
  },
  {
    name: "April",
    Total: 1600,
  },
  {
    name: "May",
    Total: 900,
  },
  {
    name: "June",
    Total: 1700,
  },
];

const Chart = ({ aspect, title }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  return (
    <div className="chart">
      <div className="title">{title}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomDateRangePicker />
        <div className="search-container">
          <button type="submit">
            <BsSearch />
          </button>
          <input type="text" placeholder="Search by user" />
          <button>
            <BiX />
          </button>
        </div>
      </div>

      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={830}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3e7c17" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#3e7c17" stopOpacity={0} />
            </linearGradient>
            {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient> */}
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          {/* <YAxis /> */}
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#3e7c17"
            fillOpacity={1}
            fill="url(#total)"
          />
          {/* <Area
            type="monotone"
            dataKey="pv"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorPv)"
          /> */}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
