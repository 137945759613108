import React, { useState ,useEffect,useContext} from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import Select, { OptionProps } from 'react-select';
import { testConcessions } from "../../../../utils";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";

import { GrDeliver } from "react-icons/gr";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import PaymentHistoryTable from "./sub-components/PaymentHistoryTable";
import UserContext from "../../../../context/UserContext";
import { useNavigate } from "react-router";
import { PaymentService } from "../../../../services/PaymentService";
import { AccountService } from "../../../../services/AccountService";
import {
  useParams,
} from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
const RevenueDetails = () => {
  const [disabled, setDisabled] = useState(true);
  const user = useContext(UserContext);
  let history = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    accountId:"",
    accountName: "",
    accountNumber: "",
    description: "",
    currentBalance: 0,
    lastUpdatedByUserId: 0,
    transactionNote: "",
    accountTypeId: 0,
    accountHistories:[]
  });

  const [paymentComponents,setPaymentComponents] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [accountTypes,setAccountTypes] = useState([]);
  useEffect(() => {
    handlePaymentComponents();
  }, []);

  const handlePaymentComponents = () => {
    PaymentService.GetPaymentComponents().then((res) => {
      let data = res.accountTypes.map((country)=>{
        country.value = country.accountTypeId;
        country.label = country.accountTypeName;
        return country;
     })
      setAccountTypes(data);
      // setAccountTypes()
    
      console.log(data)
      
    });
  };

  useEffect(() => {
    handleFetchAccount();
  }, []);
  useEffect(()=>{
    if(state.accountTypeId !== 0 && accountTypes.length>0 ){
      let data = accountTypes.filter(x=>x.accountTypeId === state.accountTypeId);
      
      setSelectedOption(data)
      console.log(data)
    }
  },[state,accountTypes])
  const handleFetchAccount = () => {
    AccountService.GetAccountById(params.id).then((res) => {
     
      setState(res);
      // setAccountTypes()
      
      
    });
  };
const handleChange = (e) => {
  const { value, id } = e.target;
  setState((oldState) => ({ ...oldState, [id]: value }));
};

const handleSelectChange = (e) => {
  console.log(e)
  setSelectedOption(e);
  // state.driverId = e.value
  setState({...state, accountTypeId:e.value});
};
  const toggleDisable = () => setDisabled((oldState) => !oldState);


  
  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Updating Account  ...");
     const addCompactmentPromise = AccountService.UpdateAccount(
       state.accountId,
       parseInt(state.accountTypeId),
       state.description,
       state.accountName,
       state.accountNumber,
        state.currentBalance,
        user.userData.userId,
        state.transactionNote
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         
        //  test(res.data)
         history("/dashboard/finance-management/account");
         
       } else {
         toast.error(res.message);
       }
     });
   
  };

  const treeFellItemsColumns = [
    {
      name: "Payment Name",
      selector: (row) => row.accountName,
    },
    {
      name: "Payment Name",
      selector: (row) => row.accountNumber,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Current Balance",
        selector: (row) => ` GHS ${row.currentBalance}`,
    },
    {
      name: "Previous Balance",
      selector: (row) => ` GHS ${row.previousBalance}`,
    },
    {
      name: "Total Credit Balance",
      selector: (row) => ` GHS ${row.totalCreditBalance}`,
    },
    {
      name: "Total Debit Balance",
      selector: (row) => ` GHS ${row.totalDebitBalance}`,
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('YYYY-MM-DD'),
    },
  ];

  return (
    <>
      <GoBack />
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>

      <div className="flex self-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
        
      </div>
      <h2 className="my-3 text-xl font-medium text-gray-700 ">
        {disabled ? "Account Details" : "Edit Account"}
      </h2>
      

      <FormGrid>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Account Name</span>
            <Input
              placeholder="Account Name"
              id="accountName"
              type="text"
              value={state.accountName}
              onChange={handleChange}
              required
              disabled={disabled}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Account Number</span>
            <Input
              placeholder="Account Number"
              id="accountNumber"
              type="text"
              value={state.accountNumber}
              onChange={handleChange}
              required
              disabled={disabled}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Current Balance</span>
            <Input
              placeholder="Current Balance"
              id="currentBalance"
              type="text"
              value={state.currentBalance}
              onChange={handleChange}
              required
              disabled={disabled}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Account Type</span>
            <Select
              placeholder="Account Type"
              label="Select Account Type"
              options={accountTypes}
              type="text"
              required
              value={selectedOption }
             onChange={handleSelectChange}
             id="accountTypeId"
             isDisabled={disabled}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Note</span>
            <TextArea
              placeholder="Transaction Note"
              type="text"
              value={state.transactionNote}
              onChange={handleChange}
              required
              id="transactionNote"
              disabled={disabled}
            />
          </label>
          
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Description</span>
            <TextArea
              id="description"
              placeholder="Description"
              type="text"
              value={state.description}
              onChange={handleChange}
              required
              disabled={disabled}
            />
          </label>
          
          
        </FormGrid>
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Account
        </button>
      )}
      <h2 className="my-4 text-lg font-semibold">Account History</h2>
      
      
    </form>
    <div className="w-full">
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeFellItemsColumns}
        data={state.accountHistories}
        pagination
      />
    </div>
    </>
  );
};

export default RevenueDetails;
