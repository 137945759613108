import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _StampageService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;

    async GetAllYields(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stampage/get/all/stumpages");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetYieldRanges(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stampage/get/ranges");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }





    async CreateTreeFell(
        teamId: number,
        description: string,
        clerkId: string,
        supervisorId: number,
        managerId: number,
        totalQuantity: number,
        treeFellItems: any
    ): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Log/add/treefell", { teamId, description, clerkId, supervisorId, managerId, totalQuantity, treeFellItems });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Create Tree Fell Log. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }



    async UploadStampage(image: any,compactmentId:any,concessionId:any,description:any,contractorName:any,forestCode:any,cokNumber:any,referenceNumber:any,district:any,localMark:any,stoolLandOwners:any,districtAssembly:any) {
        let data = new FormData();
        // data.append(`${docName}`, image);
        data.append('file', image);
        data.append('compactmentid', compactmentId);
        data.append('concessionid', concessionId);
        data.append('description', description);
        data.append('contractorname', contractorName);
        data.append('forestcode', forestCode);
        data.append('coknumber', cokNumber);
        data.append('referencenumber', referenceNumber);
        data.append('district', district);
        data.append('localmark', localMark);
        data.append('stoollandowners', stoolLandOwners);
        data.append('districtassembly', districtAssembly);

        try {
            const res = await getAxios(this.baseUrl).post("/Stampage/upload/stumpage", data, { headers: { 'Content-Type': 'multipart/form-data' } });
            return res.data as ApiResponse<any>;
        } catch (error) {

            return null;
        }
    }

    async GetRanges(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Yield/get/ranges");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }
    async GetYieldDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Stampage/get/stumpage/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async RemoveItem(stampageItemId:number): Promise<any> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stampage/remove/stumpage/item", { stampageItemId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Remove Item. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async RemoveStampage(stampageId:number): Promise<any> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stampage/remove/stumpage", { stampageId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Remove Item. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }


    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }


}

export const StampageService = new _StampageService();
