import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Search from "../../StockManagement/sub-components/Search";
import TableActions from "../../StockManagement/sub-components/TableActions";
import AddnExport from "../../StockManagement/sub-components/Add-Export";
import { WaybillService } from "../../../../services/WaybillService";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from "react-icons/fi";
import toast from "react-hot-toast";
import moment from "moment";
const WaybillHistory = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);
  useEffect(() => {
    if(copied){
      toast.success('Order number copied');
      setCopied(false)
    }
  }, [copied]);
  useEffect(() => {
    if(copied2){
      toast.success('Waybill number copied');
      setCopied2(false)
    }
  }, [copied2]);
  const waybillColumns = [
    {
      name: "Order Number",
      selector: (row) =>
    <>
      {row.orderNumber}
      <CopyToClipboard text={row.orderNumber} onCopy={() => setCopied(true)}>

        <div className="copy">    <FiCopy></FiCopy></div>
      </CopyToClipboard>
    </>
    ,
    grow:2
    },
    {
      name: "WayBill Number",
      selector: (row) =>
    <>
      {row.waybillNumber}
      <CopyToClipboard text={row.waybillNumber} onCopy={() => setCopied2(true)}>

        <div className="copy">    <FiCopy></FiCopy></div>
      </CopyToClipboard>
    </>
    ,
    grow:2
    },
    // {
    //   name: "Client",
    //   selector: (row) => row.client,
    // },
    // {
    //   name: "Manager",
    //   selector: (row) => row.manager,
    // },
    {
      name: "Total Amount",
      selector: (row) => ` GHS ${row.totalAmount}`,
    },
    {
      name: "Gross Amount",
      selector: (row) => ` GHS ${row.grossAmount}`,
    },
    {
      name: "Vat Amount",
      selector: (row) => ` GHS ${row.vatAmount}`,
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('YYYY-MM-DD'),
    },

    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`/dashboard/waybills/history/${row.waybillId}`)}
            noDelete
          />
        </div>
      ),
    },
  ];
  const [waybills,setWaybills] = useState([])
  useEffect(() => {
    handleWaybills();
  }, []);
  const [pending, setPending] = useState(true);
  const handleWaybills = () => {
    WaybillService.GetAllWaybill().then((res) => {
      
      setWaybills(...waybills, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
    console.log(search)
  }, [search]);
  return (
    <div className="w-full">
      <AddnExport />
      <Search placeholder="Search Waybills" />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={waybillColumns}
        data={waybills.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.orderNumber.toLowerCase().includes(search.toLowerCase())
           
          }
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default WaybillHistory;

const data = [
  {
    id: 1,
    orderNumber: "order-234tvd",
    numberOfProducts: 13,
    totalAmount: 130,
    isPaid: true,
    driver: "Mensah",
    client: "Yaw",
    manager: "Gaisie",
    supervisor: "Ernest",
    personnel: "Senior Maen",
    amountPaid: 120,
  },
  {
    id: 2,
    orderNumber: "order-234tvd45",
    numberOfProducts: 13,
    totalAmount: 130,
    isPaid: true,
    driver: "Mensah",
    client: "Yaw",
    manager: "Gaisie",
    supervisor: "Ernest",
    personnel: "Senior Maen",
    amountPaid: 120,
  },
  {
    id: 3,
    orderNumber: "order-234t5jvd",
    numberOfProducts: 13,
    totalAmount: 130,
    isPaid: false,
    driver: "Mensah",
    client: "Yaw",
    manager: "Gaisie",
    supervisor: "Ernest",
    personnel: "Senior Maen",
    amountPaid: 120,
  },
];
