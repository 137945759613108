import React, { useState,useEffect,useContext } from "react";
import {
  FaAngleDown,
  FaAngleUp,
  FaRegBell,
  FaBell,
  FaTimesCircle,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { HubConnection,IHttpConnectionOptions,IRetryPolicy,LogLevel, HubConnectionBuilder } from "@microsoft/signalr";
import { config } from '../../../environments/environment'
import moment from "moment";
import UserContext from '../../../context/UserContext';
const NotificationDropdown = ({ visible, hide,setNotificationCount }) => {
  
  let list = ["A tree added", "An order made"];
  const navigate = useNavigate();
  const goToNotifications = async () => {
    await hide();
    navigate("/dashboard/notifications");
  };
  const user = useContext(UserContext);
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);


  var options = { withCredentials: false }
		var retyPolicy = {
			nextRetryDelayInMilliseconds: () => 5000
		}
	

  const getUserNotifications = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(`${config.url.NOTIFICATION_API_URL_FOR_SIGNALR}`,options)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect(retyPolicy)
        .build();

      connection.on("UnReadNotificationForAdmin", (messages) => {
        console.log(messages)
        setMessages(messages);
        setNotificationCount(messages.length)
      });

      

      connection.onclose(e => {
        setConnection();
        setMessages([]);
     
      });

      await connection.start();
      await connection.invoke("GetUnreadMessagesForAdmin");
      setConnection(connection);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
      getUserNotifications(); 
  }, []);
  return (
    <div
      hidden={!visible}
      className={`w-96  absolute p-3 top-[140%] right-0 h-30 bg-white border border-gray-300 rounded rounded-md shadow-md z-40`}
    >
      <div className="flex items-center justify-between w-full ">
        <span className="text-lg font-bold text-tclPrimary">Recent</span>
        <button
          onClick={hide}
          className="mb-2 text-xl text-black hover:text-red-700"
        >
          <FaTimesCircle />
        </button>
      </div>
      {messages.length > 0 ? <Notifications list={messages} /> : <NoNotifications />}
      {messages.length > 0 && (
        <button
          className="w-full py-2 mt-4 text-white rounded rounded-md bg-tclPrimary"
          onClick={goToNotifications}
        >
          See More
        </button>
      )}
    </div>
  );
};

export default NotificationDropdown;

const NoNotifications = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full space-y-3">
      
      <span className="noHeading">No Notifications</span>
      {/*<span className="noContent">*/}
      {/*  All your TCL */}
      {/*  notifications will show up here.*/}
      {/*</span>*/}
    </div>
  );
};

const Notifications = ({ list }) => {
  var newList = list.slice(0,4)
  return (
    <div className="w-full">
      {newList.map((listItem, index) => (
        <NotificationItem key={index} item={listItem} />
      ))}
    </div>
  );
};

const NotificationItem = ({
  item = "Lorem ipsum dolor sit amet consectetur adipisicing elit...",
}) => {
  return (
    <p className="w-full p-2 text-gray-700 border-b border-gray-100 rounded-md rounded-t rounded-b-none last:border-none last:rounded-t-none last:rounded-b last:rounded-md hover:bg-gray-100">
      {item.messageTitle}
    </p>
  );
};
