import React, {useContext, useState } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
// import {
//   ProfileImageSection,
//   SignatureSection,
// } from "../sub-components/DocumentUploads";
import { UserService } from "../../../services/UserService";
import UserContext from "../../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
const AddANewUser = () => {
  let history = useNavigate();
  const user = useContext(UserContext);
  const [state, setState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
   
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
     const toastId = toast.loading("Creating User");
     const t = UserService.RegisterUser(
       state.username,
       state.firstName,
       state.lastName,
       state.phoneNumber,
       state.email,
       user.userData.firstName + "" + user.userData.lastName
     )
       .then((res) => {
         toast.dismiss(toastId);
         console.log(res);
         if (res.Successful) {
           toast.success(res.message);
           history("/dashboard/users/all");
         } else {
           toast.error(res.message);
         }
       })
       .catch((error) => {
         toast.error(error.error);
       });
    
    
  };
  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      {/* <ProfileImageSection /> */}
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        Personal Details
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Username</span>
          <Input
            placeholder="Username"
            id="username"
            type="text"
            value={state.username}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">First Name</span>
          <Input
            placeholder="First Name"
            id="firstName"
            type="text"
            value={state.firstName}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Last Name</span>
          <Input
            placeholder="Last Name"
            id="lastName"
            type="text"
            value={state.lastName}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Email Address
          </span>
          <Input
            placeholder="Email Address"
            id="email"
            type="email"
            value={state.email}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Phone Number
          </span>
          <Input
            placeholder="eg: 0505707987"
            id="phoneNumber"
            type="tel"
            value={state.phoneNumber}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      {/* <h2 className="my-4 text-xl font-medium text-gray-700 ">Address Info</h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Location</span>
          <Input placeholder="Location" type="text" value={state.location} />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Address 1</span>
          <Input placeholder="Address 1" type="text" value={state.address1} />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Address 2</span>
          <Input placeholder="Address 2" type="text" value={state.address2} />
        </label>
      </FormGrid> */}
      {/* <h2 className="my-4 text-xl font-medium text-gray-700 ">Signature</h2>
      <SignatureSection /> */}
      <button
        type="submit"
        className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create User
      </button>
    </form>
  );
};

export default AddANewUser;

export const FormGrid = ({ children }) => {
  return (
    <div className="grid w-full grid-cols-1 gap-4 mb-2 sm:w-4/6 sm:grid-cols-2" onClick={e=>e.stopPropagation()}>
      {children}
    </div>
  );
};
