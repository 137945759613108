import React, {useContext, useEffect, useState} from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import Select from "react-select";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import TreeFellItems from "./sub-components/TreeFellItems";
import { AccountService } from "../../../../services/AccountService";
import { PaymentService } from "../../../../services/PaymentService";
import UserContext from "../../../../context/UserContext";
import { useNavigate } from "react-router";
import GetOrder from "../../Waybills/History/sub-components/GetOrder";
import GetWayBill from "./sub-components/GetWaybill";
import moment from "moment";
import {OrderService} from "../../../../services/OrderService";
const AddPayment = () => {
  const user = useContext(UserContext);
  let history = useNavigate();
  const [next, setNext] = useState(false);
  // i am using a dummy state to demonstrate the successful pull for an order
  const [gotOrder, setGotOrder] = useState(false);
  const [state, setState] = useState({
    paymentNote: "",
    amountPaid: 0,
    amountOutstanding: 0,
    totalAmount: 0,
    description: "",
    dueDate: moment().format("YYYY-MM-DD"),
    paymentMethodId: 0,
    paymentModeId:0,
    dueDateInDays:0,
    comment:'',
    wayBillId:0,
    paymentTypeId:0,
    accountId:0,
    orderNumber:'',
    entryByUserId:parseInt(user.userData.userId),
    wayBillNumber:"",
    userId:0

  });

  const [wayBill, setWayBill] = useState({
    waybillNumber: "",
    waybillId:"",
    orderNumber: "",
    driverId: "",
    clientId: "",
    managerId: "",
    supervisorId: "",
    personnelId: "",
    isPaid: "",
    totalAmount: 0,
    grossAmount:0,
    vatAmount:0,
    amountPaid: "",
    comment: "",
    currencyId: "",
    client:"",
    manager:{},
    supervisor:{},
    personnel:{},
    driver:{},
    order:[],
    user:{},
    orderId:0
  });
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));


  };

  // useEffect(()=>{
  //   if(state.amountPaid > 0){
  //     let outstanding = parseFloat(state.totalAmount) - parseFloat(state.amountPaid);
  //     setState({...state,amountOutstanding:outstanding})
  //   }
  // },[state.amountPaid])

  useEffect(()=>{
    if(state.dueDate ){
      let today = moment().format("YYYY-MM-DD");
      let dDate = moment(state.dueDate).diff(today,'days');
      setState({...state,dueDateInDays:dDate})
    }
  },[state.dueDate])

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  useEffect(() => {
    handlePaymentComponents();
  }, []);



  const handlePaymentComponents = () => {
    PaymentService.GetPaymentComponents().then((res) => {
      let data = res.paymentModes.map((country)=>{
        country.value = country.paymentModeId;
        country.label = country.paymentModeName;
        return country;
      })
      setPaymentModes(data);
      let data2 = res.paymentMethods.map((country)=>{
        country.value = country.paymentMethodId;
        country.label = country.paymentMethodName;
        return country;
      })
      setPaymentMethods(data2);

      let data3 = res.paymentTypes.map((country)=>{
        country.value = country.paymentTypeId;
        country.label = country.paymentTypeName;
        return country;
      })
      setPaymentTypes(data3);
      // setAccountTypes()

      console.log(data)

    });
  };

  const handlePaymentModeChange = (e) => {
    console.log(e)
    setSelectedPaymentModeOption(e);
    // state.driverId = e.value
    setState({...state, paymentModeId:e.value});
  };

  const [selectedPaymentModeOption, setSelectedPaymentModeOption] = useState(null);
  const [selectedPaymentMethodOption, setSelectedPaymentMethodOption] = useState(null);
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState(null);
  const [selectedAccountOption, setSelectedAccountOption] = useState(null);
  const handlePaymentMethodChange =(e)=> {
    // console.log(e)
    setSelectedPaymentMethodOption(e);
    setState({...state, paymentMethodId:e.value});

  };

  const handlePaymentTypeChange =(e)=> {
    // console.log(e)
    setSelectedPaymentTypeOption(e);
    setState({...state, paymentTypeId:e.value});

  };

  const [accounts,setAccounts] = useState([])
  useEffect(() => {
    handleAccounts();
  }, []);



  const handleAccounts = () => {
    AccountService.GetAllAccounts().then((res) => {
      let data = res.map((country)=>{
        country.value = country.accountId;
        country.label = country.accountName;
        return country;
      })
      setAccounts(data);
    });
  };

  const handleAccountChange =(e)=> {
    // console.log(e)
    setSelectedAccountOption(e);
    setState({...state, accountId:e.value});

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setState({...state, entryByUserId:parseInt(user.userData.userId)});
    console.log(state)
    const toastId = toast.loading("Adding New Payment.. ");
    PaymentService.AddNewPayment(
        state.entryByUserId,
        state.orderNumber,
        state.accountId,
        state.paymentTypeId,
        state.wayBillId,
        state.userId,
        state.paymentModeId,
        state.paymentMethodId,
        state.totalAmount,
        state.amountPaid,
        state.paymentNote,
        state.dueDateInDays,
        state.dueDate,
        state.comment

    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);

        //  test(res.data)
        history("/dashboard/finance-management/payments");

      } else {
        toast.error(res.message);
      }
    });
  };
  return (
      <div className="flex flex-col w-full">
        <GoBack />
        <h2 className=" text-xl font-medium text-gray-700 my-2">New Payment</h2>
        <GetWayBill wayBill={wayBill} setWayBill={setWayBill} setPayment={setState} payment={state}  gotOrder={() => setGotOrder(true)} />
        {gotOrder && (
            <>
              <br />
              <hr />
              <br />
              <form className="w-full flex flex-col" onSubmit={handleSubmit}>


                <FormGrid>
                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Order Number</span>
                    <Input
                        placeholder="Order Number"
                        id="orderNumber"
                        type="text"
                        value={state.orderNumber}
                        readOnly
                    />
                  </label>
                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">WayBill Number</span>
                    <Input
                        placeholder="wayBillNumber"
                        id="wayBillNumber"
                        type="text"
                        value={state.wayBillNumber}
                        readOnly
                    />
                  </label>


                  {/*<label className="block">*/}
                  {/*  <span className="block text-md  text-gray-700 mb-3">Amount Outstanding</span>*/}
                  {/*  <Input*/}
                  {/*    placeholder="Amount Outstanding"*/}
                  {/*    id="amountOutstanding"*/}
                  {/*    type="text"*/}
                  {/*    value={state.amountOutstanding}*/}
                  {/*    readOnly*/}
                  {/*    required*/}
                  {/*  />*/}
                  {/*</label>*/}
                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Total Amount</span>
                    <Input
                      placeholder="Total Amount"
                      id="totalAmount"
                      type="text"
                      value={state.totalAmount}
                      onChange={handleChange}
                      required
                      readOnly
                    />
                  </label>
                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Amount Paid</span>
                    <Input
                        placeholder="Amount Paid"
                        id="amountPaid"
                        type="text"
                        value={state.amountPaid}
                        onChange={handleChange}
                        required
                    />
                  </label>
                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Due Date</span>
                    <Input
                        placeholder="Due Date"
                        id="dueDate"
                        type="date"
                        value={state.dueDate}
                        onChange={handleChange}
                        required
                    />
                  </label>
                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Note</span>
                    <TextArea
                        placeholder="Payment Note"
                        id="paymentNote"
                        type="text"
                        value={state.paymentNote}
                        onChange={handleChange}
                        required
                    />
                  </label>

                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Comments</span>
                    <TextArea
                        id="comment"
                      placeholder="Comments"
                      type="text"
                      value={state.comment}
                      onChange={handleChange}

                    />
                  </label>

                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Method</span>
                    <Select
                        id="paymentMethodId"
                      placeholder="Select Payment Method"
                      label="Select a Payment Method"
                      options={paymentMethods}
                      type="text"
                      value={selectedPaymentMethodOption}
                      onChange={handlePaymentMethodChange}
                      required
                    />
                  </label>

                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Mode</span>
                    <Select
                        id="paymentModeId"
                        placeholder="Select Payment Mode"
                        label="Select a Payment Mode"
                        options={paymentModes}
                        type="text"
                        value={selectedPaymentModeOption}
                        onChange={handlePaymentModeChange}
                        required
                    />
                  </label>

                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Type</span>
                    <Select
                        id="paymentTypeId"
                        placeholder="Select Payment Type"
                        label="Select a Payment Type"
                        options={paymentTypes}
                        type="text"
                        value={selectedPaymentTypeOption}
                        onChange={handlePaymentTypeChange}
                        required
                    />
                  </label>

                  <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Account</span>
                    <Select
                        id="accountId"
                        placeholder="Select Payment Account"
                        label="Select Account"
                        options={accounts}
                        type="text"
                        value={selectedAccountOption}
                        onChange={handleAccountChange}
                        required
                    />
                  </label>
                </FormGrid>
                {/* <h2>Items</h2>
                <TreeFellItems /> */}
                <button
                  type="submit"
                  className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
                >
                  Create Payment
                </button>
              </form>

            </>
        )}
      </div>
  );
};

export default AddPayment;
