//Dashboard Component

import React, { useMemo, useContext, useState } from "react";
import logo from "../../assets/TCL2.png";
import profile from "../../assets/man_cutting_timber.jpg";

import { FaAngleDown, FaAngleUp, FaBell, FaRegBell } from "react-icons/fa";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Sidebar from "./sub-components/Sidebar";
import ProfilePopup from "./sub-components/ProfilePopup";
import FloatingBottomNavBar from "./sub-components/FloatingBottomNavBar";
import UserContext from "../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import NotificationDropdown from "./sub-components/NotificationDropdown";
//Main Dashboard Component
const Dashboard = () => {
    let history = useNavigate();
  // console.log({ location });
  const user = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => setShowPopup((previousState) => !previousState);

  const arrow = useMemo(
    () => (showPopup ? <FaAngleUp /> : <FaAngleDown />),
    [showPopup]
  );

  const [notificationCount, setNotificationCount] = useState(0);
  const goHome = () =>{
      history('/dashboard/main')
  }

  return (
    <div className="flex flex-col w-screen h-screen bg-gray-100">
      {/* Dashboard Header */}
      <header className="flex justify-between items-center h-[7vh] shadow-sm bg-white px-2 md:px-6 relative">
        <img src={logo} alt="tcl" className="object-contain w-auto h-10" onClick={goHome} />
        <div className="flex items-center justify-center space-x-2 pointer">
          {user.userData.imageUrl ? (
            <div
              className="bg-green-700 bg-center bg-no-repeat bg-cover rounded rounded-full h-7 w-7"
              style={{ backgroundImage: `url(${user.userData.imageUrl})` }}
            ></div>
          ) : (
            <div
              className="bg-green-700 bg-center bg-no-repeat bg-cover rounded rounded-full h-7 w-7"
              style={{ backgroundImage: `url(${profile})` }}
            ></div>
          )}
          <Link to="profile" className="flex items-center">
            <span className="hidden ml-3 text-sm font-semibold text-gray-700 md:block">
              {user.userData.firstName} {user.userData.lastName}
            </span>
          </Link>
          <div className="relative">
            <div onClick={togglePopup} className="cursor-pointer">
                {/* <FaRegBell className={isActive ? "hidden" : "text-xl"} /> */}
                {/* <FaBell className={"text-xl text-tclPrimary"} />
                <div className="flex h-1.5 w-1.5 absolute bottom-1 right-[1px]">
                  <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-tclPrimary"></span>
                  <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-green-600"></span>
                </div> */}
                  <span className="relative inline-block">
                  <svg className="w-6 h-6 text-gray-700 fill-current" viewBox="0 0 448 512"><path d="M256 32V51.2C329 66.03 384 130.6 384 208V226.8C384 273.9 401.3 319.2 432.5 354.4L439.9 362.7C448.3 372.2 450.4 385.6 445.2 397.1C440 408.6 428.6 416 416 416H32C19.4 416 7.971 408.6 2.809 397.1C-2.353 385.6-.2883 372.2 8.084 362.7L15.5 354.4C46.74 319.2 64 273.9 64 226.8V208C64 130.6 118.1 66.03 192 51.2V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32H256zM224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512z" clipRule="evenodd" fillRule="evenodd"/></svg>
                
                  <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full">{notificationCount}</span>
                </span>

              </div>
              <NotificationDropdown
                visible={showPopup}
                hide={() => setShowPopup(false)}
                setNotificationCount={setNotificationCount}
              />
          </div>
        </div>
      </header>
      {/* Dashboard Main */}
      <main className="flex w-full h-[93vh] relative">
        <Sidebar />
        <section className="w-full 2xl:w-4/5 p-6 xl:p-10ful  h-[86vh] md:h-[91vh] scroll-smooth overflow-y-auto bg-gray-100">
          <Outlet />
        </section>
        <FloatingBottomNavBar />
      </main>
    </div>
  );
};

export default Dashboard;
