import React from "react";
import AddnExport from "./sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { ActivePill, InactivePill } from "./sub-components/StatusPill";
import Search from "./sub-components/Search";
import TableActions from "./sub-components/TableActions";

const AllSystemAccounts = () => {
    const navigate=useNavigate()
    const concessionColumns = [
      {
        name: "Account Name",
        selector: (row) => "Vodafone Cash",
      },
      {
        name: "Current Balance",
        selector: (row) => "GHS 0",
      },
      {
        name: "Previous Balance",
        selector: (row) => "GHS 0",
      },
      {
        name: "Total Balance",
        selector: (row) => "GHS 0",
      },
      {
        name: "Total Credit Balance",
        selector: (row) => "GHS 0",
      },
      {
        name: "Total Debit Balance",
        selector: (row) => "GHS 0",
      },
      {
        name: "Status",
        selector: (row) => row.isActive?<ActivePill >Active</ActivePill>:<InactivePill>Inactive</InactivePill>,
      },
      {
        name:'Actions',
        selector:(row)=><div>
          <TableActions viewAction={()=>navigate(`${row.id}`)} deleteAction={()=>window.confirm(`Are you sure you want to delete this concession`)} />
        </div>
      }
    
    
    ];
    return (
      <div className="w-full">
        <AddnExport />
        <Search/>
        <DataTable
          className="w-full rounded rounded-lg"
          columns={concessionColumns}
          data={data}
          pagination
        />
      </div>
    );
  };

export default AllSystemAccounts


const data = [
    {
      id: 1,
      concessionName: "Vodafone Cash",
      description: "Concession Description",
      size: 123.0,
      isActive: true,
    },
    {
      id: 2,
      concessionName: "A Concession",
      description: "Concession Description",
      size: 123.0,
      isActive: true,
    },
    {
      id: 3,
      concessionName: "A Concession",
      description: "Concession Description",
      size: 123.0,
      isActive: false,
    },
   
  ];