import React, {useEffect, useState} from "react";
import Input from "../../../Input";
import TextArea from "../../../TextArea";

import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import ReturnItems from "./sub-components/ReturnItems";
import { Link, useNavigate } from "react-router-dom";
import { UserService } from "../../../../services/UserService";
import Select, { OptionProps } from 'react-select';
import {OrderService} from "../../../../services/OrderService";
import TableActions from "../../StockManagement/sub-components/TableActions";
import swal from "sweetalert";
import toast from "react-hot-toast";
import {WaybillService} from "../../../../services/WaybillService";
import DataTable from "react-data-table-component";
import GetWayBill from "./sub-components/GetWaybill";
const AddReturn = () => {
  const [state, setState] = useState({
    orderNumber: "",
    driverId: 0,
    clientId: 0,
    managerId: 0,
    supervisorId: 0,
    personnelId: 0,
    waybillId: 0,
    totalAmount: 0,
    comment: "",
    vatAmount: 0,
    grossAmount: 0,
  });
  let history = useNavigate();
  const [next, setNext] = useState(false);
  // i am using a dummy state to demonstrate the successful pull for an order
  const [gotWayBill, setGotWayBill] = useState(false);

  const [dispatchTypes, setDispatchTypes] = useState([]);
  const [wayBillItems, setWayBillItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
      })
      setUsers(...users, data);
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);





  const [wayBill, setWayBill] = useState({
    orderNumber: "",
    driverId: "",
    clientId: "",
    managerId: "",
    supervisorId: "",
    personnelId: "",
    isPaid: "",
    totalAmount: 0,
    grossAmount:0,
    vatAmount:0,
    amountPaid: "",
    comment: "",
    currencyId: "",
    client:"",
    user:{},
    driver:{},
    supervisor:{},
    manager:{},
    personnel:{}



  });

  const orderItemColumns = [
    {
      name: "Product Name",
      selector: (row) => row.product.productName,
    },
    {
      name: "Species",
      selector: (row) => row.species.speciesName,
    },

    {
      name: "Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Price",
      selector: (row) => ` GHS ${row.price}`,
    },
    {
      name: "Width",
      selector: (row) => row.width,
    },
    {
      name: "Length",
      selector: (row) => row.length,
    },
    {
      name: "Thickness",
      selector: (row) => row.tickness,
    },
    {
      name: "Amount",
      selector: (row) => ` GHS ${row.amount}`,
    },
    {
      name: "Reason",
      selector: (row) => `${row.reason}`,
    },
    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
    {
      name: "Actions",
      selector: (row) => (
          <div>
            <TableActions
                viewAction={() => editItem(row)}
                deleteAction={() =>removeItem(row)}
            />
          </div>
      ),
    },
  ];


  const editItem = (row)=>{
    swal(`Enter new Quantity for ${row.product.productName}`, {
      content: "input",
    }).then((value) => {
          if(value){
            //find index of obect in array
            const pos = wayBillItems.findIndex(object => {
              return object.orderItemId === row.orderItemId;
            });
            wayBillItems[pos]['quantity'] = parseInt(value);
            wayBillItems[pos]['amount'] = parseInt(value)*parseInt( wayBillItems[pos]['price']);
            // wayBillItems[pos]['reason'] = value;
            setWayBillItems([...wayBillItems]);
          }else{
            swal("Invalid input");
          }
          reason(row);
          // swal(`You typed: ${value}`);
        });
  }

  const reason = (row)=>{
    swal(`Enter Return Reason for ${row.product.productName}`, {
      content: "input",
    }).then((value) => {
      if(value){
        //find index of obect in array
        const pos = wayBillItems.findIndex(object => {
          return object.orderItemId === row.orderItemId;
        });
        wayBillItems[pos]['reason'] = value;
        setWayBillItems([...wayBillItems]);
      }else{
        swal("Invalid input");
      }

      // swal(`You typed: ${value}`);
    });
  }
  const removeItem = (row)=>{
    swal({
      title: `About to remove ${row.product.productName}`,
      text: "Once deleted, it will not be part of this waybill",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
        .then((value) => {

          if(value){
            //find index of obect in array
            const pos = wayBillItems.findIndex(object => {
              return object.waybillItemId === row.waybillItemId;
            });
            const newData = wayBillItems.filter(x=>x.waybillItemId !== row.waybillItemId)

            setWayBillItems([...newData]);
          }

          // swal(`You typed: ${value}`);
        });
  }

  useEffect(()=>{
    if(wayBillItems.length>0){
      const sumall = wayBillItems.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
      setTotalAmount(sumall*1.125);
      setVatAmount(sumall*0.125);
      setGrossAmount(sumall)
    }
  },[wayBillItems])

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state)
    const toastId = toast.loading("Creating Return item ... ");
    WaybillService.AddReturns(
        wayBill.waybillId,
        wayBill.driverId,
        wayBill.managerId,
        wayBill.supervisorId,
        wayBill.personnelId,
        totalAmount,
        vatAmount,
        grossAmount,
        wayBillItems,
        state.comment
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        history("/dashboard/waybills/returns");
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
      <div className="flex flex-col w-full">
        <GoBack />
        <h2 className="my-2 text-xl font-medium text-gray-700 ">New Return</h2>
        <GetWayBill order={wayBill} setOrder={setWayBill} setOrderItems={setWayBillItems} gotOrder={() => setGotWayBill(true)} />
        {gotWayBill && (
            <>
              <br />
              <hr />
              <br />
              <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                <FormGrid>




                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                 Comment
                </span>
                    <TextArea id="comment"
                              placeholder="Comment"
                              required
                              type="text"
                              onChange={handleChange}
                              value={state.comment}/>

                  </label>





                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Manager
                </span>
                    <Input readOnly={true} type="text" value={wayBill.manager?.firstName +' '+wayBill.manager?.lastName}/>

                  </label>
                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Supervisor
                </span>
                    <Input readOnly={true} type="text" value={wayBill.supervisor?.firstName +' '+wayBill.supervisor?.lastName}/>

                  </label>
                  <label className="block">
                    <span className="block mb-3 text-gray-700 text-md">
                      Personnel
                    </span>
                    <Input readOnly={true} type="text" value={wayBill.personnel?.firstName +' '+wayBill.personnel?.lastName}/>

                  </label>
                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Total Amount
                </span>
                    <Input
                        placeholder="Total Amount"
                        type="text"
                        onChange={handleChange}
                        disabled={true}
                        value={totalAmount}
                    />
                  </label>
                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Gross Amount
                </span>
                    <Input
                        placeholder="Gross Amount"
                        id='grossAmount'
                        type="text"
                        onChange={handleChange}
                        disabled={true}
                        value={grossAmount}
                    />
                  </label>
                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Vat Amount
                </span>
                    <Input
                        placeholder="Gross Amount"
                        id='vatAmount'
                        type="text"
                        onChange={handleChange}
                        disabled={true}
                        value={vatAmount}
                    />
                  </label>
                </FormGrid>
                <br />
                <div className="w-full">
                  <DataTable
                      title={<span className="font-semibold">WayBill Items</span>}
                      data={[...wayBillItems]}

                      columns={orderItemColumns}
                  />

                </div>


                <button
                    type="submit"
                    className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
                >
                  Create Return
                </button>

              </form>
            </>
        )}
      </div>
  );
};

export default AddReturn;
