import React, { useState, useEffect } from "react";
import { testUsers, userRoles } from "../../../utils";
import UserCard from "./sub-components/UserCard";
import { UserService } from "../../../services/UserService";
import ReactPaginate from "react-paginate";
const ClientUsers = () => {
  const [users, setUsers] = useState([]);
    const [loading,setLoading] = useState(false);
  const loadUsers = () => {
      setLoading(true);
    UserService.GetClientUsers().then((res) => {
      
      setUsers(...users, res);
        setLoading(false);
    });
  };

   useEffect(() => {
 
       loadUsers();
     

   }, []);
    const itemsPerPage  = 12;
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(users.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(users.length / itemsPerPage));
    }, [itemOffset, itemsPerPage,users]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % users.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
  return (
      <>

          <div className="grid w-full grid-rows-auto grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4">

              {currentItems && currentItems.length > 0 ? (

                  currentItems.map((user) => (
                      <UserCard key={user.userId} {...user} />
                  ))

              ) : (
                  <div className="flex ">
                      <div className="px-1 text-gray-500 justify-self-center">
                          {!loading ? (
                              <span>No data found </span> ) : (
                              <span>Loading data...</span>
                          )}
                      </div>

                  </div>
              )}
          </div>
          <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              pageClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
              pageLinkClassName="page-item"
              previousClassName="page-item"
              previousLinkClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 focus:shadow-none"
              nextClassName="page-item"
              nextLinkClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"

              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName=" flex justify-center mx-auto mt-10"
              activeClassName="active bg-lime-600 text-white"
          />
      </>
  );
};

export default ClientUsers;
