import React, {useContext, useEffect, useState} from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import Select from "react-select";
import { testConcessions } from "../../../../utils";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import TreeFellItems from "./sub-components/TreeFellItems";
import {UserService} from "../../../../services/UserService";
import {ExpenseService} from "../../../../services/ExpenseService";
import {WaybillService} from "../../../../services/WaybillService";
import UserContext from "../../../../context/UserContext";
import {useNavigate} from "react-router-dom";

const AddExpense = () => {
  let history = useNavigate();
  const user = useContext(UserContext);
  const [state, setState] = useState({
    expenseForUserId: 0,
    recordedByUserId: user.userData.userId,
    comment: "",
    amount: 0,
    description: "",
    expenseTypeId: 0,
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };
  const [selectedDriverOption, setSelectedDriverOption] = useState(null);
  const [selectedExpenseOption, setSelectedExpenseOption] = useState(null);
  const handleDriverChange =(e)=> {
    // console.log(e)
    setSelectedDriverOption(e);
    state.expenseForUserId = e.value
  };
  const handleExpenseChange =(e)=> {
    // console.log(e)
    setSelectedExpenseOption(e);
    state.expenseTypeId = e.value
  };


  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
      })
      setUsers(...users, data);
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const [expenseTypes, setExpenseTypes] = useState([]);
  const loadExpenseTypes = () => {
    ExpenseService.GetExpenseTypes().then((res) => {
      let data = res.map((user)=>{
        user.value = user.expenseTypeId;
        user.label = user.expenseTypeName;
        return user;
      })
      setExpenseTypes(...expenseTypes, data);
    });
  };

  useEffect(() => {
    loadExpenseTypes();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state)
    const toastId = toast.loading("Creating expense ... ");
    ExpenseService.AddNewExpense(
        state.expenseForUserId,
        state.recordedByUserId,
        state.expenseTypeId,
        state.amount,
        state.description,
        state.comment
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        history("/dashboard/finance-management/expenses");
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
      <>
        <GoBack />
        <form className="w-full flex flex-col" onSubmit={handleSubmit}>

          <h2 className=" text-xl font-medium text-gray-700 my-2">New Expense</h2>
          <FormGrid>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Expense By
                </span>
              <Select
                  id="expenseForUserId"
                  options={users}
                  label="Select A User"
                  placeholder="User"
                  type="text"
                  value={selectedDriverOption}

                  onChange={handleDriverChange}
              />
            </label>
            <label className="block">
              <span className="block text-md  text-gray-700 mb-3">Expense Type</span>
              <Select
                  id="expenseTypeId"
                  options={expenseTypes}
                  label="Select Expense Type"
                  placeholder="Expense Type"
                  type="text"
                  value={selectedExpenseOption}
                  onChange={handleExpenseChange}
              />
            </label>
            <label className="block">
              <span className="block text-md  text-gray-700 mb-3">Amount </span>
              <Input
                  placeholder="Amount"
                  id="amount"
                  type="text"
                  value={state.amount}
                  onChange={handleChange}
                  required
              />
            </label>
            <label className="block">
              <span className="block text-md  text-gray-700 mb-3">Description</span>
              <TextArea
                  id="description"
                  placeholder="Description"
                  type="text"
                  value={state.description}
                  onChange={handleChange}


              />
            </label>
            <label className="block">
              <span className="block text-md  text-gray-700 mb-3">Comments</span>
              <TextArea
                  id="comment"
                  placeholder="Comments"
                  type="text"
                  value={state.comment}
                  onChange={handleChange}

              />
            </label>
          </FormGrid>
          {/* <h2>Items</h2>
      <TreeFellItems /> */}
          <button
              type="submit"
              className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Create Expense
          </button>
        </form>
      </>

  );
};

export default AddExpense;
