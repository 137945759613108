import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Search from "../../StockManagement/sub-components/Search";
import TableActions from "../../StockManagement/sub-components/TableActions";
import { useState } from "react";
// import FilterByClient from "./sub-components/FilterByClient";
import { useEffect } from "react";
import Expenses from "./Expenses";
import {ExpenseService} from "../../../../services/ExpenseService";
import AddnExport from "../sub-components/Add-Export";
import {UserService} from "../../../../services/UserService";
import moment from "moment";
import swal from "sweetalert";
import toast from "react-hot-toast";
const AllExpenses = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(0);
  const [payments,setPayments] = useState([])
  const [pending, setPending] = useState(true);
  const orderColumns = [

    {
      name: "Expense Type",
      selector: (row) => row.expenseType?.expenseTypeName,
    },
    {
      name: "Expense For",
      selector: (row) => row.expenseForUser?.firstName +' '+row.expenseForUser?.lastName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Total Amount",
      selector: (row) => ` GHS ${row.amount}`,
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('YYYY-MM-DD'),
    },

    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`/dashboard/finance-management/expenses/${row.expenseId}`)}
            deleteAction={()=>removeItem(row)}
          />
        </div>
      ),
    },
  ];

  const removeItem = (row)=>{
    swal({
      title: `About to remove expense entry`,
      text: "Once deleted, it will be removed permanently",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
        .then((value) => {

          if(value){
            //find index of obect in array
            ExpenseService.RemoveExpense(
                row.expenseId
            ).then((res) => {

              if (res.Successful) {
                toast.success(res.message);
                const newData = payments.filter(x=>x.expenseId !== row.expenseId)
                setPayments([...newData]);
              } else {
                toast.error(res.message);
              }
            });
          }
          // swal(`You typed: ${value}`);
        });
  }
  useEffect(() => {
    handlePayments();
  }, []);
  const handlePayments = () => {
    ExpenseService.GetAllExpense().then((res) => {

      setPayments(res);
      setPending(false);
      // console.log(concessions)

    });
  };

  const [search,setSearch] = useState("");
  return (
    <div className="w-full">
      <AddnExport />
      <div style={{ display: "flex", alignItems: "center" , gap: 10}}>

        <Search placeholder="Search Expenses" />

      </div>

      <DataTable
          className="w-full rounded rounded-lg"
          columns={orderColumns}
          data={payments.filter((item) => {
            if (search === "") {
              return item;
            } else{
              return item.description.toLowerCase().includes(search.toLowerCase())||
                  item.orderNumber.toLowerCase().includes(search.toLowerCase())

            }
          })}
          progressPending={pending}
          pagination
      />
    </div>
  );
};

export default AllExpenses;

const data = [
  {
    id: 1,
    orderNumber: "order-234tvd",
    description: "Order Description",
    status: "completed",
    numberOfProducts: "19/19/2003",
    totalAmount: 130,
    isActive: true,
  },
  {
    id: 2,
    orderNumber: "order-234tvd45",
    description: "Order Description",
    status: "completed",
    numberOfProducts: "19/19/2003",
    totalAmount: 130,
    isActive: true,
  },
  {
    id: 3,
    orderNumber: "order-234t5jvd",
    description: "Order Description",
    status: "completed",
    numberOfProducts: "19/19/2003",
    totalAmount: 130,
    isActive: false,
  },
];
