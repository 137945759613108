import React from "react";

const TotalSection = (props) => {
  return (
    <div className="flex flex-col space-y-0.5 w-full md:w-[20rem] self-end mt-10">
      <AmountSection label={"Number of Items"} value={props.state.orderItems.length} />
      <AmountSection label={"VAT"} value={`GHS ${(props.state.vatAmount).toFixed(2)}`} />
      <AmountSection
        label={"Gross Amount"}
        value={`GHS ${(props.state.totalAmount).toFixed(2)}`}
        filled
      />
        <AmountSection
            label={"Total Amount"}
            value={`GHS ${(props.state.totalAmount *1.125).toFixed(2)}`}
            filled
        />
    </div>
  );
};

export default TotalSection;

const AmountSection = ({ label, value, filled }) => {
  return (
    <div
      className={`px-2 py-3 ${
        filled ? "text-white bg-tclPrimary" : "text-tclPrimary bg-tclPrimary/20"
      } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}
    >
      <span className="font-semibold">{label}</span>
      <span className="">{value}</span>
    </div>
  );
};
