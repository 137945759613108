import React from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import Select from "../../../Select";

import toast from "react-hot-toast";
import { useState } from "react";
import TextArea from "../../../TextArea";
import { StockService } from "../../../../services/StockService";
const AddProductType = () => {
  const [state, setState] = useState({
    productType: '',
    isActive: false,
    description: "",
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Adding ProductType ");
    StockService.AddProductType(
       state.productType,
       state.description
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success("ProductType Saved");
         
       } else {
         toast.error(res.message);
       }
     });
   
  };
  return (
    <>
       <GoBack />
    
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      
        <h2 className="my-2 text-xl font-medium text-gray-700 ">New Product Type</h2>
        <FormGrid>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Product Type
            </span>
            <Input
              placeholder="Product Type"
              type="text"
              value={state.productType}
              id="productType"
              onChange={handleChange}
              required
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Description</span>
            <TextArea
              placeholder="Description"
              type="text"
              value={state.description}
              id="description"
              onChange={handleChange}
              required
            />
          </label>
        </FormGrid>
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Create Product Type
        </button>
      </form>

    </>
  );
};

export default AddProductType;
