import React, { useState,useEffect } from "react";
import Input from "../../../../Input";
import toast from "react-hot-toast";
import {WaybillService} from "../../../../../services/WaybillService";
const GetWayBill = (props) => {
    const [state, setState] = useState({
        waybillNumber: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        //props.gotOrder();
        console.log(e);
        const toastId = toast.loading("Searching for waybill ... ");
        WaybillService.GetWaybillDetailByWayBillNumber(state.waybillNumber).then((res) => {
            console.log(res)
            toast.dismiss(toastId);
            if(res){
                toast.success("Waybill Found");
                props.setOrder({...props.order,
                    waybillNumber: res.waybillNumber,
                    waybillId:res.waybillId,
                    orderNumber: res.orderNumber,
                    driverId: res.driverId,
                    clientId: res.clientId,
                    managerId: res.managerId,
                    supervisorId: res.supervisorId,
                    personnelId: res.personnelId,
                    isPaid: "",
                    totalAmount: res.totalAmount,
                    grossAmount:res.grossAmount,
                    vatAmount:res.vatAmount,
                    amountPaid: "",
                    comment: res.comment,
                    currencyId: "",
                    client:"",
                    manager:res.manager,
                    supervisor:res.supervisor,
                    personnel:res.personnel,
                    driver:res.driver,
                    order:res.order,
                    user:res.user

                });
                props.gotOrder();
                let data = res.waybillItems.map((user)=>{
                    user.reason = '';

                    return user;
                })
                props.setOrderItems(data);
            }else{
                toast.error("Oops ... Waybill not found");
            }

        });
    };

    const handleChange = (e) => {
        const { value, id } = e.target;
        setState((oldState) => ({ ...oldState, [id]: value }));
    };


    return (
        <form className="flex items-end my-5 space-x-2 " onSubmit={handleSubmit}>
            <div
                className="gap-4 2xl:px-0"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit,minmax(350px,1fr))",
                }}
            >
                <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Enter WayBill Number
          </span>
                    <Input
                        id="waybillNumber"
                        placeholder="Enter Waybill Number"
                        type="text"
                        value={state.waybillNumber}
                        onChange={handleChange}
                    />
                </label>
            </div>
            <button
                type="submit"
                className="w-auto px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-1 rounded rounded-md"
            >
                Get WayBill
            </button>
        </form>
    );
};

export default GetWayBill;
