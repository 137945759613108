import React, { useState, useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
// import Select from "../../Select";
import GoBack from "../sub-components/GoBack";
import TextArea from "../../TextArea";
import { WarehouseService } from "../../../services/WarehouseService";
import { Link, useNavigate } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import Select, { OptionProps } from 'react-select';
const AddWarehouse = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    warehouseName: "",
    location: "",
    managerId: 0,
    supervisorId: 0,
    description: "",
    totalQuantity: "",
    email: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state)
    const toastId = toast.loading("Adding new warehouse ");
    WarehouseService.AddWarehouse(
      state.warehouseName,
      state.description,
      state.location,
      state.managerId,
      state.supervisorId,
      state.email,
      state.phoneNumber
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        history("/dashboard/warehouse/all");
      } else {
        toast.error(res.message);
      }
    });
  };


  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);


  
  const handleSupervisorChange =(e)=> {
    // console.log(e)
    setSelectedSupervisorOption(e);
    state.supervisorId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };

  const handleMangerChange =(e)=> {
    // console.log(e)
    setSelectedManagerOption(e);
    state.managerId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };



  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
     })
      setUsers(...users, data);
    });
  };

   useEffect(() => {
       loadUsers();
   }, []);
  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      <GoBack />
      <h2 className="my-2 text-xl font-medium text-gray-700 ">New Warehouse</h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Warehouse Name
          </span>
          <Input
            placeholder="Warehouse Name"
            label="Warehouse Name"
            type="text"
            value={state.warehouseName}
            onChange={handleChange}
            required
            id="warehouseName"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Manager</span>
          <Select
            id="managerId"
            type="text"
            options={users}
            value={selectedManagerOption }
            onChange={handleMangerChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Supervisor</span>
          <Select
            id="supervisorId"
            type="text"
            options={users}
            value={selectedSupervisorOption}
           
            onChange={handleSupervisorChange}
            required
            
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Location</span>
          <Input
            placeholder="Location"
            label="Location"
            type="text"
            value={state.location}
            onChange={handleChange}
            required
            id="location"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Email</span>
          <Input
            placeholder="Email"
            label="Email"
            type="email"
            value={state.email}
            onChange={handleChange}
            required
            id="email"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Phone Number
          </span>
          <Input
            placeholder="Phone Number"
            label="Phone Number"
            type="tel"
            value={state.phoneNumber}
            onChange={handleChange}
            required
            id="phoneNumber"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            value={state.description}
            onChange={handleChange}
            required
            id="description"
          />
        </label>
      </FormGrid>
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Warehouse
      </button>
    </form>
  );
};

export default AddWarehouse;
