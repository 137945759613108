import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { WarehouseService } from "../../../services/WarehouseService";
import Search from "../StockManagement/sub-components/Search";
import {
  ActivePill,
  InactivePill,
} from "../StockManagement/sub-components/StatusPill";
import TableActions from "../StockManagement/sub-components/TableActions";
import moment from "moment";
const AllWarehouses = () => {
  const navigate = useNavigate();
  const warehouseColumns = [
    {
      name: "Warehouse Name",
      selector: (row) => row.warehouseName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Location",
      selector: (row) => row.location,
    },
    
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Active",
      selector: (row) =>
        row.isActive ? (
          <ActivePill>Active</ActivePill>
        ) : (
          <InactivePill>Inactive</InactivePill>
        ),
    },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`/dashboard/warehouse/${row.warehouseId}`)}
            noDelete
          />
        </div>
      ),
    },
  ];
  const [warehouses,setWarehouses] = useState([])
  useEffect(() => {
    handleWarehouses();
  }, []);
  const [pending, setPending] = useState(true);
  const handleWarehouses = () => {
    WarehouseService.GetAllWarehouses().then((res) => {
      
      setWarehouses(...warehouses, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
    console.log(search)
  }, [search]);
  return (
    <div className="w-full">
      <Search placeholder="Search Warehouses" updateList={handleSearch}/>
      <DataTable
        className="w-full rounded rounded-lg"
        columns={warehouseColumns}
        data={warehouses.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.warehouseName.toLowerCase().includes(search.toLowerCase())
           
          }
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default AllWarehouses;

const data = [
  {
    id: 1,
    warehouseName: "warehouse-234tvd",
    description: "Warehouse Description",
    isActive: true,
    location: "location",
    email: "tcl@gmail.com",
    phoneNumber: "024456789",
  },
  {
    id: 2,
    warehouseName: "warehouse-234tvd45",
    description: "Warehouse Description",
    isActive: true,
    location: "location",
    email: "tcl@gmail.com",
    phoneNumber: "024456789",
  },
  {
    id: 3,
    warehouseName: "warehouse-234t5jvd",
    description: "Warehouse Description",
    isActive: true,
    location: "location",
    email: "tcl@gmail.com",
    phoneNumber: "024456789",
  },
];
