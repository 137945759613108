import { nanoid } from "nanoid";
import React from "react";
import DataTable from "react-data-table-component";

const ExpenseHistoryTable = () => {
  const treeFellItemsColumns = [
    {
      name: "Payment Name",
      selector: (row) => row.id,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Amount Paid",
      selector: (row) => row.quantity,
    },
    {
      name: "Total Amount",
      selector: (row) => row.dimension,
    },
    {
      name: "Last Payment Date",
      selector: (row) => row.volume,
    },
    {
      name: "Due Date",
      selector: (row) => row.volume,
    },
  ];
  return (
    <div className="w-full">
      <DataTable
        className="w-full rounded rounded-lg"
        columns={treeFellItemsColumns}
        data={data}
        pagination
      />
    </div>
  );
};

export default ExpenseHistoryTable;

const data = [
  {
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
  {
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
  {
    description: "description",
    dimension: "dimension",
    quantity: "quantity",
    volume: "volume",
    status: "fell",
    id: nanoid(),
  },
];
