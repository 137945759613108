import React, {useContext, useEffect, useState} from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import Select from "react-select";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";

import { GrDeliver } from "react-icons/gr";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";

import TreeFellStatusSteps from "./sub-components/TreeFellStatusSteps";
import PaymentHistoryTable from "./sub-components/PaymentHistoryTable";
import moment from "moment";
import {PaymentService} from "../../../../services/PaymentService";
import {AccountService} from "../../../../services/AccountService";
import UserContext from "../../../../context/UserContext";
import { useNavigate } from "react-router";
import {useParams} from "react-router-dom";

const PaymentDetails = () => {
  const [disabled, setDisabled] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
    const user = useContext(UserContext);
    let history = useNavigate();
    const params = useParams();

  const toggleDisable = () => setDisabled((oldState) => !oldState);

    const [state, setState] = useState({
        paymentNote: "",
        amountPaid: 0,
        paymentId: 0,
        dueDate: moment().format("YYYY-MM-DD"),
        paymentMethodId: 0,
        paymentModeId:0,
        dueDateInDays:0,
        comment:'',
        wayBillId:0,
        paymentTypeId:0,
        accountId:0,
        entryByUserId:parseInt(user.userData.userId),
        wayBillNumber:"",
        amountOutstanding:0,
        totalAmount:0,
        orderNumber:0


    });
    const [paymentHistory, setPaymentHistory] = useState([]);

    const handleChange = (e) => {
        const { value, id } = e.target;
        setState((oldState) => ({ ...oldState, [id]: value }));


    };

    useEffect(()=>{
        if(state.dueDate ){
            let today = moment().format("YYYY-MM-DD");
            let dDate = moment(state.dueDate).diff(today,'days');
            setState({...state,dueDateInDays:dDate})
        }
    },[state.dueDate])

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentModes, setPaymentModes] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    useEffect(() => {
        handlePaymentComponents();
    }, []);



    const handlePaymentComponents = () => {
        PaymentService.GetPaymentComponents().then((res) => {
            let data = res.paymentModes.map((country)=>{
                country.value = country.paymentModeId;
                country.label = country.paymentModeName;
                return country;
            })
            setPaymentModes(data);
            let data2 = res.paymentMethods.map((country)=>{
                country.value = country.paymentMethodId;
                country.label = country.paymentMethodName;
                return country;
            })
            setPaymentMethods(data2);

            let data3 = res.paymentTypes.map((country)=>{
                country.value = country.paymentTypeId;
                country.label = country.paymentTypeName;
                return country;
            })
            setPaymentTypes(data3);
            // setAccountTypes()

            console.log(data)

        });
    };

    const handlePaymentModeChange = (e) => {
        console.log(e)
        setSelectedPaymentModeOption(e);
        // state.driverId = e.value
        setState({...state, paymentModeId:e.value});
    };

    const [selectedPaymentModeOption, setSelectedPaymentModeOption] = useState(null);
    const [selectedPaymentMethodOption, setSelectedPaymentMethodOption] = useState(null);
    const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState(null);
    const [selectedAccountOption, setSelectedAccountOption] = useState(null);
    const handlePaymentMethodChange =(e)=> {
        // console.log(e)
        setSelectedPaymentMethodOption(e);
        setState({...state, paymentMethodId:e.value});

    };

    const handlePaymentTypeChange =(e)=> {
        // console.log(e)
        setSelectedPaymentTypeOption(e);
        setState({...state, paymentTypeId:e.value});

    };

    const [accounts,setAccounts] = useState([])
    useEffect(() => {
        handleAccounts();
    }, []);



    const handleAccounts = () => {
        AccountService.GetAllAccounts().then((res) => {
            let data = res.map((country)=>{
                country.value = country.accountId;
                country.label = country.accountName;
                return country;
            })
            setAccounts(data);
        });
    };

    useEffect(() => {
        handleFetch();
    }, []);



    const handleFetch = () => {
        PaymentService.GetPaymentById(parseInt(params.id)).then((res) => {
            if(res){
                setState({...state,
                    paymentNote: '',
                    amountPaid: 0,
                    paymentId: res.paymentId,
                    dueDate: moment(res.dueDate).format("YYYY-MM-DD"),
                    paymentMethodId: res.paymentMethodId,
                    paymentModeId:res.paymentModeId,
                    dueDateInDays:res.dueDateInDays,
                    comment:'',
                    wayBillId:res.wayBillId,
                    paymentTypeId:res.paymentTypeId,
                    accountId:res.accountId,
                    entryByUserId:parseInt(user.userData.userId),
                    wayBillNumber:res.wayBillNumber,
                    amountOutstanding: res.amountOutstanding,
                    totalAmount: res.totalAmount,
                    orderNumber:res.orderNumber

                });
                setPaymentHistory(res.paymentHistories);

            }

        });
    };

    const handleAccountChange =(e)=> {
        // console.log(e)
        setSelectedAccountOption(e);
        setState({...state, accountId:e.value});

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // setState({...state, entryByUserId:parseInt(user.userData.userId)});
        console.log(state)
        const toastId = toast.loading("Updating Payment Records ... ");
        PaymentService.AddOutstandingPayment(
            state.paymentId,
            state.paymentNote,
            state.amountPaid,
            state.paymentModeId,
            state.paymentMethodId,
            state.dueDateInDays,
            state.dueDate,
            state.accountId,
            state.paymentTypeId,
            state.wayBillId,
            state.entryByUserId,
            state.comment

        ).then((res) => {
            toast.dismiss(toastId);
            if (res.Successful) {
                toast.success(res.message);

                //  test(res.data)
                history("/dashboard/finance-management/payments");

            } else {
                toast.error(res.message);
            }
        });
    };
  return (
      <>
          <GoBack />
        <form className="w-full flex flex-col" onSubmit={handleSubmit}>

          <div className="flex space-x-2 self-end">
            {disabled ? (
              <div className="">
                <button
                  onClick={toggleDisable}
                  type="button"
                  className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
                >
                  <RiEditBoxLine className="text-lg text-white" />
                </button>
                <button
                  type="button"
                  onClick={toggleDisable}
                  className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
                >
                  Edit
                </button>
              </div>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={toggleDisable}
                  className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
                >
                  <RiCloseLine className="text-lg text-white" />
                </button>
                <button
                  type="button"
                  onClick={toggleDisable}
                  className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
                >
                  Cancel
                </button>
              </div>
            )}

          </div>
          <h2 className=" text-xl font-medium text-gray-700 my-3">
            {disabled ? "Payment Details" : "Edit Payment"}
          </h2>


            <FormGrid>
                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Order Number</span>
                    <Input
                        placeholder="Order Number"
                        id="orderNumber"
                        type="text"
                        value={state.orderNumber}
                        readOnly
                        disabled={disabled}

                    />
                </label>



                <label className="block">
                  <span className="block text-md  text-gray-700 mb-3">Amount Outstanding</span>
                  <Input
                    placeholder="Amount Outstanding"
                    id="amountOutstanding"
                    type="text"
                    value={state.amountOutstanding}
                    readOnly
                    required
                    disabled={disabled}


                  />
                </label>
                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Total Amount</span>
                    <Input
                        placeholder="Total Amount"
                        id="totalAmount"
                        type="text"
                        value={state.totalAmount}
                        disabled={disabled}
                        required
                        readOnly

                    />
                </label>
                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Amount Paid</span>
                    <Input
                        placeholder="Amount Paid"
                        id="amountPaid"
                        type="text"
                        value={state.amountPaid}
                        onChange={handleChange}
                        required
                        disabled={disabled}
                    />
                </label>
                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Due Date</span>
                    <Input
                        placeholder="Due Date"
                        id="dueDate"
                        type="date"
                        value={state.dueDate}
                        onChange={handleChange}
                        required
                        disabled={disabled}
                    />
                </label>


                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Method</span>
                    <Select
                        id="paymentMethodId"
                        placeholder="Select Payment Method"
                        label="Select a Payment Method"
                        options={paymentMethods}
                        type="text"
                        value={selectedPaymentMethodOption}
                        onChange={handlePaymentMethodChange}
                        required
                        isDisabled={disabled}
                    />
                </label>

                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Mode</span>
                    <Select
                        id="paymentModeId"
                        placeholder="Select Payment Mode"
                        label="Select a Payment Mode"
                        options={paymentModes}
                        type="text"
                        value={selectedPaymentModeOption}
                        onChange={handlePaymentModeChange}
                        required
                        isDisabled={disabled}
                    />
                </label>

                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Type</span>
                    <Select
                        id="paymentTypeId"
                        placeholder="Select Payment Type"
                        label="Select a Payment Type"
                        options={paymentTypes}
                        type="text"
                        value={selectedPaymentTypeOption}
                        onChange={handlePaymentTypeChange}
                        required
                        isDisabled={disabled}
                    />
                </label>

                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Account</span>
                    <Select
                        id="accountId"
                        placeholder="Select Payment Account"
                        label="Select Account"
                        options={accounts}
                        type="text"
                        value={selectedAccountOption}
                        onChange={handleAccountChange}
                        required
                        isDisabled={disabled}
                    />
                </label>
                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Payment Note</span>
                    <TextArea
                        placeholder="Payment Note"
                        id="paymentNote"
                        type="text"
                        value={state.paymentNote}
                        onChange={handleChange}
                        required
                        disabled={disabled}
                    />
                </label>

                <label className="block">
                    <span className="block text-md  text-gray-700 mb-3">Comments</span>
                    <TextArea
                        id="comment"
                        placeholder="Comments"
                        type="text"
                        value={state.comment}
                        onChange={handleChange}
                        required
                        disabled={disabled}
                    />
                </label>
            </FormGrid>
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Update Payment
        </button>
      )}
      <h2 className="my-4 text-lg font-semibold">Payment History</h2>
      <PaymentHistoryTable history={paymentHistory}  />
      
    </form>
      </>
  );
};

export default PaymentDetails;
