import React, { useState } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import Select from "./sub-components/Select";
import { testConcessions } from "../../../utils";
import GoBack from "../sub-components/GoBack";
import TextArea from "../../TextArea";

const AddSystemAccount = () => {
  const [state, setState] = useState({
    accountName: "",
    accountNumber: "",

    description: 0,

    accountTypeId: 0,
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const createTreeFellPromise = new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve({
            message: "TreeFell Created Successfully",
            status: 200,
            data: [],
          }),
        1000
      )
    );
    // handles the toast loader for the login process
    toast.promise(createTreeFellPromise, {
      loading: "Creating TreeFell",
      success: "TreeFell Created Successfully",
      error: "TreeFell Create Failed",
    });
  };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <GoBack />
      <h2 className=" text-xl font-medium text-gray-700 my-2">New Payment</h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Account Name
          </span>
          <Input
            placeholder="Name"
            id="name"
            type="text"
            value={state.accountName}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Account Number
          </span>
          <Input
            placeholder="Amount Paid"
            id="name"
            type="text"
            value={state.accountNumber}
            onChange={handleChange}
            required
          />
        </label>

        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            value={state.description}
          />
        </label>

        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Account Type
          </span>
          <Select
            placeholder="Manager"
            label="Select a Payment Method"
            options={[]}
            type="text"
            value={state.accountTypeId}
          />
        </label>
      </FormGrid>
      {/* <h2>Items</h2>
      <TreeFellItems /> */}
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create System Account
      </button>
    </form>
  );
};

export default AddSystemAccount;
