import React, { useState } from "react";
import { produce } from "immer";
import Input from "../../../Input";
import { nanoid } from "nanoid";
import { RiCloseLine } from "react-icons/ri";
import Select, { OptionProps } from 'react-select';
const TreeFellItems = (props) => {
  // const [treeFellItems, setTreeFellItems] = useState([
  //   {
  //     description: "description",
  //     dimension: "dimension",
  //     quantity: "quantity",
  //     volume: "volume",
  //     id: nanoid(),
  //   },
  // ]);

  return (
    <div className="w-full">
      {props.treeFellItems.map((p, index) => {
        return (
          <div className="flex items-end space-x-3" key={p.id}>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">
                Tree
              </span>
              <Select

                        id={index}
                          // className="ot-s" 
                          options={props.trees}
                          value={p.tree}
                          name="treeId"
                          defaultValue={p.tree}
                          // onChange={(evnt)=>(handleSelectChange(index, evnt))}
                          
                          onChange={(e) => {
                            const tree = e;
                            console.log(e);
                            props.setTreeFellItems((currentTreeFellItems) =>
                              produce(currentTreeFellItems, (v) => {
                                v[index].tree = tree;
                                v[index].treeId = tree.treeId;
                              })
                            );
                          }}
                          menuPlacement="auto" menuPosition="fixed" placeholder="Tree"  className="treeSelect" />
              
            </label>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">
                Description
              </span>
              <Input
                onChange={(e) => {
                  const description = e.target.value;
                  props.setTreeFellItems((currentTreeFellItems) =>
                    produce(currentTreeFellItems, (v) => {
                      v[index].description = description;
                    })
                  );
                }}
                value={p.description}
                // placeholder="Description"
              />
            </label>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">
                Dimension
              </span>
              <Input
                onChange={(e) => {
                  const dimension = e.target.value;
                  props.setTreeFellItems((currentTreeFellItems) =>
                    produce(currentTreeFellItems, (v) => {
                      v[index].dimension = dimension;
                    })
                  );
                }}
                value={p.dimension}
                // placeholder="Dimension"
              />
            </label>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">Width(cm)</span>
              <Input
                onChange={(e) => {
                  const width = e.target.value;
                  props.setTreeFellItems((currentTreeFellItems) =>
                    produce(currentTreeFellItems, (v) => {
                      v[index].width = parseInt(width);
                    })
                  );
                }}
                value={p.width}
                // placeholder="Volume"
              />
            </label>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">Length(cm)</span>
              <Input
                onChange={(e) => {
                  const length = e.target.value;
                  props.setTreeFellItems((currentTreeFellItems) =>
                    produce(currentTreeFellItems, (v) => {
                      v[index].length = parseInt(length);
                    })
                  );
                }}
                value={p.length}
                // placeholder="Volume"
              />
            </label>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">Volume(&#13220;)</span>
              <Input
                onChange={(e) => {
                  const volume = e.target.value;
                  props.setTreeFellItems((currentTreeFellItems) =>
                    produce(currentTreeFellItems, (v) => {
                      v[index].volume = parseInt(volume);
                    })
                  );
                }}
                value={p.volume}
                // placeholder="Volume"
              />
            </label>
            <label className="block">
              <span className="block mb-3 text-gray-700 text-md">
                Quantity
              </span>
              <Input
                onChange={(e) => {
                  const quantity = e.target.value;
                  props.setTreeFellItems((currentTreeFellItems) =>
                    produce(currentTreeFellItems, (v) => {
                      v[index].quantity = parseInt(quantity);
                    })
                  );
                }}
                value={p.quantity}
                // placeholder="Quantity"
              />
            </label>
            <button
              className="flex items-center justify-center text-lg text-white bg-red-500 rounded rounded-full w-7 h-7"
              onClick={(e) => {
                e.preventDefault();
                if(p.treeFellItemId){
                  
                  props.deleteAction(p.treeFellItemId)
                }else{
                  props.setTreeFellItems((currentTreeFellItems) =>
                  currentTreeFellItems.filter((x) => x.id !== p.id)
                );
                }
                
              }}
            >
              <RiCloseLine />
            </button>
          </div>
        );
      })}
      <button
        className="self-end w-auto px-2 py-1 my-3 font-semibold text-white rounded rounded-md sm:px-3 bg-tclPrimary/75"
        onClick={(e) => {
          e.preventDefault();
          props.setTreeFellItems((currentTreeFellItems) => [
            ...currentTreeFellItems,
            {
              description: "",
              dimension: "",
              quantity: 0,
              volume: 0,
              width: 0,
              length: 0,
              tree:'',
              treeId:0,
              id: nanoid(),
            },
          ]);
        }}
      >
        Add Item
      </button>
      {/* <div>{JSON.stringify(treeFellItems, null, 2)}</div> */}
    </div>
  );
};

export default TreeFellItems;
