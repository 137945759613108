import React, { useState, useContext, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import Select from "../../../Select";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import { HiMinusCircle, HiTrash } from "react-icons/hi";
import toast from "react-hot-toast";
import TextArea from "../../../TextArea";
import TreeDropzone from "./sub-components/TreeDropzone";
import manCuttingTimber from "../../../../assets/man_cutting_timber.jpg";
import {
  useParams,
} from "react-router-dom";
import { StockService } from "../../../../services/StockService";
import Modal from "react-modal";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Slider from 'react-touch-drag-slider'
const dummyImages = [{src : manCuttingTimber}, {src : manCuttingTimber}, {src : manCuttingTimber}];

const EditTree = () => {
  const [disabled, setDisabled] = useState(true);
  const [concessions, setConcessions] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [species, setSpecies] = useState([]);
  const [files, setFiles] = useState([]);
  const [treeImages, setTreeImages] = useState([]);
  const [state, setState] = useState({
    treeName: "",
    volume: "",
    marketValue: 0.0,
    concessionId: 0,
    compartmentId: 0,
    price: 0.0,
    description: "",
    isAvailable: true,
    treeImages:[]
  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);


 
  const params = useParams();
  useEffect(() => {
    handleTree();

  }, []);



  const handleTree = () => {
    StockService.GetTree(params.id).then((res) => {
       console.log(res)
      // setTreeImages(res.treeImages);

      setState({
      ...state,
      treeName: res.treeName||"",
      volume: res.volume ||false,
      description:  res.description ||"",
      price: res.price||0,
      marketValue: res.marketValue||0,
      concessionId: res.concessionId||0,
      compartmentId: res.compartmentId||0,
      isAvailable: res.isAvailable||true,
      treeImages:res.treeImages
  
      
    });
    
    
    
      // console.log(concession)
    });
  };
  
  const loadConcessionList = () => {
    StockService.GetAllConcessions().then((res) => {
      let data = res.map((country)=>{
        country.value = country.concessionId;
        country.label = country.concessionName;
        return country;
     })
         setConcessions(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadConcessionList();
  }, []);


  const loadSpeciesList = () => {
    StockService.GetAllSpecies().then((res) => {
      let data = res.map((country)=>{
        country.value = country.speciesId;
        country.label = country.speciesName;
        return country;
     })
         setSpecies(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadSpeciesList();
  }, []);

  useEffect(() => {
    let compactmentList = concessions.find(c=>c.concessionId === state.concessionId); 
    if(concessions.length>0 && state.concessionId !==0)
      if(compactmentList.compartments.length>0){
        let data = compactmentList.compartments.map((country)=>{
              country.value = country.compartmentId;
              country.label = country.compartmentName;
              return country;
          })
          
       setCompartments(data)
      }
      // setTreeImages(res.treeImages);

  }, [concessions,state]);
  // const loadTreeImage = () => {
  //   StockService.GetTreeImages(params.id).then((res) => {
  //        setTreeImages(res);
  //       //  console.log(concessions);
       
  //   });
  // };
  // useEffect(() => {
  //   loadTreeImage();
  // }, []);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
    if(id === 'concessionId'){
      let compactmentList = concessions.find(c=>c.concessionId === parseInt(e.target.value)); 
   
      if(compactmentList.compartments.length>0){
        let data = compactmentList.compartments.map((country)=>{
              country.value = country.compartmentId;
              country.label = country.compartmentName;
              return country;
          })
          console.log(data)
       setCompartments(data)
      }
     
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading(`Saving Tree ...`);
    StockService.UpdateTree(
      params.id,
      state.speciesId,
      state.treeName,
      state.volume,
      state.marketValue,
      state.concessionId,
      state.compartmentId,
      state.price,
      state.description
    )
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.Successful) {
          toast.success(`Basic Tree Information Saved. Please wait while images get uploaded`);
          console.log(files.length)
          if(files && files.length >0){
            uploadTreeImages(files,params.id)
          }else{
            // navigate('/dashboard/stock-management/trees')
          }
          
        } else {
          toast.error(
            res.message
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  const uploadTreeImages = (e,treeId) => {
   
    const toastId = toast.loading(`Please image(s) upload in progress`);
    StockService.SaveTreeImage(e,treeId)
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.isSuccessful) {
          toast.success(`Image(s) upload successful`);
          // navigate('/dashboard/stock-management/trees')
        } else {
          toast.error(
            "Upload Failed, Delete file and Try again"
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  const deactivateTree = ()=>{
    StockService.DeActivateTree(parseInt(params.id)).then((res) => {
     //  console.log(res)
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isAvailable: false,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 }
 const activateTree = () => {
  StockService.ActivateTree(parseInt(params.id)).then((res) => {
      if (res.Successful) {
        toast.success(res.message);
        setState({
          ...state,
          isAvailable: true,
        });
      } else {
        toast.error(res.message);
      }

      //  console.log(state)
    });
 };

 useEffect(() => {
  // Make sure to revoke the data uris to avoid memory leaks
  console.log(state);
}, [state]);
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  
  return (
    <>
     <GoBack />
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
       
        <div className="flex self-end space-x-2">
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                type="button"
                className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                type="button"
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                type="button"
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                type="button"
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
              >
                Cancel
              </button>
            </div>
          )}
          {state.isAvailable ? (
            <button
            onClick={deactivateTree}
              type="button"
              className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50"
            >
              - Deactivate
            </button>
          ) : (
            <button
            onClick={activateTree}
              type="button"
              className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50"
            >
              + Activate
            </button>
          )}
        </div>
        <h2 className="my-2 text-xl font-medium text-gray-700 ">
          {disabled ? "Tree Details" : "Edit Tree"}
        </h2>
        <FormGrid>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Tree Name</span>
            <Input
              placeholder="Tree Name"
              disabled={disabled}
              type="text"
              value={state.treeName}
              onChange={handleChange}
            required
            id="treeName"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Tree Volume</span>
            <Input
              placeholder="Tree Volume"
              type="text"
              disabled={disabled}
              value={state.volume}
              onChange={handleChange}
              required
              id="volume"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Market Value
            </span>
            <Input
              placeholder="Market Value"
              type="text"
              disabled={disabled}
              value={state.marketValue}
              onChange={handleChange}
            required
            id="marketValue"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Concession</span>
            <Select
              placeholder="Concession"
              type="text"
              disabled={disabled}
              value={state.concessionId}
              options={concessions}
              onChange={handleChange}
            required
            id="concessionId"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Compartment</span>
            <Select
              placeholder="Compartment"
              type="text"
              disabled={disabled}
              label="Select A Compartment"
              value={state.compartmentId}
              options={compartments}
              onChange={handleChange}
              required
              id="compartmentId"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Species</span>
            <Select
              placeholder="Species"
              type="text"
              disabled={disabled}
              label="Select A Species"
              value={state.speciesId}
              options={species}
              onChange={handleChange}
            required
            id="speciesId"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Price</span>
            <Input
              placeholder="Price"
              type="text"
              disabled={disabled}
              value={state.price}
              onChange={handleChange}
          required
          id="price"
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Description</span>
            <TextArea
              placeholder="Description"
              disabled={disabled}
              type="text"
              value={state.description}
              onChange={handleChange}
            required
            id="description"
            />
          </label>
        </FormGrid>

        {/* <h3 className="my-2 text-lg font-medium text-gray-700 ">Tree Images</h3>
        <ImagesDisplaySection disabled={disabled} state={state} /> */}
        {!disabled && <TreeDropzone files={files} setFiles={setFiles} />}
        {!disabled && (
          <button
            type="submit"
            className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Edit Tree
          </button>
        )}
      {/* </div> */}
      {/* <h2 className="my-2 text-xl font-medium text-gray-700 ">
        {disabled ? "Tree Details" : "Edit Tree"}
      </h2> */}
     

      <h3 className="my-2 text-lg font-medium text-gray-700 ">Tree Images</h3>
      <div onClick={toggleModal}>
      <ImagesDisplaySection disabled={disabled} />
      </div>
      
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
      >
        <Carousel images={dummyImages} />
        <button onClick={toggleModal}>Close modal</button>
      </Modal>
      {!disabled && <TreeDropzone />}
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Tree
        </button>
      )}
    </form>
  </>
  );
};

export default EditTree;


const ImagesDisplaySection = (props) => {
  //  console.log(props)
  let { disabled,state } = props;
  const [images, setImages] = useState([]);
  // const [images, setImages] = useState([...state.treeImages]);
  const handleDelete = (index) => {
    if (disabled) return;
    let remImage = images.filter((image, id) => id === index);
    StockService.RemoveTreeImage(remImage[0].treeImageId).then((res) => {
       if (res.Successful) {
         toast.success('Tree image removed');
         let newState = images.filter((image, id) => id != index);
         setImages(newState);
       } else {
         toast.error(res.message);
       }
 
       //  console.log(state)
     });
    
  };


  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   setImages(state.treeImages);
  // }, [state.treeImages]);

 
  return (
    <div className="flex flex-wrap w-full ">
      {images.map((image, index) => (
        <div
          className="relative cursor-pointer"
          key={index}
          onClick={() => handleDelete(index)}
        >
          <img
            src={image.imageUrl}
            alt=""
            className="object-cover w-24 h-24 m-2 rounded rounded-lg peer shrink-0 first:ml-0"
          />
          {!disabled && (
            <HiTrash className="absolute top-0 hidden text-xl text-red-700 right-1 peer-hover:block" style={{backgroundColor: "gray", borderRadius: "50%", padding: 3}} />
          )}
        </div>
      ))}
      {images.length === 0 && (
        <>
          <h2>No Images</h2>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

function App() {
  const [images, setImages] = useState([...dummyImages]);
  return (
        <Slider
          onSlideComplete={(i) => {
            console.log('finished dragging, current slide is', i)
          }}
          onSlideStart={(i) => {
            console.log('started dragging on slide', i)
          }}
          activeIndex={0}
          threshHold={100}
          transition={0.5}
          scaleOnDrag={true}
        >
          {images.map(({ url, title }, index) => (
            <img src={url} key={index} alt={title} />
          ))}
        </Slider>
  )
}