import React, { useState, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import TextArea from "../../../TextArea";
import TeamMemberForm from "./sub-components/TeamMemberForm";
import DataTable from "react-data-table-component";
import {  FiTrash } from "react-icons/fi";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { StockService } from "../../../../services/StockService";
import { UserService } from "../../../../services/UserService";
import Select, { OptionProps } from 'react-select';
import TeamMemberList from "./sub-components/TeamMemberList";

const EditTeam = () => {
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    teamName: "",
    isActive: false,
    description: "",
    managerId: "",
    supervisorId: "",
    driverId: "",
    teamMembers: [],
  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const createTeamPromise = new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve({
            message: "Team Updated Successfully",
            status: 200,
            data: [],
          }),
        1000
      )
    );
    // handles the toast loader for the login process
    toast.promise(createTeamPromise, {
      loading: "Updating Team",
      success: "Team Updated Successfully",
      error: "Team Update Failed",
    });
  };

  const roleColumns = [
    {
      name: "Name",
      selector: (row) => row.userInfo.firstName+" "+row.userInfo.lastName,
    },
    {
      name: "Phone",
      selector: (row) => row.userInfo.phoneNumber,
    },
    {
      name: "Email",
      selector: (row) => row.userInfo.email,
    },
    
    {
      name:'Actions',
      selector:(row)=><div>
         <button
          className="px-2 py-1 text-gray-500 hover:bg-red-700 hover:text-white"
          onClick={()=>deleteAction(row.userInfo.userId)}
        >
          <FiTrash />
        </button>
      </div>
    }
  
  ];

  const [pending, setPending] = useState(true);
  const [team,setTeam] = useState([])
  const [teamMembers,setTeamMembers] = useState([])
  const params = useParams();
  useEffect(() => {
    handleteam();
  }, []);

   

    const handleteam = () => {
      StockService.GetTeam(params.id).then((res) => {
        
        setTeam(...team, res);
        setTeamMembers(...teamMembers,res.teamMembers)
        setPending(false);
        setState({
          ...state,
          teamName: res.teamName || "",
          description: res.description || "",
          managerId: res.managerId || "",
          supervisorId: res.supervisorId || "",
          driverId: res.driverId || "",
          
        });
        // console.log(concessions)
        
      });
    };

  const handleAddMember = (e)=>{
    e.preventDefault();
    StockService.AddTeamMember(parseInt(params.id),parseInt(selectedMemberOption.value))
          .then((res) => {
            if (res.Successful) {
              toast.success(res.message);
           
              setTimeout(()=>{
                window.location.reload()
                
              },1000)
             
             
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {});
  }
  const deleteAction = (userId)=>{
    swal({
      title: "Are you sure?",
      text: "User will be removed from team",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        StockService.RemoveTeamMember(parseInt(params.id),parseInt(userId))
          .then((res) => {
            if (res.Successful) {
              toast.success(res.message);
           
              setTimeout(()=>{
                const filteredRoles = teamMembers.filter((role) => role.userId !== userId );
                console.log(filteredRoles)
                setTeamMembers(filteredRoles);
              },1000)
             
             
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {});
      }
    });
 }

 const [selectedManagerOption, setSelectedManagerOption] = useState(null);
 const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);
 const [selectedDriverOption, setSelectedDriverOption] = useState(null);
 const [selectedMemberOption, setSelectedMemberOption] = useState(null);

 
 const handleSupervisorChange =(e)=> {
   // console.log(e)
   setSelectedSupervisorOption(e);
   state.supervisorId = e.value
   const filteredUsers = users.filter((user) => user.userId !== e.userId );
   setUsers(filteredUsers);
 };

 const handleDriverChange =(e)=> {
   // console.log(e)
   setSelectedDriverOption(e);
   state.driverId = e.value
   const filteredUsers = users.filter((user) => user.userId !== e.userId );
   setUsers(filteredUsers);
 };
 const handleMangerChange =(e)=> {
   // console.log(e)
   setSelectedManagerOption(e);
   state.managerId = e.value
   const filteredUsers = users.filter((user) => user.userId !== e.userId );
   setUsers(filteredUsers);
 };

 const handleMemberChange =(e)=> {
  console.log(e)
  setSelectedMemberOption(e);
  
};


 const [users, setUsers] = useState([]);
 const loadUsers = () => {
   UserService.GetAllInternalUsers().then((res) => {
     let data = res.map((user)=>{
       user.value = user.userId;
       user.label = user.firstName+' '+user.lastName;
       return user;
    })
    
     setUsers(...users,data);
    
    

   });
 };

  useEffect(() => {
      loadUsers();
  }, []);

  useEffect(() => {
    if(users.length > 0){
     
      const filteredSupervisor = users?.filter((user) => user.userId === state.supervisorId );
     
      setSelectedSupervisorOption(filteredSupervisor);
      const filteredManager = users?.filter((user) => user.userId === state.managerId );
      
      setSelectedManagerOption(filteredManager);
      const filteredDriver = users?.filter((user) => user.userId === state.driverId );
      
      setSelectedDriverOption(filteredDriver);
    }
}, [users,state]);

  return (
   
  <>
  {/*<GoBack />*/}
     <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      
      <div className="flex self-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
        {state.isActive ? (
          <button
            type="button"
            className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50"
          >
            - Deactivate
          </button>
        ) : (
          <button
            type="button"
            className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50"
          >
            + Activate
          </button>
        )}
      </div>
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        {disabled ? "Team Details" : "Edit Team"}
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Team Name</span>
          <Input
            placeholder="Team Name"
            disabled={disabled}
            type="text"
            value={state.teamName}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            disabled={disabled}
            type="text"
            value={state.description}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Supervisor
          </span>
          <Select
            id="supervisorId"
            type="text"
            options={users}
            value={selectedSupervisorOption}
            isDisabled={disabled}
            onChange={handleSupervisorChange}
            required
            
          />
          {/* <Input
            placeholder="Supervisor"
            type="text"
          disabled={disabled}
          value={state.supervisorId}
          /> */}
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Manager
          </span>
          <Select
            id="managerId"
            type="text"
            options={users}
            value={selectedManagerOption}
            isDisabled={disabled}
            onChange={handleMangerChange}
            required
            
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Driver
          </span>
          <Select
            id="driverId"
            type="text"
            options={users}
            value={selectedDriverOption}
            isDisabled={disabled}
            onChange={handleDriverChange}
            required
            
          />
         
        </label>
      </FormGrid>
      {/* <h3 className="my-2 text-lg font-medium text-gray-700 ">Team Members</h3>
      <TeamMemberList disabled={disabled} /> */}

      {!disabled && (
        <>
          <h3 className="my-2 font-medium text-gray-700">Add Team Members</h3>
          <TeamMemberForm />
        </>
      )}

      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Update Team
        </button>
      )}
    </form>
    {!disabled && (
      
    <form className="flex flex-col w-full" onSubmit={handleAddMember}>
      <h3 className="my-2 text-lg font-medium text-gray-700 ">Add Member to Team</h3>
      <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Select Member
          </span>
          <Select
            id="memberId"
            type="text"
            options={users}
            value={selectedMemberOption}
            // isDisabled={disabled}
            onChange={handleMemberChange}
            required
            
          />
          {/* <Input
            placeholder="Supervisor"
            type="text"
          disabled={disabled}
          value={state.supervisorId}
          /> */}
        </label>
      {/* <TeamMemberForm defaultValue={teamMembers} disabled={disabled} options={users}  setTeamMembers={setTeamMembers} /> */}
      <button
            type="submit"
            className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Add Member
      </button>
    </form>
    )}
    <div className="w-full mt-5">
    <h3 className="my-2 text-lg font-medium text-gray-700 ">Team Members</h3>
    <DataTable
      className="w-full rounded rounded-lg"
      columns={roleColumns}
       data={teamMembers}
      progressPending={pending}
      pagination
    />
  </div>
  </>
  );
};

export default EditTeam;
