import React, { useState, useEffect } from "react";
import Input from "../../../Input";
import OrderItems from "./sub-components/OrderItems";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";

import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import GetOrder from "./sub-components/GetOrder";
import toast from "react-hot-toast";
import { UserService } from "../../../../services/UserService";
import Select, { OptionProps } from 'react-select';
import { OrderService } from "../../../../services/OrderService";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import TableActions from "../../StockManagement/sub-components/TableActions";
import { WaybillService } from "../../../../services/WaybillService";
import { Link, useNavigate ,useParams} from "react-router-dom";
import TableEditActions from "../../StockManagement/sub-components/TableEditActions";

const EditWaybill = () => {
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  let history = useNavigate();
  const params = useParams();
  const [state, setState] = useState({
    waybillNumber: "",
    waybillId:"",
    orderNumber: "",
    driverId: "",
    clientId: "",
    managerId: "",
    supervisorId: "",
    personnelId: "",
    isPaid: "",
    totalAmount: 0,
    grossAmount:0,
    vatAmount:0,
    amountPaid: "",
    comment: "",
    currencyId: "",
    client:"",
    manager:{},
    supervisor:{},
    personnel:{},
    driver:{},
    order:[],
    user:{}
  });
  const [dispatchTypes, setDispatchTypes] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };




  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);
  const [selectedDriverOption, setSelectedDriverOption] = useState(null);
  const [selectedPersonnelOption, setSelectedPersonnelOption] = useState(null);
  
  const handleSupervisorChange =(e)=> {
    // console.log(e)
    setSelectedSupervisorOption(e);
    state.supervisorId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };

  const handleDriverChange =(e)=> {
    // console.log(e)
    setSelectedDriverOption(e);
    state.driverId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };
  const handleMangerChange =(e)=> {
    // console.log(e)
    setSelectedManagerOption(e);
    state.managerId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };


  const handlePersonnelChange =(e)=> {
    // console.log(e)
    setSelectedPersonnelOption(e);
    state.personnelId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };

  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
     })
      setUsers(...users, data);
    });
  };

   useEffect(() => {
       loadUsers();
   }, []);

   const loadDispatch = () => {
    OrderService.GetDispatchTypes().then((res) => {
      // console.log(res.orderItems)
      let data = res.map((user)=>{
        user.value = user.dispatchTypeId;
        user.label = user.dispatchTypeName;
        return user;
     })
      setDispatchTypes(...dispatchTypes,data)
    });
  };
  useEffect(() => {
    loadDispatch();
  }, []);

  const [selectedDispatchOption, setSelectedDispatchOption] = useState(null);
  
  const handleDispatchChange =(e)=> {
    // console.log(e)
    setSelectedDispatchOption(e);
    state.dispatchType = e
    
  };

   const [order, setOrder] = useState({
    orderId: 1,
    orderNumber: "order-234tvd",
    description: "Order Description",
    status: "completed",
    client: "Kwesi",
    dispatchType: "type",
    currency: "GHS",
    numberOfProducts: 13,
    deliveryAddress: "Delivery Address",
    totalAmount: 130,
    destinationCountryId: 2,
    comment: "",
    dispatchTypeId: 3,
    email: "tcl@gmail.com",
    phoneNumber: "0200033400",
    currencyId: 3,
    vatAmount: 4,
    contractNumber: "23456",
    contractDocumentUrl: "https://contractdocurl.com",
    contractDocumentFile: "",
    orderItems:[],
    orderStatus:{},
    orderStatusId:0,
    


  });

  const orderItemColumns = [
    {
      name: "Product Name",
      selector: (row) => row.product.productName,
    },
    {
      name: "Species",
      selector: (row) => row.species.speciesName,
    },

    {
      name: "Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Price",
      selector: (row) => ` GHS ${row.price}`,
    },
    {
      name: "Width",
      selector: (row) => row.width,
    },
    {
      name: "Length",
      selector: (row) => row.length,
    },
    {
      name: "Thickness",
      selector: (row) => row.tickness,
    },
    {
      name: "Amount",
      selector: (row) => ` GHS ${row.amount}`,
    },
    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableEditActions
            viewAction={() => editItem(row)}
            deleteAction={() =>removeItem(row)}
          />
        </div>
      ),
    },
  ];


  const editItem = (row)=>{
    swal(`Enter new Quantity for ${row.product.productName}`, {
      content: "input",
    })
    .then((value) => {
      if(value){
        //find index of obect in array
        const pos = orderItems.findIndex(object => {
          return object.orderItemId === row.orderItemId;
        });
        orderItems[pos]['quantity'] = parseInt(value);
        orderItems[pos]['amount'] = parseInt(value)*parseInt( orderItems[pos]['price']);
        setOrderItems([...orderItems]);
      }else{
        swal("Invalid input");
      }
      
      // swal(`You typed: ${value}`);
    });
  }

  const removeItem = (row)=>{
    swal({
      title: `About to remove ${row.product.productName}`,
      text: "Once deleted, it will not be part of this waybill",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((value) => {

      if(value){
        //find index of obect in array
      const pos = orderItems.findIndex(object => {
        return object.orderItemId === row.orderItemId;
      });
      const newData = orderItems.filter(x=>x.orderItemId !== row.orderItemId)
      
      setOrderItems([...newData]);
      }
      
      // swal(`You typed: ${value}`);
    });
  }

  useEffect(()=>{
    if(orderItems.length>0){
      const sumall = orderItems.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
      setTotalAmount(sumall*1.125);
      setVatAmount(sumall*0.125);
      setGrossAmount(sumall)
    }
  },[orderItems])

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state)
    const toastId = toast.loading("Creating waybill ... ");
    WaybillService.AddWaybill(
      order.orderId,
      state.driverId,
      state.managerId,
      state.supervisorId,
      state.personnelId,
      totalAmount,
      vatAmount,
      grossAmount,
      orderItems,
      state.comment
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        history("/dashboard/waybills");
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    loadWayBill();
  }, []);

  const loadWayBill = ()=>{
    WaybillService.GetWaybillDetail(parseInt(params.id)).then((res) => {
      console.log(res)
      if(res){
       setState({...state,
        waybillNumber: res.waybillNumber,
        waybillId:res.waybillId,
        orderNumber: res.orderNumber,
        driverId: res.driverId,
        clientId: res.clientId,
        managerId: res.managerId,
        supervisorId: res.supervisorId,
        personnelId: res.personnelId,
        isPaid: "",
        totalAmount: res.totalAmount,
        grossAmount:res.grossAmount,
        vatAmount:res.vatAmount,
        amountPaid: "",
        comment: res.comment,
        currencyId: "",
        client:"",
        manager:res.manager,
        supervisor:res.supervisor,
        personnel:res.personnel,
        driver:res.driver,
        order:res.order,
        user:res.user
        
       });
       
       setOrderItems(res.waybillItems);
      }else{
       toast.error("Oops ... Order not found");
      }
    
   });
  }
  return (
      <>
          <GoBack />
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>

      <div className="flex self-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        {disabled ? "Waybill Details" : "Edit Waybill"}
      </h2>

      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <Input
            placeholder="Description"
            disabled={disabled}
            type="text"
            value={state.description}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Delivery Address
          </span>
          <Input
            placeholder="Delivery Address"
            disabled={disabled}
            type="text"
            value={state.deliveryAddress}
          />
        </label>
        <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Dispatch Type
                </span>
                <Select
                  id="dispatchId"
                  type="text"
                  options={dispatchTypes}
                  value={selectedDispatchOption}
                  isDisabled={disabled}
                  onChange={handleDispatchChange}
                  required
                  
                />
              </label>

              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Driver
                </span>
                <Select
                   options={users}
                  label="Select A Driver"
                  isDisabled={disabled}
                  placeholder="Driver"
                  type="text"
                  value={selectedDriverOption}
           
                  onChange={handleDriverChange}
                />
              </label>
              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Manager
                </span>
                <Select
                   options={users}
                  label="Select a Manager"
                  isDisabled={disabled}
                  placeholder="Manager"
                  type="text"
                  value={selectedManagerOption}
           
                  onChange={handleMangerChange}
                />
              </label>
              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Supervisor
                </span>
                <Select
                  options={users}
                  label="Select a Supervisor"
                  isDisabled={disabled}
                  placeholder="Supervisor"
                  type="text"
                  value={selectedSupervisorOption}
           
                  onChange={handleSupervisorChange}
                />
              </label>
              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Personnel
                </span>
                <Select
                  placeholder="Select a Personnel"
                  options={users}
                  type="text"
                  value={selectedPersonnelOption}
                  isDisabled={disabled}
                  onChange={handlePersonnelChange}
                 
                  
                />
              </label>
              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Total Amount
                </span>
                <Input
                  placeholder="Total Amount"
                  type="text"
                  onChange={handleChange}
                  disabled={true}
                  value={totalAmount}
                />
              </label>
              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Gross Amount
                </span>
                <Input
                  placeholder="Gross Amount"
                  id='grossAmount'
                  type="text"
                  onChange={handleChange}
                  disabled={true}
                  value={grossAmount}
                />
              </label>
              <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Vat Amount
                </span>
                <Input
                  placeholder="Gross Amount"
                  id='vatAmount'
                  type="text"
                  onChange={handleChange}
                  disabled={true}
                  value={vatAmount}
                />
              </label>
            </FormGrid>
            <br />
            <div className="w-full">
            <DataTable
              title={<span className="font-semibold">Order Items</span>}
              data={[...orderItems]}
              
              columns={orderItemColumns}
            />
            
          </div>
      
      <br />
      {/* <OrderItems disabled={disabled} /> */}
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Waybill
        </button>
      )}
    </form>
      </>
  );
};

export default EditWaybill;
