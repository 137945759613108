import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _WarehouseService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;
  

    async AddWarehouse(warehouseName: string,description: string, location: string, managerId: number,supervisorId:number,email:string,phoneNumber:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Warehouse/add/new/warehouse", { warehouseName, description, location, managerId,supervisorId,email,phoneNumber });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Warehouse. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async UpdateWarehouse(warehouseId:number,warehouseName: string,description: string, location: string, managerId: number,supervisorId:number,email:string,phoneNumber:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Warehouse/upate/warehouse", { warehouseId,warehouseName, description, location, managerId,supervisorId,email,phoneNumber });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Warehouse. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async GetAllWarehouses(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Warehouse/get/all/warehouses");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetWarehouseDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Warehouse/get/warehouse/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async ActivateWarehouse(warehouseId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Warehouse/activate/warehouse", { warehouseId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Activate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async DeActivateWarehouse(warehouseId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Warehouse/deactivate/warehouse", { warehouseId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Deactivate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }


    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }
    


}

export const WarehouseService = new _WarehouseService();
