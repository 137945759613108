import React, {useContext, useEffect, useState} from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import Select from "react-select";
import { testConcessions } from "../../../../utils";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";

import { GrDeliver } from "react-icons/gr";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import TreeFellItems from "./sub-components/TreeFellItems";

import TreeFellStatusSteps from "./sub-components/TreeFellStatusSteps";
import ExpenseHistoryTable from "./sub-components/ExpenseHistoryTable";
import {UserService} from "../../../../services/UserService";
import {ExpenseService} from "../../../../services/ExpenseService";
import {useNavigate, useParams} from "react-router-dom";
import UserContext from "../../../../context/UserContext";
import {AccountService} from "../../../../services/AccountService";

const ExpenseDetails = () => {
  const [disabled, setDisabled] = useState(true);
    let history = useNavigate();
    const params = useParams();
    const user = useContext(UserContext);
    const [state, setState] = useState({
        expenseForUserId: 0,
        recordedByUserId: user.userData.userId,
        comment: "",
        amount: 0,
        description: "",
        expenseTypeId: 0,
    });

  const toggleDisable = () => setDisabled((oldState) => !oldState);

    const handleFetchAccount = () => {
        ExpenseService.GetExpenseById(params.id).then((res) => {
            setState(res);
            // setAccountTypes()

        });
    };

    useEffect(() => {
        handleFetchAccount();
    }, []);
    const handleChange = (e) => {
        const { value, id } = e.target;
        setState((oldState) => ({ ...oldState, [id]: value }));
    };
    const [selectedDriverOption, setSelectedDriverOption] = useState(null);
    const [selectedExpenseOption, setSelectedExpenseOption] = useState(null);
    const handleDriverChange =(e)=> {
        // console.log(e)
        setSelectedDriverOption(e);
        state.expenseForUserId = e.value
    };
    const handleExpenseChange =(e)=> {
        // console.log(e)
        setSelectedExpenseOption(e);
        state.expenseTypeId = e.value
    };


    const [users, setUsers] = useState([]);
    const loadUsers = () => {
        UserService.GetAllInternalUsers().then((res) => {
            let data = res.map((user)=>{
                user.value = user.userId;
                user.label = user.firstName+' '+user.lastName;
                return user;
            })
            setUsers(...users, data);
        });
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const [expenseTypes, setExpenseTypes] = useState([]);
    const loadExpenseTypes = () => {
        ExpenseService.GetExpenseTypes().then((res) => {
            let data = res.map((user)=>{
                user.value = user.expenseTypId;
                user.label = user.expenseTypeName;
                return user;
            })
            setExpenseTypes(...expenseTypes, data);
        });
    };

    useEffect(() => {
        loadExpenseTypes();
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(state)
        const toastId = toast.loading("Updating expense ... ");
        ExpenseService.UpdateExpense(
            parseInt(params.id),
            state.expenseForUserId,
            state.recordedByUserId,
            state.expenseTypeId,
            state.amount,
            state.description,
            state.comment
        ).then((res) => {
            toast.dismiss(toastId);
            if (res.Successful) {
                toast.success(res.message);
                history("/dashboard/finance-management/expenses");
            } else {
                toast.error(res.message);
            }
        });
    };
    useEffect(() => {
        console.log(state)
        let x = users.find(x=>x.userId===state.expenseForUserId)
        setSelectedDriverOption(x)

    }, [state,users]);
    useEffect(() => {
        console.log(state)
        let x = expenseTypes.find(x=>x.expenseTypeId===state.expenseTypeId)
        setSelectedExpenseOption(x)

    }, [state,expenseTypes]);
  return (
      <>
          <GoBack />
          <form className="w-full flex flex-col" onSubmit={handleSubmit}>

              <div className="flex space-x-2 self-end">
                  {disabled ? (
                      <div className="">
                          <button
                              onClick={toggleDisable}
                              type="button"
                              className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
                          >
                              <RiEditBoxLine className="text-lg text-white" />
                          </button>
                          <button
                              type="button"
                              onClick={toggleDisable}
                              className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
                          >
                              Edit
                          </button>
                      </div>
                  ) : (
                      <div>
                          <button
                              type="button"
                              onClick={toggleDisable}
                              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
                          >
                              <RiCloseLine className="text-lg text-white" />
                          </button>
                          <button
                              type="button"
                              onClick={toggleDisable}
                              className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
                          >
                              Cancel
                          </button>
                      </div>
                  )}

              </div>
              <h2 className=" text-xl font-medium text-gray-700 my-3">
                  {disabled ? "Expense Details" : "Edit Expense"}
              </h2>

              <FormGrid>

                  <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Expense For
                </span>
                      <Select
                          id="expenseForUserId"
                          options={users}
                          label="Select A User"
                          placeholder="User"
                          type="text"
                          value={selectedDriverOption}
                          isDisabled={disabled}
                          onChange={handleDriverChange}
                      />
                  </label>
                  <label className="block">
                      <span className="block text-md  text-gray-700 mb-3">Expense Type</span>
                      <Select
                          id="expenseTypeId"
                          options={expenseTypes}
                          label="Select Expense Type"
                          placeholder="Expense Type"
                          type="text"
                          value={selectedExpenseOption}
                          onChange={handleExpenseChange}
                          isDisabled={disabled}
                      />
                  </label>
                  <label className="block">
                      <span className="block text-md  text-gray-700 mb-3">Amount </span>
                      <Input
                          placeholder="Amount"
                          id="amount"
                          type="text"
                          value={state.amount}
                          onChange={handleChange}
                          required
                          disabled={disabled}
                      />
                  </label>
                  <label className="block">
                      <span className="block text-md  text-gray-700 mb-3">Description</span>
                      <TextArea
                          id="description"
                          placeholder="Description"
                          type="text"
                          value={state.description}
                          onChange={handleChange}
                          disabled={disabled}

                      />
                  </label>
                  <label className="block">
                      <span className="block text-md  text-gray-700 mb-3">Comments</span>
                      <TextArea
                          id="comment"
                          placeholder="Comments"
                          type="text"
                          value={state.comment}
                          onChange={handleChange}
                          disabled={disabled}

                      />
                  </label>
              </FormGrid>
              {!disabled && (
                  <button
                      type="submit"
                      className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
                  >
                      Edit Expense
                  </button>
              )}

              {/*<ExpenseHistoryTable />*/}

          </form>
      </>

  );
};

export default ExpenseDetails;
