import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _StockService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;
   
    async AddConcession(concessionName: string, concessionSize: string,description: string,registrationDate:string,totalNumberOfTrees: string,address:string,geoLocation:string,permitNumber: string ,permitExpiryDate: string,permitDocument:any): Promise<ProcessResponse> {
         let data = new FormData();
            data.append('permit_doc', permitDocument);
            data.append('concession_name', concessionName);
            data.append('concession_size', concessionSize);
            data.append('description', description);
            data.append('registration_date', registrationDate);
            data.append('num_of_trees', totalNumberOfTrees);
            data.append('address', address);
            data.append('g_location', geoLocation);
            data.append('permit_number', permitNumber);
            data.append('permit_expiry', permitExpiryDate);
            
            try {
                const res = await getAxios(this.baseUrl).post("/Stock/add/concession", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    
                const result = res.data as ApiResponse<any>;
            if (result.isSuccessful) return { Successful: true, message:"New Concession Saved"?? result.message };

            return { Successful: false, message: result.message ?? "Unable to Register. Please try again" }
            } catch (error) {
                
                return { Successful: false, message: "An error occurred. Please try again later" };
        
        } 
    }




     async AddCompactment(concessionId: number, compartmentName: string,description:string,numberOfTrees:number,size:number,permitNumber:string,permitExpiryDate:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/add/compartment", { concessionId, compartmentName,description,numberOfTrees,size,permitNumber,permitExpiryDate});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message:"New Compartment Saved"?? data.message ,data:data.data};

            return { Successful: false, message: data.message ?? "Unable to Save Compactment. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetAllConcessions(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/all/concessions");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetConcession(concessionId:number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Stock/get/concession/by/id/${concessionId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async UpdateConcession(concessionId:string,concessionName: string, concessionSize: string, description: string, registrationDate: string, totalNumberOfTrees: string, address: string, geoLocation: string, permitNumber: string, permitExpiryDate: string, permitDocument: any): Promise<ProcessResponse> {
        let data = new FormData();
        data.append('concession_id', concessionId);
        data.append('permit_doc', permitDocument);
        data.append('concession_name', concessionName);
        data.append('concession_size', concessionSize);
        data.append('description', description);
        data.append('registration_date', registrationDate);
        data.append('num_of_trees', totalNumberOfTrees);
        data.append('address', address);
        data.append('g_location', geoLocation);
        data.append('permit_number', permitNumber);
        data.append('permit_expiry', permitExpiryDate);

        try {
            const res = await getAxios(this.baseUrl).post("/Stock/update/concession", data, { headers: { 'Content-Type': 'multipart/form-data' } });

            const result = res.data as ApiResponse<any>;
            if (result.isSuccessful) return { Successful: true, message: "Concession Updated"??result.message };

            return { Successful: false, message: result.message ?? "Unable to Register. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };

        }
    }


    async GetAllCompactments(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/all/compartments");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetCompactment(concessionId: number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Stock/get/compartment/by/id/${concessionId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async UpdateCompactment(compartmentId: number,concessionId: number, compartmentName: string, description: string, numberOfTrees: number, size: number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/update/compartment", { compartmentId,concessionId, compartmentName, description, numberOfTrees, size });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message:"Compartment Updated"?? data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Compactment. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }



    async AddProduct(productTypeId: number, productName: string, description: string, price: string, quantityInStock: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Product/add/product", { productTypeId, productName, description, price, quantityInStock });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async UpdateProduct(productId:number,productTypeId: number, productName: string, description: string, price: string, quantityInStock: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Product/update/product", { productId,productTypeId, productName, description, price, quantityInStock });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetAllProducts(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Product/get/all/products");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetProduct(productId: number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Product/get/product/by/id/${productId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

  
    async GetProductCount(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Product/get/product/counts`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async ActivateProduct(productId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Product/activate/product", { productId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Activate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async DeActivateProduct(productId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Product/deactivate/product", { productId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Deactivate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async AddSpecies(speciesName: string,description: string, totalNumberOfTrees: number, marketValue: number,numberOfTreesFelled:number,numberOfTreesRemaining:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/add/species", { speciesName, description, totalNumberOfTrees, marketValue,numberOfTreesFelled,numberOfTreesRemaining });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetAllSpecies(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/species");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetSpecies(speciesId: number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Stock/get/species/by/id/${speciesId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                
                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async UpdateSpecies(speciesId:number,speciesName: string,description: string, totalNumberOfTrees: number, marketValue: number,numberOfTreesFelled:number,numberOfTreesRemaining:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/update/species", {speciesId, speciesName, description, totalNumberOfTrees, marketValue,numberOfTreesFelled,numberOfTreesRemaining });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: "Species Updated"??data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async ActivateProductType(productTypeId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/activate/product/type", { productTypeId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Activate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async DeActivateProductType(productTypeId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/deactivate/product/type", { productTypeId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Deactivate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async AddProductType(productTypeName: string,description: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Product/add/producttype", { productTypeName, description});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetProductTypes(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Product/get/producttypes");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetProductType(productTypeId: number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Product/get/producttype/by/id/${productTypeId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                // if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                
                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async UpdateProductType(productTypeId:number,productTypeName: string,description: string, ): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Product/update/producttype", {productTypeId, productTypeName, description });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async CreateTeam(teamName: string,description: string, otherMembersIds: string, supervisorId: number,managerId:number,driverId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Team/add/new/team", { teamName, description, otherMembersIds, supervisorId,managerId,driverId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Create Team. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async AddTeamMember(teamId:number,userId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Team/add/member/to/team", { teamId,userId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Team Member. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async RemoveTeamMember(teamId:number,userId:number): Promise<any> {
        try {

            const res = await getAxios(this.baseUrl).post("/Team/remove/member/from/team", { teamId,userId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Remove team member. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetTeams(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Team/get/teams`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                // if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetTeam(teamId:number): Promise<any> {
        try {

            const res = await getAxios(this.baseUrl).get(`/Team/get/team/by/id/${teamId}`);

            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                // if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }


    async AddTimberClInfo( companyName: string, location: string,email:string,phoneNumber: string,fax:string,registrationNumber:string,registrationDate:any,commencementDate:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/add/timbercl/basic/info", {  companyName, location,email, phoneNumber,fax,registrationNumber,registrationDate,commencementDate });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Add Company Info. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async UpdateTimberClInfo( companyName: string, location: string,email:string,phoneNumber: string,fax:string,registrationNumber:string,registrationDate:any,commencementDate:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/update/timbercl/basic/info", {  companyName, location,email, phoneNumber,fax,registrationNumber,registrationDate,commencementDate });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Company Info. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetTimberClInfo(): Promise<UserInfo|any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/timbercl/info");
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }

    async SaveTimberClDocs(image: any,docName:string,onUploadProgress: any) {
        let data = new FormData();
        data.append(`${docName}`, image);
        // data.append('userId', userId);
        
        try {
            const res = await getAxios(this.baseUrl).post("/Stock/upload/timbercl/documents", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

    async GetStockTotals(): Promise<UserInfo|any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/stock/totals");
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }


    //Tree APIs

    async ActivateTree(treeId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/activate/tree", { treeId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: "Tree Activated" };

            return { Successful: false, message: data.message ?? "Unable to Activate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async DeActivateTree(treeId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/deactivate/tree", { treeId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: "Tree Deactivated" };

            return { Successful: false, message: data.message ?? "Unable to Deactivate Product. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async AddTree(speciesId: number,treeName: string, volume: number, marketValue: number,concessionId:number,compartmentId:number,price:number,description:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/add/new/tree", { speciesId, treeName, volume, marketValue,concessionId,compartmentId,price,description });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Tree. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetTrees(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Tree/get/all/trees");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetTree(treeId: number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Tree/get/tree/by/id/${treeId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                // if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                
                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async UpdateTree(treeId:number,speciesId: number,treeName: string, volume: number, marketValue: number,concessionId:number,compartmentId:number,price:number,description:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Tree/update/tree", { treeId,speciesId, treeName, volume, marketValue,concessionId,compartmentId,price,description });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async SaveTreeImage(image: any,treeId:string,onUploadProgress: any) {
        let data = new FormData();
        for (const key of Object.keys(image)) {
            data.append('inputCollection', image[key])
        }
        data.append('treeid', treeId);
        
        try {
            const res = await getAxios(this.baseUrl).post("/Tree/upload/tree/images", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

    async RemoveTreeImage(treeImageId:number ): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Tree/remove/tree/image", {treeImageId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }


    async GetTreeImages(treeId: number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Tree/get/images/for/tree/id/${treeId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.permitExpiryDate) response.data.permitExpiryDate = moment(response.data.permitExpiryDate).format('yyyy-MM-DD');
                // if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                
                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }




    //Currency & Exchange Rate
    async GetCurrencies(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Stock/get/currencies`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                console.log()
                
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                // if (response.data.registrationDate) response.data.registrationDate = moment(response.data.registrationDate).format('yyyy-MM-DD');
                
                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }
   
    async UpdateCurrencyExnachangeRate(currencyId:number,newRate: number,updatedBy: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/update/exchangerate", { currencyId,newRate, updatedBy});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async SaveCompartmentDoc(image: any,compartmentId:any,onUploadProgress: any) {
        let data = new FormData();
        data.append(`permit_doc`, image);
        data.append('compartment_id', compartmentId);
        
        try {
            const res = await getAxios(this.baseUrl).post("/Stock/upload/compartment/document", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }


    async ActivateSpecies(speciesId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/activate/species", { speciesId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: "Species Activated" };

            return { Successful: false, message: data.message ?? "Unable to Activate Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async DeActivateSpecies(speciesId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/deactivate/species", { speciesId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: "Species Deactivated" };

            return { Successful: false, message: data.message ?? "Unable to Deactivate Species. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }

    // async UpdateUserAddress(userId: number, location: string, address1?: string,address2?: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/update/user/address", { userId, location, address1, address2 });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to update Address. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    //  async AddCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/add/company/client/info", { userId,clientId, companyName, email, phoneNumber });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to Add Company Info. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    //  async UpdateCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/update/company/info", { userId,clientId, companyName, email, phoneNumber });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to update Company Info. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    // async GetUserById(id: number): Promise<UserInfo|any> {
    //     try {
    //         const res = await getAxios().get("/User/get/user/by/id/" + id);
    //         var response = res.data as ApiResponse<UserInfo>;
    //         if (response.isSuccessful) {
    //             // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
    //              this.SetCurrentUser(response.data);
    //             return response.data;

    //         }
    //         return null;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }



    //   async GetAdminById(id: number): Promise<UserInfo|any> {
    //     try {
    //         const res = await getAxios().get("/User/get/user/by/id/" + id);
    //         var response = res.data as ApiResponse<UserInfo>;
    //         if (response.isSuccessful) {
    //             // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
    //             //  this.SetCurrentUser(response.data);
    //             return response.data;

    //         }
    //         return null;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }
 
    // async UpdateUserInfo(data: UserInfo) {
    //     try {
    //         const res = await getAxios().post("/User/update/user", data);
    //         const data_res = res.data as ApiResponse<any>;
    //          if (data_res.isSuccessful) return { Successful: true, message: data_res.message };

    //         return { Successful: false, message: data_res.message ?? "Unable to Update. Please try again" }
    //     } catch (error) {
            
    //         return null;
    //     }
    // }



    // async SaveUserImage(image:any, userId:string) {
    //     let data = new FormData();
    //     data.append('imageCollection', image);
    //     data.append('userId', userId);
    //     try {
    //         const res = await getAxios().post("/User/upload/user/image", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    //         return res.data as ApiResponse<any>;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }

    // async SaveCompanyDocuments(image:any, userId:string) {
    //     let data = new FormData();
    //     data.append('docCollection', image);
    //     data.append('userId', userId);
        
    //     try {
    //         const res = await getAxios().post("/User/upload/company/client/documents", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    //         return res.data as ApiResponse<any>;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }

    // SetCurrentUser(userData?: any) {
    //     window.localStorage.setItem(this.localStorageKeys.currentUser, JSON.stringify(userData));
    // }

    // async changePassword(userId: number, oldPassword: string, newPassword: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/reset/password/on/dashboard", { userId, oldPassword, newPassword});

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to change password. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    // async ActivateUser(userId: number): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/activate/user", { userId });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable Activate. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    //  async DeactivateUser(userId: number): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/deactivate/user", { userId });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable Deactivate. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    // async GetUserStats(id: number): Promise<UserInfo|any> {
    //     try {
    //         const res = await getAxios().get("/User/get/user/counts");
    //         var response = res.data as ApiResponse<UserInfo>;
    //         if (response.isSuccessful) {
    //             // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
    //             //  this.SetCurrentUser(response.data);
    //             return response.data;

    //         }
    //         return null;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }
    // async SaveUserSignature(image:any, userId:string) {
    //     let data = new FormData();
    //     data.append('imageCollection', image);
    //     data.append('userId', userId);
    //     try {
    //         const res = await getAxios().post("/User/upload/user/signature", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    //         return res.data as ApiResponse<any>;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }


}

export const StockService = new _StockService();
