import React, { useState, useContext, useEffect } from "react";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import {
  ProfileImageSection,
  SignatureSection,
} from "../sub-components/DocumentUploads";
import UserContext from "../../../context/UserContext";
import { UserService } from "../../../services/UserService";
const PersonalDetails = () => {
  const user = useContext(UserContext);
  const [state, setState] = useState(user.userData);
  const loadUserProfile = () => {
    UserService.GetUserById(parseInt(user.userData.userId)).then((res) => {
      setState(state,res)
    });
  };

   useEffect(() => {
    
         loadUserProfile();
       
   }, []);

  
  const id = state.userId;
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Updating Password");
    const updateProfilePromise = UserService.UpdateUserInfo({
      userId: state.userId,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phoneNumber: state.phoneNumber
    }).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        UserService.GetUserById(state.userId).then(
          (ress) => {
            window.location.reload();
          }
        );
      } else {
        toast.error(res.message);
      }
    });
    // handles the toast loader for the login process
    // toast.promise(changeProfilePromise, {
    //   loading: "Updating Profile",
    //   success: "Profile Changed Successfully",
    //   error: "Profile Update Failed",
    // });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center my-6">
        <h4 className="text-left text-xl md:text-2xl text-gray-600 font-semibold">
          Personal Details
        </h4>

        <div>
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>

      {disabled ? (
        <div
          className="bg-white h-24 w-24 rounded rounded-full border-2 border-gray-200 shadow-sm flex justify-center items-center pointer bg-cover bg-no-repeat bg-center "
          style={{ backgroundImage: `url(${state.imageUrl})` }}
        ></div>
      ) : (
        <ProfileImageSection id={id} disabled={disabled} />
      )}
      <form onSubmit={handleSubmit} className={`w-full flex flex-col`}>
        <FormGrid>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">Username</span>
            <Input
              id="username"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Username"
              type="text"
              value={state.username}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">
              First Name
            </span>
            <Input
              id="firstName"
              onChange={handleChange}
              disabled={disabled}
              placeholder="First Name"
              type="text"
              value={state.firstName}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">Last Name</span>
            <Input
              id="lastName"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Last Name"
              type="text"
              value={state.lastName}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">
              Email Address
            </span>
            <Input
              id="email"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Email Address"
              type="email"
              value={state.email}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">
              Phone Number
            </span>
            <Input
              id="phoneNumber"
              onChange={handleChange}
              disabled={disabled}
              placeholder="eg: 0505707987"
              type="tel"
              value={state.phoneNumber}
            />
          </label>
        </FormGrid>
        
        <h2 className="block text-md  text-gray-700 mb-3">Signature</h2>
        {disabled ? (
          <div
            className="bg-white h-24 w-24 rounded rounded-full border-2 border-gray-200 shadow-sm flex justify-center items-center pointer bg-cover bg-no-repeat bg-center "
            style={{ backgroundImage: `url(${state.signatureUrl})` }}
          ></div>
        ) : (
          <SignatureSection id={id} disabled={disabled} />
        )}

        {!disabled && (
          <button
            type="submit"
            className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Update Profile
          </button>
        )}
      </form>
    </div>
  );
};

export default PersonalDetails;
