import axios from "axios";
import { config } from '../environments/environment'
import swal from "sweetalert";
import {AuthService} from "../services/AuthService";
// const { publicRuntimeConfig } = getConfig();
const baseUrl = config.url.USER_API_URL;
const Logout = () => {
    swal({
        title: "Restricted Access",
        text: "User is not permitted to perform this action. Contact Administrator",
        icon: "error",
        closeOnClickOutside:true,

        // buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        if (willDelete) {
            // window.location.replace('/dashboard')
        } else {
        }
    });
};

export const getAxios = function (baseURL: string = baseUrl) {
    let requestId:any = {userId:0};
    const itemSet = (localStorage.getItem('CURRENT_USER') !== null);
    if(itemSet){
        let tempRequestId = window.localStorage.getItem('CURRENT_USER');
        requestId = tempRequestId ? JSON.parse(tempRequestId):{userId:0};

    }

    const instance = axios.create({
        baseURL,
        headers: { Authorization: 'Basic ' + config.url.API_KEY ,'Access-Control-Allow-Origin':'*', requestid:requestId.userId }
    });
     instance.interceptors.response.use((response) => {
         console.log(response)
         if (response.data.hasOwnProperty('isPermitted') && !response.data.isPermitted) {
             Logout();
         }
        return response;
      }, function (error) {
         console.log(error.response)
        if (error.response.status === 400) return Promise.resolve(error.response);
         // if (error.response.status === 401) return Promise.resolve(error.response);
        return Promise.reject(error.response);
      });

    return instance;
}

// export default axios.create({
//   baseURL: baseUrl,
//   headers: {
//      Authorization: 'Basic ' + config.url.API_KEY ,'Access-Control-Allow-Origin':'*'
//   }
// });

