import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _ExpenseService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.PAYMENT_API;
  

    async GetAllExpense(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Expense/get/all/expenses");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }


    async GetExpenseById(accountId: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Expense/get/expense/by/Id/${accountId}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async GetExpenseTypes(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Expense/get/expense/types`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }
   

    async AddNewExpense(expenseForUserId:number,recordedByUserId: number,expenseTypeId:number,amount:number,description:string,comment?:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Expense/add/expense", { expenseForUserId,recordedByUserId,expenseTypeId,amount,description,comment });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Create Expense. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async UpdateExpense(expenseId:number,expenseForUserId:number,recordedByUserId: number,expenseTypeId:number,amount:number,description:string,comment?:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Expense/update/expense", {expenseId,expenseForUserId,recordedByUserId,expenseTypeId,amount,description,comment });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Expense. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async RemoveExpense(expenseId:number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Expense/delete/expense", {expenseId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to remove Expense. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }



    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }


}

export const ExpenseService = new _ExpenseService();
