import React, {useState, useContext, useEffect, useCallback} from "react";
import toast from "react-hot-toast";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import logo from "../../../assets/logo.png";
import UserContext from "../../../context/UserContext";
import { StockService } from "../../../services/StockService";
import Input from "../../Input";
import DocumentUpload, {
  ProfileImageSection,
  SignatureSection,
  CompanyDocumentUpload
} from "../sub-components/DocumentUploads";
import { FormGrid } from "../Users/AddANewUser";
import moment from "moment";
import ImageViewer from 'react-simple-image-viewer';
import {AiOutlineCloudUpload, AiOutlineEye} from "react-icons/ai";
const CompanyInfo = () => {
  const [state, setState] = useState({
    companyName: "",
    location: "",
    email: "",
    phoneNumber: "",
    fax: "",
    logoUrl: "",
    logo: logo,
    registrationNumber: "",
    registrationDate: new Date().toISOString(),
    certificateOfRegistrationUrl: "",
    certificateOfRegistrationFile: "",
    commencementDate: new Date().toISOString(),
    certificateOfCommencementUrl: "",
    certificateOfCommencementFile: "",
    cert_logo:'',
    certificate_of_registration:'',
    certificate_of_commencement:''
  });

  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const user = useContext(UserContext);

  const loadUserProfile = () => {
    StockService.GetTimberClInfo().then((res) => {
      setState({
        ...state,
        companyName: res.companyName||"",
        location: res?.location||"",
        email: res?.email||"",
        phoneNumber: res?.phoneNumber||"",
        fax: res?.fax||"",
        logoUrl: res?.logoUrl||"",
        logo: res?.logo||"",
        registrationNumber: res?.registrationNumber||"",
        registrationDate: res.registrationDate||new Date().toISOString(),
        certificateOfRegistrationUrl: res?.certificateOfRegistrationUrl,
        certificateOfRegistrationFile: res?.certificateOfRegistrationFile,
        commencementDate: res.commencementDate||new Date().toISOString(),
        certificateOfCommencementUrl: res?.certificateOfCommencementUrl,
        certificateOfCommencementFile: res?.certificateOfCommencementFile
      });
    });
  };

   useEffect(() => {
         loadUserProfile(); 
   }, []);

  
  const id = state.userId;
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Saving Information");
    StockService.UpdateTimberClInfo(
      state.companyName,
      state.location,
      state.email,
      state.phoneNumber,
      state.fax,
      state.registrationNumber,
      moment(state.registrationDate).format('yyyy-MM-DD'),
      moment(state.commencementDate).format('yyyy-MM-DD')
      
      ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);

        if(state.cert_logo){
          uploadDocument(state.cert_logo,"logo")
         
         
        }
        if(state.certificate_of_registration){
          setTimeout(()=>{
            uploadDocument(state.certificate_of_registration,"certificate_of_registration")
          },3000)
          
        }
        if(state.certificate_of_commencement){
          
          setTimeout(()=>{
            uploadDocument(state.certificate_of_commencement,"certificate_of_commencement")
          },3000)
        }
        // window.location.reload();
        
      } else {
        toast.error(res.message);
      }
    });
    // handles the toast loader for the login process
    // toast.promise(changeProfilePromise, {
    //   loading: "Updating Profile",
    //   success: "Profile Changed Successfully",
    //   error: "Profile Update Failed",
    // });
  };

  const uploadDocument = (e,docname) => {
    
    const toastId = toast.loading(`Please wait ${docname} file upload in progress`);
    StockService.SaveTimberClDocs(e,docname)
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.isSuccessful) {
          toast.success(`${docname} file upload successful`);
        } else {
          toast.error(
            "Upload Failed, Delete file and Try again"
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const [currentImage, setCurrentImage] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    'http://placeimg.com/1200/800/nature',
    'http://placeimg.com/800/1200/nature',
    'http://placeimg.com/1920/1080/nature',
    'http://placeimg.com/1500/500/nature',
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage('');
    setIsViewerOpen(false);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between my-6">
        <h4 className="text-xl font-semibold text-left text-gray-600 md:text-2xl">
          Company Info
        </h4>

        <div>
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      <CompanyDocumentUpload disabled={disabled} preview={state.logoUrl} docname="logo" state={state} />
      <form onSubmit={handleSubmit} className={`w-full flex flex-col`}>
        <FormGrid>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Company Name
            </span>
            <Input
              disabled={disabled}
              placeholder="Company Name"
              type="text"
              value={state.companyName}
              id="companyName"
              onChange={handleChange}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Email</span>
            <Input
              disabled={disabled}
              placeholder="Email"
              type="email"
              value={state.email}
              id="email"
              onChange={handleChange}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Phone Number
            </span>
            <Input
              disabled={disabled}
              placeholder="Phone Number"
              type="tel"
              value={state.phoneNumber}
              id="phoneNumber"
              onChange={handleChange}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Location</span>
            <Input
              disabled={disabled}
              placeholder="Location"
              type="location"
              value={state.location}
              id="location"
              onChange={handleChange}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Fax</span>
            <Input
              disabled={disabled}
              placeholder="Fax"
              type="text"
              value={state.fax}
              id="fax"
              onChange={handleChange}
            />
          </label>
        </FormGrid>

        <br />
        <hr />
        <br />

        <h2 className="block mb-3 text-lg font-bold text-gray-700">
          Registration
        </h2>
        <FormGrid>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Registration Number
            </span>
            <Input
              disabled={disabled}
              placeholder="Registration Number"
              type="text"
              value={state.registrationNumber}
              id="registrationNumber"
              onChange={handleChange}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Registration Date
            </span>
            <Input
              disabled={disabled}
              placeholder="Registration Date"
              type="datetime-local"
              value={state.registrationDate.slice(0, 16)}
              id="registrationDate"
              onChange={handleChange}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Commencement Date
            </span>
            <Input
              disabled={disabled}
              placeholder="Commencement Date"
              type="datetime-local"
              value={state.commencementDate.slice(0, 16)}
              id="commencementDate"
              onChange={handleChange}
            />
          </label>
        </FormGrid>
        <FormGrid >
           <div className="block" id="1" >
            <h2 className="block mt-1 mb-3 text-gray-700 text-md">
              Certificate of Registration
            </h2>
             {disabled && (
             <div
                 className="flex items-center self-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-lg shadow-sm pointer"
                 style={{ backgroundImage: `url(${state.certificateOfRegistrationUrl})` }}
                 onClick={ () => openImageViewer(state.certificateOfRegistrationUrl) }
             >
               <AiOutlineEye className="text-2xl text-gray-600" />

             </div>
             )}
             {isViewerOpen && (
                 <ImageViewer
                     src={ [currentImage] }
                     currentIndex={ 0 }
                     disableScroll={ false }
                     closeOnClickOutside={ true }
                     onClose={ closeImageViewer }
                 />
             )}

             {!disabled && (
            <CompanyDocumentUpload   preview={state.certificateOfRegistrationUrl} disabled={disabled} docname="certificate_of_registration" state={state} />
                 )}
             </div>
          <div className="block" id="2">
            <h2 className="block mt-1 mb-3 text-gray-700 text-md">
              Certificate of Commencement
            </h2>
            {disabled && (
                <div
                    className="flex items-center self-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-lg shadow-sm pointer"
                    style={{ backgroundImage: `url(${state.certificateOfCommencementUrl})` }}
                    onClick={ () => openImageViewer(state.certificateOfCommencementUrl) }
                >
                  <AiOutlineEye className="text-2xl text-gray-600" />

                </div>
            )}


            {!disabled && (
            <CompanyDocumentUpload  preview={state.certificateOfCommencementUrl} disabled={disabled} docname="certificate_of_commencement" state={state} />
                )}
            </div>
        </FormGrid>

        {/* <SignatureSection disabled={disabled} /> */}
        {!disabled && (
          <button
            type="submit"
            className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Update Profile
          </button>
        )}
      </form>
      {/* <CompanyDocumentUpload preview={state.certificateOfRegistrationUrl} disabled={disabled} docname="certificate_of_registration" state={state} /> */}
      
    </div>
  );
};

export default CompanyInfo;
