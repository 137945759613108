import "./Featured.scss";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const Featured = () => {
  return <div className="featured">
      <div className="top">
          <h1 className="title"> Total Revenue</h1>
          <MoreVertIcon fontSize="smaall" />
      </div>
      <div className="bottom">
          <div className="featuredChart">
              <CircularProgressbar value={70} text={"70%"}  strokeWidth={5} />
          </div>
          <p className="title">Total orders made today</p>
          <p className="amount">$420</p>
          <p className="desc">Previous transaction processing. Last payments may not be included.</p>

          <div className="summary">
              <div className="item">
                  <div className="itemTitle">Target</div>
                  <div className="itemResult negative">
                      <ArrowCircleDownIcon fontSize="small" />
                      <div className="resultAmount">$12.4k</div>
                  </div>
              </div>
              <div className="item">
                  <div className="itemTitle">Last Week</div>
                  <div className="itemResult positive">
                      <ArrowCircleUpIcon fontSize="small" />
                      <div className="resultAmount">$12.4k</div>
                  </div>
              </div>
              <div className="item">
                  <div className="itemTitle">Last Month</div>
                  <div className="itemResult positive">
                      <ArrowCircleUpIcon fontSize="small" />
                      <div className="resultAmount">$12.4k</div>
                  </div>
              </div>
          </div>
      </div>
  </div>;
};

export default Featured;
