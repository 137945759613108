import React, {useEffect, useState} from "react";
import Input from "../../../Input";
import TextArea from "../../../TextArea";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import Select from "../../../Select";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import toast from "react-hot-toast";
import ReturnItems from "./sub-components/ReturnItems";
import {WaybillService} from "../../../../services/WaybillService";
import {useNavigate, useParams} from "react-router-dom";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import TableActions from "../../StockManagement/sub-components/TableActions";
import TableEditActions from "../../StockManagement/sub-components/TableEditActions";

const EditReturn = () => {
  let history = useNavigate();
  const params = useParams();
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    orderNumber: "",
    driverId: "",
    clientId: "",
    managerId: "",
    supervisorId: "",
    personnelId: "",
    waybillId: "",
    totalAmount: "",
    comment: "",
    user:{},
    driver:{},
    supervisor:{},
    manager:{},
    personnel:{}
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [returnItems,setReturnItems] = useState([]);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editWaybillReturnPromise = new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve({
            message: "Waybill Return edited Successfully",
            status: 200,
            data: [],
          }),
        1000
      )
    );
    // handles the toast loader for the login process
    toast.promise(editWaybillReturnPromise, {
      loading: "Editing Waybill Return",
      success: "Waybill Return edited Successfully",
      error: "Waybill Return edit Failed",
    });
  };

  useEffect(() => {
    loadWayBill();
  }, []);

  const loadWayBill = ()=>{
    WaybillService.GetReturnDetail(parseInt(params.id)).then((res) => {
      console.log(res)
      if(res){
        setState({...state,
          waybillNumber: res.waybillNumber,
          waybillId:res.waybillId,
          orderNumber: res.orderNumber,
          driverId: res.driverId,
          clientId: res.clientId,
          managerId: res.managerId,
          supervisorId: res.supervisorId,
          personnelId: res.personnelId,
          isPaid: "",
          totalAmount: res.totalAmount,
          grossAmount:res.grossAmount,
          vatAmount:res.vatAmount,
          comment: res.comment,
          manager:res.manager,
          supervisor:res.supervisor,
          personnel:res.personnel,
          driver:res.driver,
          order:res.order,
          user:res.user

        });

        setReturnItems(res.returnItems);
      }else{
        toast.error("Oops ... Order not found");
      }

    });
  }

  const editItem = (row)=>{
    swal(`Enter new Quantity for ${row.product.productName}`, {
      content: "input",
    }).then((value) => {
      if(value){
        //find index of obect in array
        const pos = returnItems.findIndex(object => {
          return object.returnItemId === row.returnItemId;
        });
        returnItems[pos]['quantity'] = parseInt(value);
        returnItems[pos]['amount'] = parseInt(value)*parseInt( returnItems[pos]['price']);
        // wayBillItems[pos]['reason'] = value;
        setReturnItems([...returnItems]);
      }else{
        swal("Invalid input");
      }
      reason(row);
      // swal(`You typed: ${value}`);
    });
  }

  const reason = (row)=>{
    swal(`Enter Return Reason for ${row.product.productName}`, {
      content: "input",
    }).then((value) => {
      if(value){
        //find index of obect in array
        const pos = returnItems.findIndex(object => {
          return object.returnItemId === row.returnItemId;
        });
        returnItems[pos]['reason'] = value;
        setReturnItems([...returnItems]);
      }else{
        swal("Invalid input");
      }

      // swal(`You typed: ${value}`);
    });
  }
  const removeItem = (row)=>{
    swal({
      title: `About to remove ${row.product.productName}`,
      text: "Once deleted, it will not be part of this return items",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
        .then((value) => {

          if(value){
            //find index of obect in array
            const pos = returnItems.findIndex(object => {
              return object.returnItemId === row.returnItemId;
            });
            const newData = returnItems.filter(x=>x.returnItemId !== row.returnItemId)

            setReturnItems([...newData]);
          }

          // swal(`You typed: ${value}`);
        });
  }

  useEffect(()=>{
    if(returnItems.length>0){
      const sumall = returnItems.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
      setTotalAmount(sumall*1.125);
      setVatAmount(sumall*0.125);
      setGrossAmount(sumall)
    }
  },[returnItems])
  const orderItemColumns = [
    {
      name: "Product Name",
      selector: (row) => row.product?.productName,
    },
    {
      name: "Species",
      selector: (row) => row.species?.speciesName,
    },

    {
      name: "Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Price",
      selector: (row) => ` GHS ${row.price}`,
    },
    {
      name: "Width",
      selector: (row) => row.width,
    },
    {
      name: "Length",
      selector: (row) => row.length,
    },
    {
      name: "Thickness",
      selector: (row) => row.tickness,
    },
    {
      name: "Amount",
      selector: (row) => ` GHS ${row.amount}`,
    },
    {
      name: "Reason",
      selector: (row) => `${row.reason}`,
    },
    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
    {
      name: "Actions",
      selector: (row) => (
          <div>
            <TableEditActions
                viewAction={() => editItem(row)}
                deleteAction={() =>removeItem(row)}
            />
          </div>
      ),
    },
  ];
  return (
      <>
        <GoBack />
        <form className="w-full flex flex-col">

      <div className="flex space-x-2 self-end">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
            >
              Cancel
            </button>
          </div>
        )}

      </div>
      <h2 className=" text-xl font-medium text-gray-700 my-2">
        {disabled ? "Waybill Return Details" : "Edit Waybill Return"}
      </h2>
      {/* <GetOrder /> */}
      <br />
          <FormGrid>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                 Comment
                </span>
              <TextArea id="comment"
                        placeholder="Comment"
                        required
                        type="text"
                        onChange={handleChange}
                        value={state.comment}/>

            </label>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Manager
                </span>
              <Input readOnly={true} type="text" value={state.manager?.firstName +' '+state.manager?.lastName}/>

            </label>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Supervisor
                </span>
              <Input readOnly={true} type="text" value={state.supervisor?.firstName +' '+state.supervisor?.lastName}/>

            </label>
            <label className="block">
                    <span className="block mb-3 text-gray-700 text-md">
                      Personnel
                    </span>
              <Input readOnly={true} type="text" value={state.personnel?.firstName +' '+state.personnel?.lastName}/>

            </label>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Total Amount
                </span>
              <Input
                  placeholder="Total Amount"
                  type="text"
                  onChange={handleChange}
                  disabled={true}
                  value={totalAmount}
              />
            </label>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Gross Amount
                </span>
              <Input
                  placeholder="Gross Amount"
                  id='grossAmount'
                  type="text"
                  onChange={handleChange}
                  disabled={true}
                  value={grossAmount}
              />
            </label>
            <label className="block">
                <span className="block mb-3 text-gray-700 text-md">
                  Vat Amount
                </span>
              <Input
                  placeholder="Gross Amount"
                  id='vatAmount'
                  type="text"
                  onChange={handleChange}
                  disabled={true}
                  value={vatAmount}
              />
            </label>
          </FormGrid>
      <br />
          <div className="w-full">
            <DataTable
                title={<span className="font-semibold">WayBill Items</span>}
                data={[...returnItems]}

                columns={orderItemColumns}
            />

          </div>
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Return
        </button>
      )}
    </form>
      </>
  );
};

export default EditReturn;
