import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'
import { number } from "yup";

class _OrderService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;
  

    async GetAllOrders(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Order/get/orders");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return this.sortData(response.data);

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetOrderDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/order/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async GetOrderByOrderNumber(orderNumber: string): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/order/by/orderNumber/${orderNumber}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return null;
        } catch (error) {
            return null;
        }
    }


 
    async GetOrderStatus(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/order/statuses`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetDispatchTypes(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/dispatchtypes`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                // if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }
    async UpdateOrderStatus(orderId:number,orderStatusId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/change/status", { orderId,orderStatusId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Order Status. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async UpdateOrderDispatchStatus(orderId:number,dispatchTypeId: number,): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/set/dispatchtype", { orderId,dispatchTypeId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Set Dispatch type. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetOrderStats(): Promise<UserInfo|any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Report/get/all/order/counts");
            var response = res.data as ApiResponse<UserInfo>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                //  this.SetCurrentUser(response.data);
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }
    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }


}

export const OrderService = new _OrderService();
