import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { financeRoutes } from "../../../utils";
import DashboardTabLink from "../sub-components/DashboardTabLink";
import { LabelAndValue } from "../DashboardMain";
import { StockService } from "../../../services/StockService";
const FinanceManagement = () => {
  const [state, setState] = useState({
    numberOfCompartments:220,
    numberOfConcessions:130,
    numberOfSpecies:20,
    numberOfTrees:100,
    totalCompartments: 0,
    totalConcessions: 0,
    totalProducts: 0,
    totalSpecies: 0,
    totalTeams: 0,
    totalTrees: 0,
    totalWarehouses: 0

  });

  const loadStockTotals = () => {
    StockService.GetStockTotals().then((res) => {
      // console.log(res)
      
      setState({
        ...state,
        totalCompartments: res.totalCompartments,
        totalConcessions: res.totalConcessions,
        totalProducts: res.totalProducts,
        totalSpecies: res.totalSpecies,
        totalTeams: res.totalTeams,
        totalTrees: res.totalTrees,
      });
    });
  };

   useEffect(() => {
         loadStockTotals(); 
   }, []);

 

  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 mb-6 text-4xl font-extrabold text-slate-700">Finance Management</h1>
    
      <div className="hidden w-full grid-cols-1 gap-4 md:grid sm:grid-cols-3 md:grid-cols-4">
        <LabelAndValue
          label={"Total Payments"}
          value={state.totalCompartments}
          className="text-gray-500/75"
        />
        <LabelAndValue
          label={"Total Expense"}
          value={state.totalConcessions}
          className="text-orange-500/75"
          active
        />
        <LabelAndValue
          label={"Total Account"}
          value={state.totalSpecies}
          className="text-yellow-800/75"
        />
         {/* <LabelAndValue
          label={"Trees"}
          value={state.totalTrees}
          className="text-green-700/75"
        /> */}
      </div>
      <nav className="flex flex-wrap w-full py-5 mb-2 text-left border-b border-gray-300">
        {financeRoutes.map((route,index) => (
          <DashboardTabLink key={index} {...route} />
        ))}
      </nav>
      <Outlet />
    </div>
  );
};

export default FinanceManagement;
