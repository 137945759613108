import React, {useEffect, useState} from "react";
import { RiAddLine } from "react-icons/ri";
import { Link, Outlet, useLocation, useRoutes } from "react-router-dom";
import GoBack from "../sub-components/GoBack";
import AnimatedNumber from "animated-number-react";
import {StockService} from "../../../services/StockService";
import {LogService} from "../../../services/LogService";

const Treefells = () => {
  return (
    <div className="w-full h-full  flex flex-col items-start">
      <TreeFellsHeader />
      <Outlet />
    </div>
  );
};

export default Treefells;

const TreeFellsHeader = () => {
  const { pathname } = useLocation();
  const onAll = pathname === "/dashboard/tree-fell/all";

  const [state, setState] = useState({
    totalCount:0,
  totalDelivered:0,
  totalFresh: 0,
  totalInSlidingArea: 0,
  totalTransported: 0,

  });


  const loadStockTotals = () => {
    LogService.GetLogStats().then((res) => {
      // console.log(res)

      setState({
        ...state,
        totalCount: res.totalCount,
        totalDelivered: res.totalDelivered,
        totalFresh: res.totalFresh,
        totalInSlidingArea: res.totalInSlidingArea,
        totalTransported: res.totalTransported,

      });
    });
  };

  useEffect(() => {
    loadStockTotals();
  }, []);
  return (
    <>
      {onAll ? (
        <div>
          <div className="w-full  flex justify-between items-center">
            <h1 className="text-4xl font-extrabold my-3 text-slate-700">
              Log Management
            </h1>
            <div className="flex items-center">
              <Link
                to="add"
                className="rounded rounded-full p-2 shadow-lg bg-[#3e7c17] hover:bg-[#356C14] mr-2"
              >
                <RiAddLine className="text-lg text-white" />
              </Link>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-4">
            <LabelAndValue
              label={"Fresh"}
              value={state.totalFresh}
              className="text-gray-500/75"
            />
            <LabelAndValue
              label={"Moved to Sliding Area"}
              value={state.totalInSlidingArea}
              className="text-gray-500/75"
              active
            />
            <LabelAndValue
              label={"Transported"}
              value={state.totalTransported}
              className="text-blue-500/75"
            />
            <LabelAndValue
              label={"Delivered"}
              value={state.totalDelivered}
              className="text-green-500/75"
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export const LabelAndValue = (props) => {
  const { label, value, className } = props;
  const formatValue = (value) => value.toFixed(0);
  return (
    <div
      className={`bg-white shadow-sm min-h-[5vh] rounded rounded-lg p-6 flex flex-col space-y-4 hover:shadow-md transition ease-in-out delay-150 `}
    >
      <span className="text-lg font-medium text-gray-300 uppercase">
        {label}
      </span>
      <AnimatedNumber
        value={value}
        className={`text-5xl font-bold  ${className}`}
        formatValue={formatValue}
        duration={2000}
      />
    </div>
  );
};
