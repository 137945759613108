import React, { useState, useEffect } from "react";
import AnimatedNumber from "animated-number-react";
import DataTable from "react-data-table-component";
import { UserService } from "../../services/UserService";
import { LogService } from "../../services/LogService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TableActions from "./StockManagement/sub-components/TableActions";
import { OrderService } from "../../services/OrderService";
const DashboardMain = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    totalActive: 0,
    totalDeactivated: 0,
    totalUsers:0
  });
  const [orderState, setOrderState] = useState({
    totalCount: 0,
    totalDelivered: 0,
    totalInProgress: 0,
    totalInTransit: 0,
    totalNew: 0
  });
const loadUserProfile = () => {
  UserService.GetUserStats().then((res) => {
    console.log(res);
    // setUserProfile(true);
    setState({
      ...state,
      totalActive: res.totalActive,
      totalDeactivated: res.totalDeactivated,
      totalUsers: res.totalUsers,
    });
    console.log(state);
  });
};
useEffect(() => {
    loadUserProfile();
}, []);
const treeFellColumns = [
  {
    name: "Tree Fell Code",
    selector: (row) => row.treeFellCode,
  },
  {
    name: "Description",
    selector: (row) => row.description,
  },
  // {
  //   name: "Status",
  //   selector: (row) => row.status,
  // },
  {
    name: "Total Quantity",
    selector: (row) => row.totalQuantity,
  },
  {
    name: "Date",
    selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
  },
  {
    name: "Actions",
    selector: (row) => (
      <div>
        <TableActions
          viewAction={() => navigate(`/dashboard/tree-fell/${row.treeFellId}`)}
          noDelete
        />
      </div>
    ),
  },
];

const [treefell,setTreefell] = useState([])
useEffect(() => {
  handletreefell();
}, []);
const [pending, setPending] = useState(true);
const handletreefell = () => {
  LogService.GetAllTreeLogs().then((res) => {
    
    setTreefell(...treefell, res);
    setPending(false);
    // console.log(concessions)
    
  });
};
const [search,setSearch] = useState("");
const handleSearch = (event) => {
  setSearch(event.target.value); 
};

useEffect(() => {
  console.log(search)
}, [search]);

const loadOrder = () => {
  OrderService.GetOrderStats().then((res) => {
    setOrderState({
      ...orderState,
      totalCount: res.totalCount,
      totalNew: res.totalNew,
      totalInProgress: res.totalInProgress,
      totalInTransit:res.totalInTransit,
      totalDelivered: res.totalDelivered

    });
  });
};
useEffect(() => {
    loadOrder();
}, []);
  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 mb-6 text-4xl font-extrabold text-slate-700">
        Dashboard
      </h1>
      <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-4">
        <LabelAndValue
          label={"Users"}
          value={state.totalUsers}
          className="text-gray-500/75"
        />
        <LabelAndValue
          label={"Active Users"}
          value={state.totalActive}
          className="text-green-500/75"
          active
        />
        <LabelAndValue
          label={"Deactivated Users"}
          value={state.totalDeactivated}
          className="text-red-500/75"
        />
         <LabelAndValue
          label={"All Orders"}
          value={orderState.totalCount}
          className="text-green-500/75"
          active
        />
      </div>
      <div className="grid w-full grid-cols-1 gap-4 mt-10 sm:grid-cols-3 md:grid-cols-4">
       
         <LabelAndValue
          label={"Total New"}
          value={orderState.totalNew}
          className="text-blue-500/75"
          active
        />
        <LabelAndValue
          label={"Total In Progress"}
          value={orderState.totalInProgress}
          className="text-green-500/75"
          active
        />
        <LabelAndValue
          label={"Total In Transit"}
          value={orderState.totalInTransit}
          className="text-violet-500/75"
        />
        <LabelAndValue
          label={"Total Delivered"}
          value={orderState.totalDelivered}
          className="text-red-500/75"
        />
        
      </div>
      <div className="w-full mt-5">
        <h3 className="my-3 mb-6 text-xl font-medium text-gray-500">
          Recent Trees Fell
        </h3>
        <DataTable className="w-full"  columns={treeFellColumns}
        data={treefell.filter((item) => {
          if (search === "") {
            return item;
          } else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.treeFellCode.toLowerCase().includes(search.toLowerCase())
           
          }
        })}
        progressPending={pending} />
      </div>
    </div>
  );
};

export default DashboardMain;

export const LabelAndValue = (props) => {
  const { label, value, className } = props;
  const formatValue = (value) => value.toFixed(0);
  return (
    <div
      className={`bg-white shadow-sm min-h-[5vh] rounded rounded-lg p-6 flex flex-col space-y-4 hover:shadow-md transition ease-in-out delay-150 `}
    >
      <span className="text-lg font-medium text-gray-300 uppercase">
        {label}
      </span>
      <AnimatedNumber
        value={value}
        className={`text-5xl font-bold  ${className}`}
        formatValue={formatValue}
        duration={2000}
      />
    </div>
  );
};
