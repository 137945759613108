import Chart from "../../chart/Chart";
import Featured from "../../featured/Featured";
import "./Analytics.scss";

const Analytics = () => {
  return (
    <div style={{width: "95vw"}}>
      <h1 className="text-4xl font-extrabold my-3 mb-6 text-slate-700">
        Reports / Analytics
      </h1>

      <div className="charts">
        <Featured />
        <Chart aspect={3 / 1} title="Payments -  6 months (Revenue) " />
      </div>
      <div className="charts">
        <Chart aspect={2 / 1} title="Trees - Last 6 months (Revenue) " />
        <Chart aspect={2 / 1} title="Tree Fell - Last 6 months (Revenue) " />
      </div>
      <div className="charts">
        <Chart aspect={3 / 1} title="Trees - Last 6 months (Revenue) " />
      </div>
      <div className="charts">
        <Chart aspect={2 / 1} title="Orders - Last 6 months (Revenue) " />
        <Chart aspect={2 / 1} title="Waybill - Last 6 months (Revenue) " />
      </div>
    </div>
  );
};

export default Analytics;
