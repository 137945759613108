import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { OrderService } from "../../../services/OrderService";
import Search from "../StockManagement/sub-components/Search";
import TableActions from "../StockManagement/sub-components/TableActions";
import FilterByClient from "./sub-components/FilterByClient";
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from "react-icons/fi";
import toast from "react-hot-toast";
const AllOrders = () => {
  const navigate = useNavigate();
  const [shouldFilter, setShouldFilter] = useState(false);
  const [filter, setFilter] = useState(0);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
      console.log({shouldFilter},{filter})
  }, [shouldFilter,filter]);
  useEffect(() => {
   if(copied){
     toast.success('Order number copied');
     setCopied(false)
   }
  }, [copied]);
  const toggleShouldFilter = () => setShouldFilter((prevState) => !prevState);
  const orderColumns = [
    {
      name: "Number",
      selector: (row) =>
          <>
            {row.orderNumber}
            <CopyToClipboard text={row.orderNumber} onCopy={() => setCopied(true)}>

              <div className="copy">    <FiCopy></FiCopy></div>
            </CopyToClipboard>
          </>
          ,
      grow:2
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
        name: "Status",
        selector: (row) => row.orderStatus.orderStatusName,
      },
      {
        name: "Number of Products",
        selector: (row) => row.numberOfProducts,
      },
      {
        name: "Total Amount",
        selector: (row) =>` GHS ${ row.totalAmount}`,
      },
    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
    {
      name: "Date",
      selector: (row) => moment(row.dateCreated).format('yyyy-MM-DD'),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`/dashboard/orders/${row.orderId}`)}
            noDelete
          />
        </div>
      ),
    },
  ];

  const [orders,setOrders] = useState([])
  useEffect(() => {
    handleOrders();
  }, []);
  const [pending, setPending] = useState(true);

  const handleOrders = () => {
    OrderService.GetAllOrders().then((res) => {
      setOrders(...orders, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };

  useEffect(() => {
  }, [search]);

  const [searchByClient,setSearchByClient] = useState("");
  const handleSearchByClient = (event) => {
    setSearchByClient(event); 
    if(event && event.userId){
      setSearch(event.userId);
    }else{
      setSearch('')
    }
    
  };

  return (
    <div className="w-full">
      {/* <AddnExport /> */}
      <FilterByClient
        filter={filter}
        changeHandler={setFilter}
        shouldFilter={shouldFilter}
        toggleFilters={toggleShouldFilter}
        updateList={handleSearchByClient}
        state={searchByClient}
      />
      <Search placeholder="Search Orders" updateList={handleSearch} />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={orderColumns}
        data={orders.filter((item) => {
          if (search === "") {
            return item;
          }else if(typeof search === "number"){
            return item.userId === search;
          }
           else{
           return item.description.toLowerCase().includes(search.toLowerCase())||
           item.orderNumber.toLowerCase().includes(search.toLowerCase())
           
          }
        
          })}
        progressPending={pending}
        pagination

      />
    </div>
  );
};

export default AllOrders;

const data = [
  {
    id: 1,
    orderNumber: "order-234tvd",
    description: "Order Description",
    status:'completed',
    numberOfProducts:13,
    totalAmount:130,
    isActive: true,
  },
  {
    id: 2,
    orderNumber: "order-234tvd45",
    description: "Order Description",
    status:'completed',
    numberOfProducts:13,
    totalAmount:130,
    isActive: true,
  },
  {
    id: 3,
    orderNumber: "order-234t5jvd",
    description: "Order Description",
    status:'completed',
    numberOfProducts:13,
    totalAmount:130,
    isActive: false,
  },
];
