import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Search from "../../StockManagement/sub-components/Search";
import TableActions from "../../StockManagement/sub-components/TableActions";
import { useState } from "react";
// import FilterByClient from "./sub-components/FilterByClient";
import { useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import {AccountService} from "../../../../services/AccountService";
import {PaymentService} from "../../../../services/PaymentService";
import moment from "moment";
const AllPayments = () => {
  const navigate = useNavigate();
  const [shouldFilter, setShouldFilter] = useState(false);
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    console.log({ shouldFilter }, { filter });
  }, [shouldFilter, filter]);

  const toggleShouldFilter = () => setShouldFilter((prevState) => !prevState);
  const orderColumns = [
    {
      name: "Order Number",
      selector: (row) => row.orderNumber,
    },
    {
      name: "Payment Note",
      selector: (row) => row.orderNumber,
    },

    {
      name: "Status",
      selector: (row) => row.isFullyPaid ? (
            <b>Fully Paid</b>
          ) : (
            <b>Partially Paid</b>
          ),
    },

    {
      name: "Total Amount",
      selector: (row) => ` GHS ${row.totalAmount}`,
    },
    {
      name: "Amount Paid",
      selector: (row) => ` GHS ${row.totalPaid}`,
    },
    {
      name: "Outstanding Amount",
      selector: (row) => ` GHS ${row.amountOutstanding}`,
    },
    {
      name: "Due Date",
      selector: (row) => moment(row.dueDate).format('YYYY-MM-DD'),
    },
    // {
    //   name: "Active",
    //   selector: (row) =>
    //     row.isActive ? (
    //       <ActivePill>Active</ActivePill>
    //     ) : (
    //       <InactivePill>Inactive</InactivePill>
    //     ),
    // },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`/dashboard/finance-management/payments/${row.paymentId}`)}
            deleteAction={()=>window.confirm(`Are you sure you want to delete this concession`)}
            noDelete={true}
          />
        </div>
      ),
    },
  ];
  const [payments,setPayments] = useState([])
  const [pending, setPending] = useState(true);
  useEffect(() => {
    handlePayments();
  }, []);



  const handlePayments = () => {
    PaymentService.GetAllPayments().then((res) => {
      setPayments(res);
      setPending(false);
      // console.log(concessions)

    });
  };
  const [search,setSearch] = useState("");
  return (
    <div className="w-full">
       <AddnExport />
      {/* <FilterByClient
        filter={filter}
        changeHandler={setFilter}
        shouldFilter={shouldFilter}
        toggleFilters={toggleShouldFilter}
      /> */}
      <div style={{ display: "flex", alignItems: "center" , gap: 10}}>
        {" "}
        <Search placeholder="Search Payments" />

      </div>

      <DataTable
        className="w-full rounded rounded-lg"
        columns={orderColumns}
        data={payments.filter((item) => {
          if (search === "") {
            return item;
          } else{
            return item.description.toLowerCase().includes(search.toLowerCase())||
                item.orderNumber.toLowerCase().includes(search.toLowerCase())

          }
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default AllPayments;
