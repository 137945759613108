import React, { useState, useContext, useEffect } from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
// import Select from "../../../Select";
import { testConcessions } from "../../../../utils";
import GoBack from "../../sub-components/GoBack";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import TextArea from "../../../TextArea";
import {
  useParams,
} from "react-router-dom";
import { StockService } from "../../../../services/StockService";
import Select, { OptionProps } from 'react-select';
const EditCompartment = () => {
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    compartmentId:"",
    concessionId:0,
    compartmentName: "",
    concession: {},
    description: "",
    numberOfTrees: 0,
    isActive:false,
    compartmentSize: 0,
  });
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };
  const handleSelectChange = (e) => {
    console.log(e)
    setSelectedOption(e);
    // state.driverId = e.value
    setState({...state, concessionId:e.value});
  };

  const params = useParams();
  const [concessions, setConcessions] = useState([]);

  const loadConcessionList = () => {
    StockService.GetAllConcessions().then((res) => {
        //  let data = Array.from(res);
         let data = res.map((country)=>{
            country.value = country.concessionId;
            country.label = country.concessionName;
            return country;
        })
         setConcessions(data);
       
       
    });
  };
  useEffect(() => {
    loadConcessionList();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
   const toastId = toast.loading("Compactment Update In Progress...");
    StockService.UpdateCompactment(
      state.compartmentId,
      parseInt(state.concessionId),
      state.compartmentName,
      state.description,
      parseInt(state.numberOfTrees),
      parseInt(state.compartmentSize),
     
      
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };


 
  useEffect(() => {
    handleCompactments();
  }, []);

  const handleCompactments = () => {
    StockService.GetCompactment(params.id).then((res) => {
      console.log(res)
        setState({
        ...state,
        compartmentId:  res.compartmentId ||"",
        concessionId:  res.concessionId ||1,
        compartmentName:  res.compartmentName ||"",
        concession:  res.concession ||"",
        numberOfTrees:  res.numberOfTrees ||0,
        description:  res.description ||"",
        isActive: res.isActive ||false,
        compartmentSize:  res.size ||0,
      
        
      });
      let data = res.concession;
      data.value = data.concessionId;
      data.label = data.concessionName;
      
      setSelectedOption(data)
      // console.log(concession)
    });
  };

  // useEffect(() => {
  //   console.log(concessions)
  //   console.log(state.concessionId)
  //   if(concessions.length>0 && state.concessionId !==0){
  //     let selectedCon = concessions.find(c=>c.concessionId === state.concessionId); 
  //     setState({
  //       ...state,
  //       concession:  selectedCon         
  //     });
  //     setSelectedOption(selectedCon)
  //   }
  // }, [selectedOption,concessions]);
  return (
    <>
       <GoBack />
    <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      
      <div className='flex self-end space-x-2'>
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                type='button'
                className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                type='button'
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                type='button'
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                type='button'
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
              >
                Cancel
              </button>
            </div>
          )}
       {state.isActive ? (
          <button type='button' className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50">
            - Deactivate
          </button>
        ) : (
          <button type='button' className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50">
            + Activate
          </button>
        )}

        </div>
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        {disabled ? "Compartment Details" : "Edit Compartment"}
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Compartment Name
          </span>
          <Input
            placeholder="Compartment Name"
            type="text"
            disabled={disabled}
            value={state.compartmentName}
            onChange={handleChange}
            required
            id="compartmentName"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Compartment Size
          </span>
          <Input
            placeholder="Compartment Size"
            disabled={disabled}
            type="text"
            value={state.compartmentSize}
            onChange={handleChange}
            required
            id="compartmentSize"
          />
        </label>

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Number of Trees
          </span>
          <Input
            placeholder="Total Number of Trees"
            type="text"
            disabled={disabled}
            value={state.numberOfTrees}
            onChange={handleChange}
            required
            id="numberOfTrees"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Select Concession
          </span>
          <Select
            id="concession"
            type="text"
            options={concessions}
            value={selectedOption }
            onChange={handleSelectChange}
            required
          />
          {/* <Select
            id="concessionId"
            type="text"
            options={concessions}
            value={state.concessionId}
            onChange={handleChange}
            disabled={disabled}
            required
          /> */}
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            disabled={disabled}
            value={state.description}
            onChange={handleChange}
            required
            id="description"
          />
        </label>
      </FormGrid>
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Compartment
        </button>
      )}
    </form>
    </>
   
  );
};

export default EditCompartment;
