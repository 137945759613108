import React, { useState, useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
// import Select from "../../Select";
import { testConcessions } from "../../../utils";
import GoBack from "../sub-components/GoBack";
import TextArea from "../../TextArea";
import TreeFellItems from "./sub-components/TreeFellItems";
import Select, { OptionProps } from 'react-select';
import { LogService } from "../../../services/LogService";
import { Link, useNavigate } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import { StockService } from "../../../services/StockService";
import { nanoid } from "nanoid";
const AddTreeFell = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    managerId: 0,
    supervisorId: 0,
    description: "",
    totalQuantity: "",
    teamId: 0,
    clerkId: 0,
    treeFellItems: [],
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const [teamMembers,setTeamMembers] = useState("")


  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);
  const [selectedClerkOption, setSelectedClerkOption] = useState(null);
  const [selectedTeamOption, setSelectedTeamOption] = useState(null);

  


  const handleClerkChange =(e)=> {
    // console.log(e)
    setSelectedClerkOption(e);
    
    setState({...state, clerkId:e.value});
  };


  const handleTeamChange =(e)=> {
      console.log(e)
     setState({...state, managerId:e.managerId,driverId:e.driverId, supervisorId:e.supervisorId,teamId:e.teamId});
     
    setSelectedTeamOption(e);
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // state.teamMembers = teamMembers;
    //  console.log(state);
    //  console.log(treeFellItems);
    const toastId = toast.loading("Creating new Tree fell ... ");
     LogService.CreateTreeFell(
       state.teamId,
       state.description,
       state.clerkId,
       state.supervisorId,
       state.managerId,
       state.totalQuantity,
       treeFellItems
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         history("/dashboard/tree-fell");
         
       } else {
         toast.error(res.message);
       }
     });
  };

  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
     })
      setUsers(...users, data);
    });
  };

  const [teams, setTeams] = useState([]);
  const loadTeams = () => {
    StockService.GetTeams().then((res) => {
      let data = res.map((user)=>{
        user.value = user.teamId;
        user.label = user.teamName;
        return user;
     })
      setTeams(...users, data);
    });
  };

   useEffect(() => {
       loadUsers();
   }, []);

   useEffect(() => {
    loadTeams();
    }, []);
    const [trees, setTrees] = useState([]);
    const loadTrees = () => {
      StockService.GetTrees().then((res) => {
        // console.log()
        let data = res.map((user)=>{
          user.value = user.treeId;
          user.label = user.treeName;
          return user;
       })
        setTrees(...trees, data);
      });
    };
  
     useEffect(() => {
         loadTrees();
     }, []);
 

const [treeFellItems, setTreeFellItems] = useState([
  {
    description: "",
    dimension: "",
    quantity: 0,
    volume: 0,
    width: 0,
    length: 0,
    tree:'',
    treeId:0,
    id: nanoid(),
  },
]);

useEffect(() => {
  console.log(treeFellItems);
  let totalQty = treeFellItems.map(item => parseInt(item.quantity)).reduce((prev, next) => prev + next);
  setState({...state, totalQuantity:totalQty});
  // let totalQty = treeFellItems.map(item => parseInt(item.quantity)).reduce((prev, next) => prev + next);
  // setState({...state, totalQuantity:totalQty});
}, [treeFellItems]);
  return (
    <>
     <GoBack />
     <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      <h2 className="my-2 text-xl font-medium text-gray-700 ">New TreeFell</h2>
      <FormGrid>
        {/* <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Manager</span>
          <Select
            id="managerId"
            type="text"
            options={users}
            value={selectedManagerOption }
            onChange={handleMangerChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Supervisor</span>
          <Select
            id="supervisorId"
            type="text"
            options={users}
            value={selectedSupervisorOption}
            onChange={handleSupervisorChange}
            required
            
          />
        </label> */}

        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Team</span>
          <Select
            label="Select a Team"
            options={teams}
            value={selectedTeamOption}
            onChange={handleTeamChange}
            required
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Clerk</span>
          <Select
            id="clerkId"
            type="text"
            options={users}
            value={selectedClerkOption }
            onChange={handleClerkChange}
            required
          />
          {/* <Select label="Select a Clerk" options={[]} value={state.clerkId} /> */}
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Description</span>
          <TextArea
            id="description"
            placeholder="Description"
            type="text"
            value={state.description}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      <h2>Items</h2>
      <TreeFellItems trees={trees} setTreeFellItems={setTreeFellItems} treeFellItems={treeFellItems} />
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create TreeFell
      </button>
    </form>
    </>
   
  );
};

export default AddTreeFell;
