import React from "react";
import { useNavigate } from "react-router-dom";

const AddnExport = () => {
  const navigate = useNavigate();
  const handleAdd=()=>navigate('add')
  return (
    <div className="flex justify-end w-full my-3 space-x-3">
      <button
        className="px-3 py-1 text-white rounded rounded-md bg-tclPrimary/90 hover:tclPrimary 5 hover:shadow-md"
        onClick={handleAdd}
      >
        + Add
      </button>
      {/* <button
        className="px-3 py-1 rounded rounded-md bg-tclPrimary/25 hover:shadow-sm hover:bg-tclPrimary/50 5 text-tclPrimary "
        // onClick={handleAdd}
      >
        Export
      </button> */}
    </div>
  );
};

export default AddnExport;
